import moment from 'moment';
import { addDays, subDays, isBefore, isAfter } from 'date-fns';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  onSnapshot,
} from 'firebase/firestore';
import { ref, uploadBytes, deleteObject } from 'firebase/storage';

import {
  LOAD_CASES,
  SET_CASES,
  SET_ACTIVE_TAB,
  SET_SELECTED_FLOW,
  CREATE_CASE,
  UPDATE_CASE,
  SET_ACTIVE_CASE,
  DISCARD_ACTIVE_CASE,
  RESET_CASES,
  SET_CASE_NOTES,
  ADD_CASE_NOTE, SET_COMPLETED_CASES, LOAD_COMPLETED_CASES,
} from './actionTypes';
import Firebase, { collections } from '../firebase';
import {
  CASES_COLLECTION,
  SURGEONS_COLLECTION,
  NOTES_SUB_COLLECTION,
} from '../firebase/collections';
import { fetchProcedures } from './adminActions';
import { createActivity, fetchActivity } from './activityActions';
import { setNotification } from './notificationActions';
import { api, sendEmail } from '../util';
import { caseConstants, userRoles, emailTemplates } from '../constants';
import { roleNames } from '../constants/userRoles';
import { orderBy } from 'lodash';
import urls from '../constants/urls';
import { casesACLEmails, casesCMFEmails, casesCMFExtendedEmails } from '../constants/emailTemplates';

const {
  stepRestrictions,
  cmfExtendedStepRestrictions,
  aclStepRestrictions,
  restrictionsInTrainingMode,
  caseNotifications,
  caseACLNotifications,
  caseCMFNotifications,
  caseCMFExtendedNotifications,
  alertMessages,
  alertAclMessages,
  alertCmfMessages,
  alertCmfExtendedMessages,
  procedureFlows,
  numberOfSteps,
  fileStatuses,
  fileTypes,
  statuses,
} = caseConstants;
const { casesEmails } = emailTemplates;

const hasAccess = (currentUser, surgeon, step, flow, trainingMode = false) => {
  const { GLOBAL_ADMIN, COUNTRY_ADMIN } = roleNames;
  const restrictions = [procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)
    ? cmfExtendedStepRestrictions
    : (flow === procedureFlows.ACL ? aclStepRestrictions : stepRestrictions);

  const isAccess = trainingMode
    ? restrictionsInTrainingMode[step].includes(currentUser.role) || step > numberOfSteps
    : (restrictions[step].includes(currentUser.role) || step > numberOfSteps || currentUser?.engineerLevel === 1 || (restrictions[step].includes(currentUser.role) && step === 1))
    && (surgeon && Object.values(surgeon)?.some((value) => (Array.isArray(value) && value?.includes(currentUser.uid)) || value === currentUser.uid) || currentUser.role === GLOBAL_ADMIN || currentUser.role === COUNTRY_ADMIN);

  if (currentUser?.engineerLevel === 3) {
    return (step > numberOfSteps || step === 0 || step === 1) && Object.values(surgeon)?.some((value) => value && value?.includes(currentUser.uid));
  }

  return isAccess;
};

export const setCases = (cases) => ({ type: SET_CASES, cases });
export const setCompletedCases = (cases) => ({ type: SET_COMPLETED_CASES, cases });

export const setActiveTab = (tab) => ({ type: SET_ACTIVE_TAB, tab });
export const setSelectedFlow = (flow) => ({ type: SET_SELECTED_FLOW, flow });

export const fetchCases = (withoutLoading) => async (dispatch, getState) => {
  const db = Firebase.db;
  const state = getState();
  const uid = state.user.currentUser?.uid;
  const role = state.user.currentUser?.role;
  const country = state.user?.currentUser?.administrationCountry || null;
  const users = state.users.list;
  const distributors = state.distributors.list;

  if (!withoutLoading) {
    dispatch({ type: LOAD_CASES });
  }

  dispatch(fetchProcedures());

  try {
    const surgeons = await fetchSurgeons(uid, role);
    const surgeonIds = surgeons.map((item) => item.id);

    if (!surgeonIds.length) {
      return dispatch(setCases([]));
    }

    const results = [];
    const surgeonQueries = surgeonIds.map((surgeonId) =>
      getDocs(query(collection(db, CASES_COLLECTION), where('surgeonId', '==', surgeonId))).then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });
      })
    );

    await Promise.all(surgeonQueries);

    const filteredCases = results.filter((item) => role === userRoles.GLOBAL_ADMIN.name || !item.archived);

    // Fetch the surgeon data from Firestore using document references
    const promises = filteredCases.map(async (item) => {
      // Convert surgeon path (if it's stored as a string) into a DocumentReference
      const surgeonRef = typeof item.surgeon === 'string'
        ? doc(Firebase.db, item.surgeon)
        : item.surgeon;

      // Fetch the surgeon document data
      const surgeonDoc = await getDoc(surgeonRef);

      // Return the data if the document exists
      return surgeonDoc.exists() ? { ...surgeonDoc.data(), id: surgeonDoc?.id || '' } : null;
    });

    const surgeonsData = await Promise.all(promises);

    surgeonsData.forEach((surgeon, index) => {
      const currentCase = filteredCases[index];
      const caseSurgeon = { id: surgeon?.id, ...surgeon };
      const surgeonUser = users.find((user) => user.uid === caseSurgeon?.userId);
      const surgeonName = surgeonUser ? `${surgeonUser?.firstName} ${surgeonUser?.lastName}` : '';
      const currentUser = state.user.currentUser;
      const isTrainingMode = currentCase.trainingMode;

      currentCase.surgeon = caseSurgeon;
      currentCase.surgeonName = surgeonName;
      currentCase.access = hasAccess(currentUser, caseSurgeon, currentCase.step, currentCase.procedureFlow, isTrainingMode);
    });

    const finalCases = role === userRoles.COUNTRY_ADMIN.name
      ? filteredCases.filter((item) => distributors.find((d) => d.id === item.distributorId)?.country === country)
      : filteredCases;

    dispatch(setCases(finalCases.sort((a, b) => (a.date > b.date ? 1 : -1))));
  } catch (error) {
    dispatch(setCases([]));
    console.error("Error fetching cases:", error);
  }
};

export const fetchCompletedCases = () => async (dispatch, getState) => {
  const db = Firebase.db;
  const state = getState();
  const role = state.user.currentUser?.role;
  const uid = state.user.currentUser?.uid;
  const country = state.user?.currentUser?.administrationCountry || null;
  const users = state.users.list;
  const distributors = state.distributors.list;

  // Skip fetch for Radiology role
  if (role === userRoles.RADIOLOGY.name) {
    console.log("Fetch skipped for Radiology role.");
    return; // Early return to skip fetching completed cases
  }

  try {
    dispatch({ type: LOAD_COMPLETED_CASES });
    // Firestore query to fetch cases with 'status' === 'COMPLETED'
    const completedCasesQuery = query(
      collection(db, CASES_COLLECTION),
      where("status", "==", "COMPLETED"),
    );

    // Execute the query
    const snapshot = await getDocs(completedCasesQuery);
    let completedCases = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log(`Fetched ${completedCases.length} completed cases`);

    // Fetch surgeon data and enrich cases
    const enrichedCases = await Promise.all(
      completedCases.map(async (caseData) => {
        const surgeonRef =
          typeof caseData.surgeon === "string"
            ? doc(Firebase.db, caseData.surgeon)
            : caseData.surgeon;
        const surgeonData = await fetchSurgeonData(surgeonRef);

        const surgeonUser = users.find(
          (user) => user.uid === surgeonData?.userId
        );
        const surgeonName = surgeonUser
          ? `${surgeonUser?.firstName} ${surgeonUser?.lastName}`
          : "";
        const currentUser = state.user.currentUser;
        const isTrainingMode = caseData.trainingMode;

        return {
          ...caseData,
          surgeon: surgeonData,
          surgeonName,
          access: hasAccess(
            currentUser,
            surgeonData,
            caseData.step,
            caseData.procedureFlow,
            isTrainingMode
          ),
        };
      })
    );

    // Apply role-specific filtering
    let filteredCases = enrichedCases;
    if (role === userRoles.COUNTRY_ADMIN.name) {
      filteredCases = filteredCases.filter((item) =>
        distributors
          .find((d) => d.id === item.distributorId)
          ?.country === country
      );
    }

    if (role === userRoles.SURGEON.name) {
      filteredCases = filteredCases.filter(
        (caseItem) => caseItem.surgeon?.userId === uid
      );
    }

    // Dispatch sorted completed cases
    dispatch(
      setCompletedCases(filteredCases.sort((a, b) => (a.date > b.date ? 1 : -1)))
    );
  } catch (error) {
    console.error("Error fetching completed cases:", error);
  }
};

// Fetch completed cases in chunks

// export const fetchCompletedCases = () => async (dispatch, getState) => {
//   const db = Firebase.db;
//   const state = getState();
//   const role = state.user.currentUser?.role;
//   const uid = state.user.currentUser?.uid;
//   const country = state.user?.currentUser?.administrationCountry || null;
//   const users = state.users.list;
//   const distributors = state.distributors.list;
//
//   // Skip fetch for Radiology role
//   if (role === userRoles.RADIOLOGY.name) {
//     console.log("Fetch skipped for Radiology role.");
//     return; // Early return to skip fetching completed cases
//   }
//
//   try {
//     dispatch({ type: LOAD_COMPLETED_CASES });
//
//     const chunkSize = 500; // Number of documents to fetch per chunk
//     let lastVisible = null; // For pagination
//     let isMoreData = true; // Track if more data is available
//
//     while (isMoreData) {
//       // Create Firestore query with pagination
//       const completedCasesQuery = lastVisible
//         ? query(
//           collection(db, CASES_COLLECTION),
//           where("status", "==", "COMPLETED"),
//           startAfter(lastVisible),
//           limit(chunkSize)
//         )
//         : query(
//           collection(db, CASES_COLLECTION),
//           where("status", "==", "COMPLETED"),
//           limit(chunkSize)
//         );
//
//       // Fetch a single chunk
//       const snapshot = await getDocs(completedCasesQuery);
//
//       // If no more documents are returned, stop fetching
//       if (snapshot.empty) {
//         isMoreData = false;
//         break;
//       }
//
//       // Add current chunk of cases
//       const currentChunk = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//
//       console.log(`Fetched ${currentChunk.length} cases in this chunk`);
//
//       // Fetch surgeon data and enrich cases
//       const enrichedCases = await Promise.all(
//         currentChunk.map(async (caseData) => {
//           const surgeonRef =
//             typeof caseData.surgeon === "string"
//               ? doc(Firebase.db, caseData.surgeon)
//               : caseData.surgeon;
//           const surgeonData = await fetchSurgeonData(surgeonRef);
//
//           const surgeonUser = users.find(
//             (user) => user.uid === surgeonData?.userId
//           );
//           const surgeonName = surgeonUser
//             ? `${surgeonUser?.firstName} ${surgeonUser?.lastName}`
//             : "";
//           const currentUser = state.user.currentUser;
//           const isTrainingMode = caseData.trainingMode;
//
//           return {
//             ...caseData,
//             surgeon: surgeonData,
//             surgeonName,
//             access: hasAccess(
//               currentUser,
//               surgeonData,
//               caseData.step,
//               caseData.procedureFlow,
//               isTrainingMode
//             ),
//           };
//         })
//       );
//
//       // Apply role-specific filtering
//       let filteredCases = currentChunk;
//       if (role === userRoles.COUNTRY_ADMIN.name) {
//         filteredCases = filteredCases.filter((item) =>
//           distributors
//             .find((d) => d.id === item.distributorId)
//             ?.country === country
//         );
//       }
//
//       if (role === userRoles.SURGEON.name) {
//         filteredCases = filteredCases.filter(
//           (caseItem) => caseItem.surgeon?.userId === uid
//         );
//       }
//
//       // Dispatch the chunk to the store
//       dispatch(setCompletedCases(filteredCases));
//
//       console.log(`Dispatched ${filteredCases.length} cases`);
//
//       // Update last visible document for pagination
//       lastVisible = snapshot.docs[snapshot.docs.length - 1];
//     }
//   } catch (error) {
//     console.error("Error fetching completed cases:", error);
//   }
// };

export const fetchCaseById = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const users = state.users.list;
  const db = Firebase.db;

  try {
    const caseDocRef = doc(db, CASES_COLLECTION, caseId);
    const docSnapshot = await getDoc(caseDocRef);

    const q = query(collection(db, CASES_COLLECTION), where('formattedId', '==', caseId));
    const snapshot = await getDocs(q);
    const docs = snapshot?.docs || [];

    if (docSnapshot.exists() || docs?.length) {
      dispatch(fetchProcedures());
      const data = docSnapshot.exists() ? docSnapshot.data() : docs[0]?.data();
      const id =  docSnapshot.exists() ? docSnapshot.id : docs[0]?.id;

      const surgeonDocRef = doc(db, SURGEONS_COLLECTION, data.surgeonId);
      const surgeonSnap = await getDoc(surgeonDocRef);
      const surgeon = surgeonSnap.data();
      const caseSurgeon = users.find((user) => user.uid === surgeon?.userId);
      const surgeonName = caseSurgeon ? `${caseSurgeon?.firstName} ${caseSurgeon?.lastName}` : '';

      return {
        ...data,
        id,
        surgeon,
        surgeonName,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching case by ID:", error);
  }
};

export const fetchCasesByPatientData = (lastName, dateOfBirth) => async (dispatch, getState) => {
  const state = getState();
  const users = state.users.list;
  const db = Firebase.db;

  dispatch(fetchProcedures());

  try {
    const q = query(collection(db, CASES_COLLECTION), where('patientInfo.lastName', '==', lastName));
    const snapshot = await getDocs(q);

    const promises = snapshot.docs.map(async (docSnap) => {
      const data = docSnap.data();
      const surgeonDocRef = doc(db, SURGEONS_COLLECTION, data.surgeonId);
      const surgeonSnap = await getDoc(surgeonDocRef);
      const surgeon = surgeonSnap.data();
      const caseSurgeon = users.find((user) => user.uid === surgeon.userId);
      const surgeonName = caseSurgeon ? `${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';

      return {
        ...data,
        id: docSnap.id,
        surgeon,
        surgeonName,
      };
    });

    const cases = await Promise.all(promises);

    return cases.filter((item) => {
      const dateBefore = subDays(new Date(dateOfBirth), 1);
      const dateAfter = addDays(new Date(dateOfBirth), 1);
      const date = new Date(moment(item.patientInfo.dateOfBirth).format('MM/DD/YYYY'));

      return isBefore(dateBefore, date) && isAfter(dateAfter, date);
    });
  } catch (error) {
    console.error("Error fetching cases by patient data:", error);
  }
};

export const fetchRejectedCases = () => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser.uid;
  const users = state.users.list;
  const db = Firebase.db;

  dispatch(fetchProcedures());

  try {
    const q = query(
      collection(db, CASES_COLLECTION),
      where('uploadFilesUid', '==', userId),
      where('status', '==', 'REJECTED')
    );
    const snapshot = await getDocs(q);

    const promises = snapshot.docs.map(async (docSnap) => {
      const data = docSnap.data();
      const surgeonDocRef = doc(db, SURGEONS_COLLECTION, data.surgeonId);
      const surgeonSnap = await getDoc(surgeonDocRef);
      const surgeon = surgeonSnap.data();
      const caseSurgeon = users.find((user) => user.uid === surgeon.userId);
      const surgeonName = caseSurgeon ? `${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';

      return {
        ...data,
        id: docSnap.id,
        surgeon,
        surgeonName,
      };
    });

    return await Promise.all(promises);
  } catch (error) {
    console.error("Error fetching rejected cases:", error);
  }
};

const fetchSurgeons = async (uid, role) => {
  const db = Firebase.db;
  const { SURGEON, ENGINEER, SALES_REP, LOGISTICS, MANUFACTURER, PRACTICE_MANAGER, REGISTRAR } = roleNames;
  const userRole = userRoles[role];

  try {
    const collectionRef = collection(db, SURGEONS_COLLECTION);
    let q;

    if ([ENGINEER, SALES_REP, LOGISTICS, MANUFACTURER, PRACTICE_MANAGER, REGISTRAR].includes(role) && userRole) {
      const opStr = [ENGINEER, SALES_REP, PRACTICE_MANAGER, REGISTRAR].includes(role) ? 'array-contains' : '==';
      q = query(collectionRef, where(userRole.field, opStr, uid));
    } else if (role === SURGEON) {
      q = query(collectionRef, where('userId', '==', uid));
    } else {
      q = query(collectionRef);
    }

    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching surgeons:", error);
  }
};

export const createCase = (uid, caseId, data, files) => async (dispatch, getState) => {
  const db = Firebase.db;
  const storage = Firebase.storage;
  const state = getState();
  const currentUser = state.user.currentUser;
  const users = state.users.list;
  const surgeons = state.surgeons.list;
  const procedures = state.procedures.list;

  const surgeon = surgeons.find((item) => item.id === data.surgeon);
  const step = data.skipPSI ? 10 : 1;
  const access = surgeon && hasAccess(currentUser, surgeon, step, data.procedureFlow);
  const trainingMode = state.sidebar.trainingMode;

  const surgeonUser = users.find((u) => u.uid === surgeon?.userId || u.uid === data?.surgeon);
  const procedure = procedures.find((p) => p.id === data.procedure);

  dispatch({ type: LOAD_CASES });

  try {
    await setDoc(doc(db, CASES_COLLECTION, caseId), {
      ...data,
      userId: uid,
      distributorId: data.distributorId,
      step,
      surgeon: `/${SURGEONS_COLLECTION}/${data.surgeon}`,
      surgeonId: data.surgeon,
      patientLastName: data.patientInfo.lastName,
      createdAt: moment().format('YYYY-MM-DD hh:mm A'),
    });

    const fileUploadPromises = files.map((file) => {
      const currentFile = data.files.find((item) => item.name === file.name);
      const type = currentFile?.type;
      if (type) {
        const path = `${caseId}/${caseId}_${type}_${file.name}`;
        return uploadBytes(ref(storage, path), file);
      }
    });

    await Promise.all(fileUploadPromises);

    let emails = casesEmails;
    if (data.procedureFlow === procedureFlows.ACL) {
      emails = casesACLEmails;
    } else if (data.procedureFlow === procedureFlows.CMF) {
      emails = casesCMFEmails;
    } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.CUSTOM_EXTENDED, procedureFlows.ONCOL, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(data.procedureFlow)) {
      emails = casesCMFExtendedEmails;
    }

    const email = emails[0].success;

    if (!trainingMode) {
      email.roles.forEach((role) => {
        const recipientId = role.name === roleNames.SURGEON ? surgeon.userId : surgeon[role.field];
        if (!recipientId) return;

        const recipients = Array.isArray(recipientId)
          ? recipientId.map((recipientItem) => users.find((userItem) => userItem.uid === recipientItem))
          : [users.find((user) => user.uid === recipientId)];

        recipients.forEach((recipient) => {
          if (recipient?.uid !== uid && !recipient?.disabled) {
            dispatch(
              createActivity(recipient.uid, {
                ...caseNotifications[0].success,
                caseId,
                date: moment().format(),
                userId: currentUser?.uid || '',
              })
            );

            if (role.name !== roleNames.SURGEON && !recipient?.disabled) {
              dispatch(
                sendCaseEmail(
                  recipient,
                  email.subject,
                  email.message({
                    caseId: caseId.includes("_") ? caseId.substring(0, caseId.indexOf("_")) : caseId,
                    patientLastName: data.patientLastName || '',
                  }),
                  email.additionalText,
                  true,
                  [
                    { key: 'CASE ID', value: caseId.includes("_") ? caseId.substring(0, caseId.indexOf("_")) : caseId },
                    { key: 'PATIENT SURNAME', value: data.patientInfo?.lastName || '' },
                    { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
                    { key: 'DOS', value: data.date ? moment(data.date).format('DD/MM/YY') : 'TBC' },
                    { key: 'HOSPITAL', value: data.hospital },
                    { key: 'PROCEDURE', value: procedure ? procedure.name : '' },
                  ]
                )
              );
            }
          }
        });
      });
    }

    dispatch(setNotification({ variant: 'success', vertical: 'top', message: alertMessages[0].success }));

    dispatch({
      type: CREATE_CASE,
      case: {
        id: caseId,
        ...data,
        patientLastName: data.patientInfo.lastName,
        userId: uid,
        step,
        access,
        surgeon: surgeon || data.surgeon,
      },
    });
  } catch (error) {
    console.error("Error creating case:", error);
  }
};

export const clearRejectedFiles = async (filesData, caseId) => {
  const storage = Firebase.storage;
  const deletePromises = filesData
    .filter((file) => file.status === fileStatuses.REJECTED)
    .map((file) => deleteObject(ref(storage, `${caseId}/${file.id}`)));

  try {
    await Promise.all(deletePromises);
  } catch (error) {
    console.error("Error clearing rejected files:", error);
  }
};

export const updateCaseAdmin = (id, data, previousData, files) => async (dispatch, getState) => {
  const db = Firebase.db;
  const storage = Firebase.storage;
  const state = getState();
  const trainingMode = state.sidebar.trainingMode || previousData.trainingMode;
  const uid = state.user.currentUser?.uid;

  if (!trainingMode) {
    dispatch(sendChangeDateHospitalEmail(id, data, previousData));
  }

  const docData = { ...data };
  delete docData.sortDate;

  if (data.procedure !== previousData.procedure) {
    const newActivity = {
      userId: uid,
      date: moment().format(),
      type: 'activity',
      title: 'Procedure Change',
      text: 'Procedure has been changed',
    };
    docData.activity = [newActivity, ...docData.activity];
  }

  if (data.hospital !== previousData.hospital || (data.date && (!previousData?.date || !moment(data.date).isSame(moment(previousData?.date), 'day')))) {
    const newActivity = {
      userId: uid,
      date: moment().format(),
      type: 'activity',
      title: 'Case Updated',
      text: 'Case has been updated',
    };
    docData.activity = [newActivity, ...docData.activity];
  }

  try {
    await updateDoc(doc(db, CASES_COLLECTION, id), {
      ...docData,
      surgeon: `/${SURGEONS_COLLECTION}/${data.surgeonId}`,
    });

    const fileUploadPromises = files.map((file) => {
      const currentFile = data.files.find((item) => item.name === file.name);
      const type = currentFile?.type;

      if (type) {
        return uploadBytes(ref(storage, `${data.id}/${data.id}_${type}_${file.name}`), file);
      }
    });

    await Promise.all(fileUploadPromises);

    dispatch({ type: UPDATE_CASE, case: { ...data, surgeon: previousData.surgeon, isPaid: data.isPaid, activity: docData.activity } });
  } catch (error) {
    console.error("Error updating case admin:", error);
  }
};

export const updateCaseEngineer = (id, data, previousData) => async (dispatch, getState) => {
  const db = Firebase.db;
  const state = getState();
  const uid = state.user.currentUser?.uid;

  const docData = { ...data };
  delete docData.sortDate;

  if (data.procedure !== previousData.procedure) {
    const newActivity = {
      userId: uid,
      date: moment().format(),
      type: 'activity',
      title: 'Procedure Change',
      text: 'Procedure has been changed',
    };
    docData.activity = [newActivity, ...docData.activity];
  }

  try {
    await updateDoc(doc(db, CASES_COLLECTION, id), {
      ...docData,
      surgeon: `/${SURGEONS_COLLECTION}/${data.surgeonId}`,
    });

    dispatch({ type: UPDATE_CASE, case: { ...data, surgeon: previousData.surgeon, activity: docData.activity } });
  } catch (error) {
    console.error("Error updating case admin:", error);
  }
};

export const uploadFiles = (caseId, stepData) => async (dispatch, getState) => {
  const db = Firebase.db;
  const state = getState();
  const currentUser = state.user.currentUser;
  const activeCase = state.cases.activeCase;
  const users = state.users.list;
  const surgeon = activeCase.surgeon;
  const isTrainingMode = activeCase.trainingMode;
  const access = hasAccess(currentUser, surgeon, 2, activeCase.procedureFlow, isTrainingMode);
  const filesData = stepData.files;

  dispatch({ type: LOAD_CASES });

  stepData.files = filesData.filter((file) => file.status !== fileStatuses.REJECTED);
  if (currentUser.role === roleNames.RADIOLOGY) {
    stepData.radiologyUser = currentUser.uid;
  }

  try {
    await updateDoc(doc(db, CASES_COLLECTION, caseId), { ...stepData, step: 2 });

    let emails = casesEmails;
    if (activeCase.procedureFlow === procedureFlows.ACL) {
      emails = casesACLEmails;
    } else if (activeCase.procedureFlow === procedureFlows.CMF) {
      emails = casesCMFEmails;
    } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)) {
      emails = casesCMFExtendedEmails;
    }

    const email = emails[1].success;
    const recipients = surgeon.engineer.map((engineer) => users.find((user) => user.uid === engineer));

    if (!isTrainingMode) {
      recipients.forEach((recipient) => {
        if (recipient?.uid !== currentUser?.uid && !recipient?.disabled) {
          dispatch(sendCaseEmail(
            recipient,
            email.subject,
            email.message({ caseId: caseId.includes("_") ? caseId.substring(0, caseId.indexOf("_")) : caseId }),
            email.additionalText,
            true,
            [
              { key: 'CASE ID', value: caseId.includes("_") ? caseId.substring(0, caseId.indexOf("_")) : caseId },
              { key: 'PATIENT SURNAME', value: activeCase.patientInfo?.lastName || '' },
              { key: 'SURGEON', value: users.find((u) => u.uid === surgeon.userId)?.fullName || '' },
            ]
          ));

          dispatch(createActivity(recipient.uid, {
            ...caseNotifications[1].success,
            caseId,
            date: moment().format(),
            userId: currentUser?.uid || '',
          }));
        }
      });
    }

    dispatch(setNotification({ variant: 'success', vertical: 'top', message: alertMessages[1].success }));

    dispatch({
      type: UPDATE_CASE,
      case: { id: caseId, ...stepData, step: 2, access, files: stepData.files.slice() },
    });
  } catch (error) {
    console.error("Error uploading files:", error);
  }
};

export const updateCase = (activeCase, currentStep, newFiles, rejected) => async (dispatch, getState) => {
  const db = Firebase.db;
  const storage = Firebase.storage;
  const state = getState();
  const currentUser = state.user.currentUser;
  const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
  const isTrainingMode = activeCase.trainingMode;
  const access = hasAccess(currentUser, surgeon, activeCase.step, activeCase.procedureFlow, isTrainingMode);
  const surgeonId = activeCase.surgeon.id || state.cases.activeCase.surgeon.id;

  delete activeCase.access;
  delete activeCase.sortDate;

  const files = activeCase.files.map((file) => {
    delete file.downloadLink;
    return file;
  });

  dispatch({ type: LOAD_CASES });

  try {
    if (newFiles?.length) {
      const uploadPromises = newFiles.map((file) => {
        const currentFile = activeCase.files.find((item) => item.name === file.name);
        const type = currentFile?.type;

        if (type) {
          return uploadBytes(ref(storage, `${activeCase.id}/${activeCase.id}_${type}_${file.name}`), file);
        }
      });

      const deletePromises = files
        .filter((file) => file.status === fileStatuses.REJECTED)
        .map(async (file) => {
          try {
            await deleteObject(ref(storage, `${activeCase.id}/${file.id}`));
          } catch (error) {
            if (error.code !== 'storage/object-not-found') {
              console.error(`Error deleting file: ${file.id}`, error);
            }
          }
        });

      await Promise.all([...uploadPromises, ...deletePromises]);

      await updateDoc(doc(db, CASES_COLLECTION, activeCase.id), {
        ...activeCase,
        files: files.filter((file) => file.status !== fileStatuses.REJECTED),
        surgeon: `/${SURGEONS_COLLECTION}/${activeCase.surgeonId}`,
        previousStep: currentStep,
      });
    } else {
      await updateDoc(doc(db, CASES_COLLECTION, activeCase.id), {
        ...activeCase,
        surgeon: `/${SURGEONS_COLLECTION}/${activeCase.surgeonId}`,
        updatedAt: moment().format('YYYY-MM-DD hh:mm A'),
      });
    }

    dispatch(triggerNotification(activeCase, currentStep, rejected, activeCase.uploadFilesUid, currentUser.uid));
    dispatch({ type: UPDATE_CASE, case: { ...activeCase, access } });
  } catch (error) {
    console.error("Error updating case:", error);
  }
};

export const simpleUpdateCase = (activeCase, newFiles) => async (dispatch) => {
  const db = Firebase.db;
  const storage = Firebase.storage;
  const surgeonId = activeCase.surgeon.id;
  const access = activeCase.access;

  delete activeCase.access;
  delete activeCase.sortDate;

  const files = activeCase.files.map((file) => {
    delete file.downloadLink;
    return file;
  });

  try {
    if (newFiles?.length) {
      const uploadPromises = newFiles.map((file) => {
        const currentFile = activeCase.files.find((item) => item.name === file.name);
        const type = currentFile?.type;

        if (type) {
          return uploadBytes(ref(storage, `${activeCase.id}/${activeCase.id}_${type}_${file.name}`), file);
        }
      });

      await Promise.all(uploadPromises);
    }

    await Promise.all(
      files.map(async (file) => {
        if (!file.downloadLink) {
          const currentFile = activeCase.files.find((item) => item.name === file.name);
          const type = currentFile?.type;

          file.downloadLink = await Firebase.getDownloadLink(`${activeCase.id}/${activeCase.id}_${type}_${file.name}`);
        }
      })
    );

    const docData = {
      ...activeCase,
      files,
      surgeon: `/${SURGEONS_COLLECTION}/${activeCase.surgeonId}`,
    };

    await updateDoc(doc(db, CASES_COLLECTION, activeCase.id), docData);
    dispatch({ type: SET_ACTIVE_CASE, case: { ...activeCase, access } });
  } catch (error) {
    console.error("Error updating case:", error);
  }
};

export const deleteCaseFile = (activeCase, fileId) => async (dispatch) => {
  const db = Firebase.db;
  const storage = Firebase.storage;
  const files = activeCase.files.filter((file) => file.id !== fileId);

  try {
    await deleteObject(ref(storage, `${activeCase.id}/${fileId}`));

    delete activeCase.sortDate;

    await setDoc(doc(db, CASES_COLLECTION, activeCase.id), { files }, { merge: true });
    dispatch({ type: UPDATE_CASE, case: { ...activeCase, files } });
  } catch (error) {
    console.error("Error deleting case file:", error);
  }
};

export const updateCaseDoc = (caseId, data) => async () => {
  const db = Firebase.db;

  try {
    await setDoc(doc(db, CASES_COLLECTION, caseId), data, { merge: true });
  } catch (error) {
    console.error("Error updating case document:", error);
  }
};

export const requestCaseDateUpdate = (activeCase, date, hospital) => async (dispatch, getState) => {
  const state = getState();
  const { db } = Firebase;
  const { users, surgeons, distributors } = state;
  const currentUser = state.user.currentUser;

  const requestedSurgeryDate = {
    currentDate: activeCase.date,
    newDate: date?.format(),
    userId: currentUser.uid,
    userName: `${currentUser.firstName} ${currentUser.lastName}`,
  };

  if (hospital) {
    requestedSurgeryDate.currentHospital = activeCase.hospital;
    requestedSurgeryDate.newHospital = hospital;
  }

  try {
    await setDoc(doc(db, CASES_COLLECTION, activeCase.id), { requestedSurgeryDate }, { merge: true });

    const trainingMode = state.sidebar.trainingMode || activeCase.trainingMode;

    if (!trainingMode) {
      const distributor = distributors.list.find((d) => d.id === activeCase.distributorId);
      const surgeon = surgeons.list.find((s) => s.id === activeCase.surgeon.id);
      const surgeonUser = users.list.find((u) => u.uid === surgeon?.userId);

      users.list.forEach((user) => {
        if (
          user.role === userRoles.GLOBAL_ADMIN.name ||
          (user.role === userRoles.COUNTRY_ADMIN.name && user.administrationCountry === distributor.country) ||
          (user.role === userRoles.DISTRIBUTOR_ADMIN.name && user.distributor === distributor.id) ||
          (user.role === userRoles.ENGINEER.name && user.engineerLevel === 1 && surgeon?.engineer?.includes(user.uid))
        ) {
          const parameters = [
            { key: 'CASE ID', value: activeCase?.formattedId || activeCase?.id },
            { key: 'PATIENT SURNAME', value: activeCase.patientInfo?.lastName || '' },
            { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
            { key: 'CURRENT DOS', value: activeCase.date ? moment(activeCase.date).format('DD/MM/YY') : 'TBC' },
            { key: 'NEW DOS', value: date ? moment(date).format('DD/MM/YY') : 'TBC' },
            { key: 'HOSPITAL', value: activeCase.hospital },
          ];

          if (hospital?.name) {
            parameters.push({ key: 'NEW HOSPITAL', value: hospital.name });
          }

          if (!user.disabled) {
            dispatch(
              sendCaseEmail(
                user.email,
                'Request for case date change',
                'Request to change case date has been submitted.',
                `User Requesting change: ${currentUser.firstName} ${currentUser.lastName}.`,
                true,
                parameters
              )
            );

            dispatch(
              createActivity(user.uid, {
                type: 'notification',
                title: 'Case Date Change Requested',
                text: 'User has requested a change to the case date',
                caseId: activeCase.id,
                date: moment().format(),
                userId: currentUser.uid || '',
              })
            );
          }
        }
      });
    }
  } catch (error) {
    console.error("Error requesting case date update:", error);
  }
};

export const approveRequestDateUpdate = (activeCase) => async (dispatch, getState) => {
  const state = getState();
  const { db } = Firebase;
  const { users, surgeons } = state;

  const docData = {
    date: activeCase?.requestedSurgeryDate?.newDate,
    requestedSurgeryDate: null,
  };

  const newHospital = activeCase?.requestedSurgeryDate?.newHospital;
  if (newHospital) {
    docData.hospital = newHospital.name || '';
    docData.hospitalUniq = {
      id: newHospital.id || '',
      name: newHospital.name || '',
    };
  }

  try {
    await setDoc(doc(db, CASES_COLLECTION, activeCase.id), docData, { merge: true });

    const trainingMode = state.sidebar.trainingMode || activeCase.trainingMode;

    if (!trainingMode) {
      const requestUser = users.list.find((u) => u.uid === activeCase?.requestedSurgeryDate?.userId);
      const surgeon = surgeons.list.find((s) => s.id === activeCase.surgeon.id);
      const surgeonUser = users.list.find((u) => u.uid === surgeon?.userId);

      if (requestUser?.email && !requestUser?.disabled) {
        const parameters = [
          { key: 'CASE ID', value: activeCase?.formattedId || activeCase?.id },
          { key: 'PATIENT SURNAME', value: activeCase.patientInfo?.lastName || '' },
          { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
          { key: newHospital ? 'NEW HOSPITAL' : 'HOSPITAL', value: newHospital ? newHospital.name : activeCase.hospital },
          { key: 'NEW DOS', value: docData.date ? moment(docData.date).format('DD/MM/YY') : 'TBC' },
        ];

        dispatch(
          sendCaseEmail(
            requestUser.email,
            'Case Date Change Request: APPROVED',
            'Your request to change the surgery date of the following case has been: APPROVED.',
            'Should you have any questions relating to this change, please contact Personalised Surgery.',
            true,
            parameters
          )
        );
      }

      users.list.forEach((user) => {
        if (
          (user.role === userRoles.ENGINEER.name && surgeon?.engineer?.includes(user.uid)) ||
          (user.role === userRoles.SALES_REP.name && surgeon?.salesRep?.includes(user.uid)) ||
          (user.role === userRoles.PRACTICE_MANAGER.name && surgeon?.manager?.includes(user.uid)) ||
          (user.role === userRoles.LOGISTICS.name && surgeon?.logistics === user.uid) ||
          (user.role === userRoles.MANUFACTURER.name && surgeon?.manufacturer === user.uid) ||
          (user.role === userRoles.REGISTRAR.name && surgeon?.registrar?.includes(user.uid))
        ) {
          const parameters = [
            { key: 'CASE NUMBER', value: activeCase?.formattedId || activeCase?.id },
            { key: 'PATIENT REFERENCE', value: activeCase.patientInfo?.lastName || '' },
            { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
            { key: newHospital ? 'NEW HOSPITAL' : 'HOSPITAL', value: newHospital ? newHospital.name : activeCase.hospital },
            { key: 'NEW CASE DATE', value: docData.date ? moment(docData.date).format('DD/MM/YY') : 'TBC' },
          ];

          if (user.uid !== activeCase?.requestedSurgeryDate?.userId && !user?.disabled) {
            dispatch(
              sendCaseEmail(
                user.email,
                'Case Date Change Request: APPROVED',
                'A new requested surgery date for the following case has been approved.',
                'Should you have any questions relating to this change, please contact Personalised Surgery.',
                true,
                parameters
              )
            );
          }
        }
      });
    }
  } catch (error) {
    console.error("Error approving request date update:", error);
  }
};

export const rejectRequestDateUpdate = (activeCase) => async (dispatch, getState) => {
  const state = getState();
  const { db } = Firebase;
  const { users, surgeons } = state;

  const docData = { requestedSurgeryDate: null };

  try {
    await setDoc(doc(db, CASES_COLLECTION, activeCase.id), docData, { merge: true });

    const trainingMode = state.sidebar.trainingMode || activeCase.trainingMode;

    if (!trainingMode) {
      const requestUser = users.list.find((u) => u.uid === activeCase?.requestedSurgeryDate?.userId);
      const surgeon = surgeons.list.find((s) => s.id === activeCase.surgeon.id);
      const surgeonUser = users.list.find((u) => u.uid === surgeon?.userId);

      if (requestUser?.email && !requestUser?.disabled) {
        const parameters = [
          { key: 'CASE ID', value: activeCase?.formattedId || activeCase?.id },
          { key: 'PATIENT SURNAME', value: activeCase.patientInfo?.lastName || '' },
          { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
          { key: 'HOSPITAL', value: activeCase.hospital },
          { key: 'CURRENT DOS', value: activeCase.date ? moment(activeCase.date).format('DD/MM/YY') : 'TBC' },
        ];

        dispatch(
          sendCaseEmail(
            requestUser.email,
            'Case Date Change Request: REJECTED',
            'Your request to change the surgery date of the following case has been: REJECTED.',
            'Personalised Surgery Admin team have rejected this change for specific operational or logistics reasons. Please contact the team to discuss further.',
            true,
            parameters
          )
        );
      }
    }
  } catch (error) {
    console.error("Error rejecting request date update:", error);
  }
};

export const sendChangeDateHospitalEmail = (id, data, previousData) => async (dispatch, getState) => {
  const state = getState();
  const { users } = state;
  const currentUser = state.user.currentUser;

  const surgeon = users.list.find((user) => user.uid === previousData.surgeon.id);

  let emails = casesEmails;
  if (data.procedureFlow === procedureFlows.ACL) {
    emails = casesACLEmails;
  } else if (data.procedureFlow === procedureFlows.CMF) {
    emails = casesCMFEmails;
  } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(data.procedureFlow)) {
    emails = casesCMFExtendedEmails;
  }

  const dateEmail = emails['0'].updateDate;
  const hospitalEmail = emails['0'].updateHospital;

  const trainingMode = state.sidebar.trainingMode || previousData.trainingMode;

  if (!trainingMode) {
    if (data.date !== previousData.date) {
      const recipients = [];
      const roles = dateEmail.roles.map((role) => role.field);

      for (const key in previousData.surgeon) {
        if (roles.includes(key) && key !== 'surgeon' && previousData.surgeon[key]) {
          if (typeof previousData.surgeon[key] === 'string') {
            recipients.push(users.list.find((user) => user.uid === previousData.surgeon[key]));
          } else {
            previousData.surgeon[key].forEach((recipient) =>
              recipients.push(users.list.find((user) => user.uid === recipient))
            );
          }
        }
      }

      const parameters = [
        { key: 'CASE ID', value: id.includes("_") ? id.substring(0, id.indexOf("_")) : id },
        { key: 'PATIENT SURNAME', value: data.patientInfo?.lastName || '' },
        {
          key: 'OLD DOS',
          value: previousData.date ? moment(previousData.date).format('DD/MM/YY') : 'TBC',
        },
        { key: 'NEW DOS', value: data.date ? moment(data.date).format('DD/MM/YY') : 'TBC' },
      ];

      recipients.forEach((recipient) => {
        if (recipient?.uid !== currentUser?.uid && !recipient?.disabled) {
          dispatch(
            sendCaseEmail(
              recipient.email,
              dateEmail.subject,
              dateEmail.message({ caseId: id.includes("_") ? id.substring(0, id.indexOf("_")) : id }),
              dateEmail.additionalText({ caseId: id.includes("_") ? id.substring(0, id.indexOf("_")) : id }),
              true,
              parameters
            )
          );
        }
      });
    }

    if (data.hospital !== previousData.hospital) {
      const recipients = [surgeon];
      const roles = hospitalEmail.roles.map((role) => role.field);

      for (const key in previousData.surgeon) {
        if (roles.includes(key) && key !== 'surgeon' && previousData.surgeon[key]) {
          if (typeof previousData.surgeon[key] === 'string') {
            recipients.push(users.list.find((user) => user.uid === previousData.surgeon[key]));
          } else {
            previousData.surgeon[key].forEach((recipient) =>
              recipients.push(users.list.find((user) => user.uid === recipient))
            );
          }
        }
      }

      const parameters = [
        { key: 'CASE ID', value: id.includes("_") ? id.substring(0, id.indexOf("_")) : id },
        { key: 'PATIENT SURNAME', value: data.patientInfo?.lastName || '' },
        { key: 'DOS', value: data.date ? moment(data.date).format('DD/MM/YY') : 'TBC' },
        { key: 'OLD HOSPITAL', value: previousData.hospital },
        { key: 'NEW HOSPITAL', value: data.hospital },
      ];

      recipients.forEach((recipient) => {
        if (recipient?.uid !== currentUser?.uid && !recipient?.disabled) {
          dispatch(
            sendCaseEmail(
              recipient.email,
              hospitalEmail.subject,
              hospitalEmail.message({ caseId: id.includes("_") ? id.substring(0, id.indexOf("_")) : id }),
              hospitalEmail.additionalText({ caseId: id.includes("_") ? id.substring(0, id.indexOf("_")) : id }),
              true,
              parameters
            )
          );
        }
      });
    }
  }
};

export const changePreopFile = (activeCase, file) => async (dispatch, getState) => {
  const state = getState();
  const { db, storage } = Firebase;
  const userId = state.user.currentUser.uid;

  const surgeonId = activeCase.surgeon.id;
  const oldPreop = activeCase.files.find((item) => item.type === 'preop');
  const preopIndex = activeCase.files.findIndex((item) => item.type === 'preop');
  const files = activeCase.files.map((item) => ({ ...item, downloadLink: undefined }));

  files[preopIndex] = {
    id: `${activeCase.id}_${fileTypes.preop}_${file.name}`,
    name: file.name,
    type: fileTypes.preop,
    status: fileStatuses.APPROVED,
    date: moment().format(),
    userId,
  };

  try {
    // Delete old preop file and upload new one
    await deleteObject(ref(storage, `${activeCase.id}/${oldPreop.id}`));
    await uploadBytes(ref(storage, `${activeCase.id}/${activeCase.id}_${fileTypes.preop}_${file.name}`), file);

    // Update case document in Firestore
    await updateDoc(doc(db, CASES_COLLECTION, activeCase.id), {
      ...activeCase,
      files,
      surgeon: `/${SURGEONS_COLLECTION}/${activeCase.surgeonId}`,
    });

    dispatch(
      setNotification({
        variant: 'success',
        vertical: 'top',
        message: 'Pre op file has been changed',
      })
    );
  } catch (error) {
    console.error('Error changing preop file:', error);
  }
};

export const confirmPlanningMeeting = (activeCase, planning) => async (dispatch, getState) => {
  const state = getState();
  const currentUser = state.user.currentUser;
  const users = state.users.list;
  const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
  const surgeonUser = users?.find((u) => u.uid === surgeon.userId);

  if (activeCase.sortDate) {
    delete activeCase.sortDate;
  }

  try {
    await dispatch(simpleUpdateCase({ ...activeCase, planning }));

    const email = casesCMFExtendedEmails[3].planningConfirmed;
    const caseId = activeCase?.id?.includes('_') ? activeCase.id.substring(0, activeCase.id.indexOf('_')) : activeCase.id;
    const planningLink = planning.meetingLink || '';
    const planningDate = planning?.requestPlanningDate ? moment(activeCase?.planning?.requestPlanningDate).format('DD/MM/YYYY, h:mm a') : '';

    if (surgeonUser && surgeonUser.uid !== currentUser?.uid && !surgeonUser?.disabled) {
      await dispatch(
        sendCaseEmail(
          surgeonUser.email,
          email.subject(),
          email.message({ ...activeCase, caseId }),
          '',
          true,
          [
            { key: 'MEETING LINK', value: planningLink },
            { key: 'DATE/TIME', value: planningDate },
          ]
        )
      );

      dispatch(
        createActivity(surgeonUser.uid, {
          type: 'activity',
          title: 'Planning Meeting Confirmed',
          text: 'A requested planning meeting has now been confirmed',
          caseId: activeCase.id,
          date: moment().format(),
          userId: currentUser?.uid || '',
        })
      );
    }
  } catch (error) {
    console.error('Error confirming planning meeting:', error);
  }
};

const triggerNotification = (activeCase, currentStep, rejected, customRecipientId, userId) => (dispatch, getState) => {
  const state = getState();
  const { users, procedures } = state;
  const currentUser = state.user.currentUser;
  const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
  const surgeonUser = users.list.find((u) => u.uid === surgeon.userId);
  const procedure = procedures.list.find((p) => p.id === activeCase?.procedure);

  const type = rejected ? 'reject' : 'success';

  let notifications = caseNotifications;
  if (activeCase.procedureFlow === procedureFlows.ACL) {
    notifications = caseACLNotifications;
  } else if (activeCase.procedureFlow === procedureFlows.CMF) {
    notifications = caseCMFNotifications;
  } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)) {
    notifications = caseCMFExtendedNotifications;
  }

  let emails = casesEmails;
  if (activeCase.procedureFlow === procedureFlows.ACL) {
    emails = casesACLEmails;
  } else if (activeCase.procedureFlow === procedureFlows.CMF) {
    emails = casesCMFEmails;
  } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)) {
    emails = casesCMFExtendedEmails;
  }

  let email = ![procedureFlows.ACL, procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.CUSTOM_EXTENDED, procedureFlows.ONCOL, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && activeCase.step === 7 && currentStep === 5 ?
    casesEmails[5].manufacturer : emails[currentStep]?.[type];

  const parameters = email && email.parameters ? email.parameters.map((key) => {
    switch (key) {
      case 'caseId':
        return { key: 'CASE ID', value: activeCase.formattedId || activeCase.id };
      case 'lastName':
        return { key: 'PATIENT SURNAME', value: activeCase?.patientInfo?.lastName || '' };
      case 'date':
        return { key: 'DOS', value: activeCase.date ? moment(activeCase.date).format('DD/MM/YY') : 'TBC' };
      case 'surgeon':
        return { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' };
      case 'hospital':
        return { key: 'HOSPITAL', value: activeCase.hospital };
      case 'procedure':
        return { key: 'PROCEDURE', value: procedure ? procedure.name : '' };
      case 'planningDate':
        return { key: 'DATE/TIME', value: activeCase?.planning?.requestPlanningDate ? moment(activeCase?.planning?.requestPlanningDate).format('DD/MM/YYYY, h:mm a') : '' };
      case 'planningLink':
        return { key: 'MEETING LINK', value: activeCase?.planning?.meetingLink || '' };
      case 'note':
        return { key: 'NOTE', value: activeCase?.notes?.[`step${currentStep}`] || '' };
      default:
        return { key: '', value: '' };
    }
  }) : [];

  if (!activeCase.trainingMode) {
    if (currentStep === 2 && customRecipientId && rejected) {
      const recipient = users.list.find((user) => user.uid === customRecipientId);

      if (recipient?.uid !== currentUser?.uid && !recipient?.disabled) {
        dispatch(
          sendCaseEmail(
            recipient,
            email.subject,
            email.message({
              ...activeCase,
              caseId: activeCase.formattedId || activeCase.id,
            }),
            email.additionalText,
            true,
            parameters
          )
        );
      }
      dispatch(
        createActivity(recipient.uid, {
          ...notifications[currentStep][type],
          caseId: activeCase.id,
          date: moment().format(),
          userId: userId || '',
        })
      );
    }

    if ([procedureFlows.ACL, procedureFlows.CMF].includes(activeCase.procedureFlow) && currentStep > 5) {
      email = null;
    }

    if (email && currentStep !== 2) {
      email.roles.forEach((role) => {
        const recipientId = role.name === roleNames.SURGEON ? surgeon.userId : surgeon[role.field];

        if (!recipientId) {
          return;
        }

        if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && currentStep === 3 && !activeCase.planning?.planApproveRequired) {
          return;
        }

        const recipient = typeof recipientId === 'string'
          ? users.list.find((user) => user.uid === recipientId)
          : recipientId.map((recipientItem) => users.list.find((userItem) => userItem.uid === recipientItem));

        if (typeof recipientId === 'string') {
          if (recipientId !== currentUser?.uid && !recipient?.disabled) {
            dispatch(
              sendCaseEmail(
                recipient,
                email.subject({ ...activeCase, recipient }),
                email.message({
                  ...activeCase,
                  recipient,
                  caseId: activeCase.formattedId || activeCase.id,
                }),
                email.additionalText({ ...activeCase, recipient }),
                true,
                parameters
              )
            );
          }
          dispatch(
            createActivity(recipient.uid, {
              ...notifications[currentStep][type],
              caseId: activeCase.id,
              date: moment().format(),
              userId: userId || '',
            })
          );
        } else {
          recipient.forEach((recipient) => {
            if (recipient?.uid !== currentUser?.uid && !recipient?.disabled) {
              dispatch(
                sendCaseEmail(
                  recipient,
                  email.subject({ ...activeCase, recipient }),
                  email.message({
                    ...activeCase,
                    recipient,
                    caseId: activeCase.formattedId || activeCase.id,
                  }),
                  email.additionalText({ ...activeCase, recipient }),
                  true,
                  parameters
                )
              );
            }
            dispatch(
              createActivity(recipient.uid, {
                ...notifications[currentStep][type],
                caseId: activeCase.id,
                date: moment().format(),
                userId: userId || '',
              })
            );
          });
        }
      });
    }
  }

  const messages =
    activeCase.procedureFlow === procedureFlows.ACL
      ? alertAclMessages
      : activeCase.procedureFlow === procedureFlows.CMF
        ? alertCmfMessages
        : [procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)
          ? alertCmfExtendedMessages
          : alertMessages;

  const message = messages[currentStep][type];

  if (message) {
    dispatch(
      setNotification({
        variant: rejected ? 'warning' : 'success',
        vertical: 'top',
        message,
      })
    );
  }
};

// const onNoteAdded = (activeCase, note) => (dispatch, getState) => {
//   const state = getState();
//   const userId = state.user.currentUser.uid;
//   const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
//   const users = state.users.list;
//
//   if (!activeCase.trainingMode) {
//     const recipients = [];
//
//     users?.forEach((user) => {
//       if ([roleNames.GLOBAL_ADMIN, roleNames.COUNTRY_ADMIN, roleNames.DISTRIBUTOR_ADMIN].includes(user.role)) {
//         recipients?.push(user.uid);
//       } else if (user.role === roleNames.SURGEON && surgeon.userId === user.uid) {
//         recipients?.push(user.uid);
//       } else {
//         const roleField = userRoles[ user.role ]?.field || '';
//         const recipientId = surgeon[ roleField ];
//
//         if (( typeof recipientId === 'string' && user.uid === recipientId ) || recipientId?.includes(user.uid)) {
//           recipients?.push(user.uid);
//         }
//       }
//     });
//
//     recipients?.forEach((recipient) => {
//       dispatch(
//         createActivity(recipient.uid, {
//           type: 'notification',
//           title: 'Note Added',
//           text: note,
//           caseId: activeCase.id,
//           date: moment().format(),
//           userId: userId || ''
//         })
//       );
//     });
//   }
// };

export const notifyUsers = (activeCase) => (dispatch, getState) => {
  if (!activeCase) return;

  const state = getState();
  const { users, procedures } = state;
  const currentUser = state.user.currentUser;
  const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
  const type = activeCase.status === statuses.rejected ? 'reject' : 'success';
  const previousStep = activeCase.previousStep || activeCase.step - 1;

  const surgeonUser = users.list.find((u) => u.uid === surgeon.userId);
  const procedure = procedures.list.find((p) => p.id === activeCase?.procedure);

  // Get the appropriate email template
  let email = casesEmails[previousStep][type];

  // Map parameters for email and SMS
  const parameters = email?.parameters?.map((key) => {
    switch (key) {
      case 'caseId':
        return { key: 'CASE ID', value: activeCase.formattedId || activeCase.id };
      case 'lastName':
        return { key: 'PATIENT SURNAME', value: activeCase?.patientInfo?.lastName || '' };
      case 'date':
        return { key: 'DOS', value: activeCase.date ? moment(activeCase.date).format('DD/MM/YY') : 'TBC' };
      case 'surgeon':
        return { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' };
      case 'hospital':
        return { key: 'HOSPITAL', value: activeCase.hospital };
      case 'procedure':
        return { key: 'PROCEDURE', value: procedure ? procedure.name : '' };
      default:
        return { key: '', value: '' };
    }
  }) || [];

  // Prevent email sending for specific cases
  if (!activeCase.trainingMode) {
    if (
      [procedureFlows.ACL, procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) &&
      previousStep === 6
    ) {
      email = null;
    }

    // If there's an email template, send notifications
    if (email) {
      email.roles.forEach((role) => {
        const recipientId = role.name === roleNames.SURGEON ? surgeon.userId : surgeon[role.field];
        if (!recipientId) return;

        const getRecipient = (id) => users.list.find((user) => user.uid === id);

        const recipients = Array.isArray(recipientId)
          ? recipientId.map(getRecipient)
          : [getRecipient(recipientId)];

        recipients.forEach((recipient) => {
          if (recipient && recipient.uid !== currentUser.uid && !recipient?.disabled) {
            dispatch(
              sendCaseEmail(
                recipient.email,
                email.subject({ ...activeCase, recipient }),
                email.message({ ...activeCase, recipient, caseId: activeCase.formattedId || activeCase.id }),
                email.additionalText({ ...activeCase, recipient }),
                true,
                parameters
              )
            );
          }
          if (recipient && recipient.phone && !recipient?.disabled) {
            const text = email.message({ ...activeCase, recipient, caseId: activeCase.formattedId || activeCase.id });
            api.post(urls.sendSms, { phone: recipient.phone, text, parameters });
          }
        });
      });
    }
  }

  dispatch(setNotification({ variant: 'success', vertical: 'bottom', message: 'Notifications were sent via SMS and email' }));
};

export const setActiveCase = (newCase) => (dispatch, getState) => {
  const { procedures } = getState();

  if (!newCase.procedureFlow) {
    const procedureFlow = procedures.list.find((pr) => pr.id === newCase.procedure)?.flow;
    newCase.procedureFlow = procedureFlow;
  }

  dispatch({ type: SET_ACTIVE_CASE, case: newCase });
};

export const setActiveCaseById = (caseId) => (dispatch, getState) => {
  const { cases } = getState();
  const newCase = cases.list.find((item) => item.id === caseId);

  if (newCase) {
    dispatch({ type: SET_ACTIVE_CASE, case: newCase });
  }
};

export const discardActiveCase = () => (dispatch) => {
  setTimeout(() => dispatch({ type: DISCARD_ACTIVE_CASE }), 500);
};

export const resetCases = () => ({ type: RESET_CASES });

export const deleteCase = (caseId) => async (dispatch) => {
  try {
    const db = Firebase.db;

    await setDoc(doc(db, CASES_COLLECTION, caseId), { archived: true }, { merge: true });
    dispatch(discardActiveCase());
    dispatch(
      setNotification({
        variant: 'success',
        vertical: 'top',
        message: 'Case successfully archived',
      })
    );
  } catch (error) {
    console.error('Error archiving case:', error);
  }
};

export const restoreCase = (caseId) => async (dispatch) => {
  try {
    const db = Firebase.db;

    await setDoc(doc(db, CASES_COLLECTION, caseId), { archived: false }, { merge: true });
    dispatch(discardActiveCase());
    dispatch(
      setNotification({
        variant: 'success',
        vertical: 'top',
        message: 'Case successfully restored',
      })
    );
  } catch (error) {
    console.error('Error restoring case:', error);
  }
};

export const setNotes = (notes) => ({ type: SET_CASE_NOTES, notes });

export const createNote = (activeCase, note) => async (dispatch, getState) => {
  const state = getState();
  const { uid } = state.user.currentUser;
  const users = state.users.list;
  const surgeons = state.surgeons.list;
  const notesCollection = collection(Firebase.db, NOTES_SUB_COLLECTION(activeCase.id));
  const newNote = { ...note, userId: uid, date: moment().format() };

  try {
    // Add the note to Firestore
    const docRef = await addDoc(notesCollection, newNote);
    dispatch({ type: ADD_CASE_NOTE, id: docRef.id, note: newNote });

    // Define recipients
    let recipients = [{ uid: 'casenote@personalisedsurgery.com', email: 'casenote@personalisedsurgery.com' }];
    const surgeonDoc = surgeons.find((s) => s.id === activeCase.surgeonId);
    const engineers = Array.isArray(surgeonDoc.engineer) ? surgeonDoc.engineer : [surgeonDoc.engineer];
    const salesReps = Array.isArray(surgeonDoc.salesRep) ? surgeonDoc.salesRep : [surgeonDoc.salesRep];

    engineers.forEach((engineerUid) => {
      const user = users.find((u) => u.uid === engineerUid);
      if (user && user.email && !recipients.some((r) => r.email === user.email)) {
        recipients.push(user);
      }
    });

    if (note.external) {
      salesReps.forEach((salesRepUid) => {
        const user = users.find((u) => u.uid === salesRepUid);
        if (user && user.email && !recipients.some((r) => r.email === user.email)) {
          recipients.push(user);
        }
      });

      const surgeonUser = users.find((user) => user.uid === (activeCase.surgeon || state.cases.activeCase.surgeon).userId);
      if (surgeonUser) {
        recipients.push(surgeonUser);
      }
    }

    // Filter out the current user from the recipients
    recipients = recipients.filter((user) => user.uid !== uid);

    // Send notifications and emails to recipients
    await Promise.all(
      recipients.map((recipient) => {
        if (!recipient?.disabled) {
          return dispatch(
            sendCaseEmail(
              recipient.email,
              'New Case Note',
              `New note was added for the case ${activeCase?.formattedId || activeCase?.id}`,
              note.text
            )
          );
        }
      })
    );

    await Promise.all(
      recipients.map((recipient) => {
        if (!recipient?.disabled) {
          return dispatch(
            createActivity(recipient.uid, {
              type: 'notification',
              title: 'New Case Note',
              text: note.text,
              caseId: activeCase.id,
              date: moment().format(),
              userId: uid || '',
            })
          );
        }
      })
    );
  } catch (error) {
    console.error('Error creating note:', error);
  }
};

export const fetchNotes = (caseId) => async (dispatch) => {
  const notesCollection = collection(Firebase.db, NOTES_SUB_COLLECTION(caseId));

  try {
    const snapshot = await getDocs(notesCollection);
    const notes = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    dispatch(setNotes(orderBy(notes, 'date')));
  } catch (error) {
    console.error('Error fetching notes:', error);
  }
};

export const sendCaseEmail = (recipient, subject, message, additionalText, isCase, parameters) => async (dispatch, getState) => {
  const { notifications } = getState().settings;

  if (notifications?.active) {
    try {
      return sendEmail(recipient, subject, message, additionalText, isCase, parameters);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  }
};

const fetchSurgeonData = async (surgeonRef) => {
  const surgeonDoc = await getDoc(surgeonRef);
  return surgeonDoc.exists() ? { id: surgeonDoc.id, ...surgeonDoc.data() } : null;
};

export const subscribeToCases = () => (dispatch, getState) => {
  const db = Firebase.db;
  const state = getState();
  const uid = state.user.currentUser?.uid;
  const role = state.user.currentUser?.role;
  const country = state.user?.currentUser?.administrationCountry || null;
  const users = state.users.list;
  const distributors = state.distributors.list;

  // Skip subscription if user role is Radiology
  if (role === userRoles.RADIOLOGY.name) {
    console.log("Subscription skipped for Radiology role.");
    return () => {}; // Return a no-op function as the unsubscribe placeholder
  }

  let unsubscribe;

  // Fetch surgeons and set up subscription
  fetchSurgeons(uid, role).then((surgeons) => {
    const surgeonIds = surgeons.map((surgeon) => surgeon.id);

    if (!surgeonIds.length && role !== userRoles.GLOBAL_ADMIN.name) {
      console.log("No assigned surgeons, skipping subscription.");
      dispatch(setCases([]));
      return;
    }

    // Firestore query for all cases
    // const casesQuery = query(collection(db, CASES_COLLECTION), where('status', '!=', 'COMPLETED'));
    const casesQuery = query(collection(db, CASES_COLLECTION));

    // Subscribe to the query
    unsubscribe = onSnapshot(
      casesQuery,
      async (snapshot) => {
        const cases = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log(`Fetched ${cases.length} cases`);

        // Fetch surgeon data and enrich cases
        const enrichedCases = await Promise.all(
          cases.map(async (caseData) => {
            const surgeonRef =
              typeof caseData.surgeon === "string"
                ? doc(Firebase.db, caseData.surgeon)
                : caseData.surgeon;
            const surgeonData = await fetchSurgeonData(surgeonRef);

            const surgeonUser = users.find(
              (user) => user.uid === surgeonData?.userId
            );
            const surgeonName = surgeonUser
              ? `${surgeonUser?.firstName} ${surgeonUser?.lastName}`
              : "";
            const currentUser = state.user.currentUser;
            const isTrainingMode = caseData.trainingMode;

            return {
              ...caseData,
              surgeon: surgeonData,
              surgeonName,
              access: hasAccess(
                currentUser,
                surgeonData,
                caseData.step,
                caseData.procedureFlow,
                isTrainingMode
              ),
            };
          })
        );

        // Filter cases by assigned surgeons if role is not GLOBAL_ADMIN
        let filteredCases = enrichedCases;
        if (role !== userRoles.GLOBAL_ADMIN.name) {
          filteredCases = enrichedCases.filter((caseItem) =>
            surgeonIds.includes(caseItem.surgeon?.id)
          );
        }

        // Apply role-specific filtering
        if (role === userRoles.COUNTRY_ADMIN.name) {
          filteredCases = filteredCases.filter((item) =>
            distributors
              .find((d) => d.id === item.distributorId)
              ?.country === country
          );
        }

        filteredCases = filteredCases.filter((caseItem) => {
          if (role === userRoles.SURGEON.name) {
            return caseItem.surgeon?.userId === uid;
          }
          return true;
        });

        // Dispatch sorted cases
        dispatch(
          setCases(filteredCases.sort((a, b) => (a.date > b.date ? 1 : -1)))
        );
      },
      (error) => {
        console.error("Error fetching cases:", error);
      }
    );
  });

  // Return the unsubscribe function
  return () => {
    if (unsubscribe) unsubscribe();
  };
};

// Filter on BE using chunks:
// export const subscribeToCases = () => (dispatch, getState) => {
//   const db = Firebase.db;
//   const state = getState();
//   const uid = state.user.currentUser?.uid;
//   const role = state.user.currentUser?.role;
//   const country = state.user?.currentUser?.administrationCountry || null;
//   const users = state.users.list;
//   const distributors = state.distributors.list;
//
//   // Skip subscription if user role is Radiology
//   if (role === userRoles.RADIOLOGY.name) {
//     console.log("Subscription skipped for Radiology role.");
//     return () => {}; // Return a no-op function as the unsubscribe placeholder
//   }
//
//   let unsubscribes = []; // Store multiple unsubscribe functions
//   let casesFetchedCount = 0; // Track the number of queries completed
//   const allCases = new Map(); // To merge and deduplicate cases
//
//   // Fetch surgeons and set up subscription
//   fetchSurgeons(uid, role).then((surgeons) => {
//     const surgeonIds = surgeons.map((surgeon) => surgeon.id);
//
//     if (!surgeonIds.length && role !== userRoles.GLOBAL_ADMIN.name) {
//       console.log("No assigned surgeons, skipping subscription.");
//       dispatch(setCases([]));
//       return;
//     }
//
//     // Firestore queries
//     const casesQueries =
//       role === userRoles.GLOBAL_ADMIN.name
//         ? [query(collection(db, CASES_COLLECTION))] // One query for GLOBAL_ADMIN
//         : chunk(surgeonIds, 10).map((idsChunk) =>
//           query(
//             collection(db, CASES_COLLECTION),
//             where("surgeonId", "in", idsChunk) // Split queries into chunks of 10 surgeon IDs
//           )
//         );
//
//     // Subscribe to each query and merge results
//     unsubscribes = casesQueries.map((casesQuery, index) =>
//       onSnapshot(
//         casesQuery,
//         async (snapshot) => {
//           const cases = snapshot.docs.map((doc) => ({
//             id: doc.id,
//             ...doc.data(),
//           }));
//
//           console.log(`Fetched ${cases.length} cases from query ${index}:`, cases);
//
//           // Fetch surgeon data and enrich cases
//           const enrichedCases = await Promise.all(
//             cases.map(async (caseData) => {
//               const surgeonRef =
//                 typeof caseData.surgeon === "string"
//                   ? doc(Firebase.db, caseData.surgeon)
//                   : caseData.surgeon;
//               const surgeonData = await fetchSurgeonData(surgeonRef);
//
//               const surgeonUser = users.find(
//                 (user) => user.uid === surgeonData?.userId
//               );
//               const surgeonName = surgeonUser
//                 ? `${surgeonUser?.firstName} ${surgeonUser?.lastName}`
//                 : "";
//               const currentUser = state.user.currentUser;
//               const isTrainingMode = caseData.trainingMode;
//
//               return {
//                 ...caseData,
//                 surgeon: surgeonData,
//                 surgeonName,
//                 access: hasAccess(
//                   currentUser,
//                   surgeonData,
//                   caseData.step,
//                   caseData.procedureFlow,
//                   isTrainingMode
//                 ),
//               };
//             })
//           );
//
//           // Update allCases map
//           enrichedCases.forEach((caseItem) => {
//             allCases.set(caseItem.id, caseItem); // Deduplicate by case ID
//           });
//
//           casesFetchedCount += 1;
//
//           // Check if all queries are complete
//           if (casesFetchedCount === casesQueries.length) {
//             let finalCases = Array.from(allCases.values());
//             if (role === userRoles.COUNTRY_ADMIN.name) {
//               finalCases = finalCases.filter((item) =>
//                 distributors
//                   .find((d) => d.id === item.distributorId)
//                   ?.country === country
//               );
//             }
//
//             const filteredCasesByRole = finalCases.filter((caseItem) => {
//               if (role === userRoles.SURGEON.name) {
//                 return caseItem.surgeon?.userId === uid && !caseItem?.archived;
//               }
//               return !caseItem?.archived;
//             });
//
//             // Dispatch sorted cases only once
//             dispatch(
//               setCases(filteredCasesByRole.sort((a, b) => (a.date > b.date ? 1 : -1)))
//             );
//           }
//         },
//         (error) => {
//           console.error("Error fetching cases:", error);
//         }
//       )
//     );
//   });
//
//   // Return a combined unsubscribe function
//   return () => {
//     unsubscribes.forEach((unsubscribe) => unsubscribe && unsubscribe());
//   };
// };
