import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from '../Modal';
import GlobalAdminContainer from './global/GlobalAdminContainer';
import CountryAdminContainer from './country/CountryAdminContainer';
import DistributorAdminContainer from './distributor/DistributorAdminContainer';
import RestrictComponent from '../../shared/RestrictComponent';

import {
  fetchCountryList,
  fetchProcedures,
  fetchDistributors,
  fetchHospitals,
  fetchManufacturers,
  fetchRadiology,
  fetchSurgeons,
  fetchDocuments,
  fetchProducts,
  fetchUsers,
} from '../../../actions/adminActions';

import { userRoles } from '../../../constants';

import isAccessPermitted from '../../../util/restrict';

import './admin-modal.scss';

const globalAdminTabs = ['Global', 'Country', 'Distributor'];
const countryAdminTabs = ['Country', 'Distributor'];
const distributorAdminTabs = ['Distributor'];

const { GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN } = userRoles;

const AdminModal = (props) => {
  const {
    open,
    onClose,
    fetchCountries,
    fetchProcedures,
    fetchDistributors,
    fetchHospitals,
    fetchManufacturers,
    fetchRadiology,
    fetchSurgeons,
    fetchDocuments,
    fetchProducts,
    fetchUsers,
    userRole,
    emailVerified,
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);

      const promises = [fetchCountries(), fetchDistributors(), fetchDocuments(), fetchProducts()];

      if (isAccessPermitted({ roles: [GLOBAL_ADMIN], userRole, emailVerified })) {
        promises.push(fetchProcedures());
      }

      if (isAccessPermitted({ roles: [GLOBAL_ADMIN, COUNTRY_ADMIN], userRole, emailVerified })) {
        promises.push(fetchHospitals(), fetchManufacturers(), fetchRadiology());
      }

      if (
        isAccessPermitted({
          roles: [GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN],
          userRole,
          emailVerified,
        })
      ) {
        promises.push(fetchSurgeons());
      }

      Promise.all(promises).finally(() => setLoading(false));
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps
  // Disable eslint rule for props in useEffect

  const refreshData = async () => {
    try {
      setFetching(true);
      await Promise.all([
        fetchCountries(),
        fetchProcedures(),
        fetchDistributors(),
        fetchHospitals(),
        fetchManufacturers(),
        fetchRadiology(),
        fetchSurgeons(),
        fetchDocuments(),
        fetchProducts(),
        fetchUsers(),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const renderContent = () => {
    let globalTab = 0;
    let countryTab = 0;
    let distributorTab = 0;

    if (userRole === GLOBAL_ADMIN.name) {
      countryTab = 1;
      distributorTab = 2;
    }

    if (userRole === COUNTRY_ADMIN.name) {
      globalTab = null;
      distributorTab = 1;
    }

    if (userRole === DISTRIBUTOR_ADMIN.name) {
      globalTab = null;
      countryTab = null;
    }

    switch (activeTab) {
      case globalTab:
        return (
          <RestrictComponent roles={[GLOBAL_ADMIN]}>
            <GlobalAdminContainer
              onClose={onClose}
              editMode={editMode}
              onSubmit={() => setEditMode(false)}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
            />
          </RestrictComponent>
        );
      case countryTab:
        return (
          <RestrictComponent roles={[COUNTRY_ADMIN, GLOBAL_ADMIN]}>
            <CountryAdminContainer
              onClose={onClose}
              editMode={editMode}
              onSubmit={() => setEditMode(false)}
            />
          </RestrictComponent>
        );
      case distributorTab:
        return (
          <RestrictComponent roles={[GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN]}>
            <DistributorAdminContainer
              onClose={onClose}
              editMode={editMode}
              onSubmit={() => setEditMode(false)}
            />
          </RestrictComponent>
        );
      default:
        return (
          <RestrictComponent roles={[GLOBAL_ADMIN]}>
            <GlobalAdminContainer
              onClose={onClose}
              editMode={editMode}
              onSubmit={() => setEditMode(false)}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
            />
          </RestrictComponent>
        );
    }
  };

  const getTabs = () => {
    switch (userRole) {
      case GLOBAL_ADMIN.name:
        return globalAdminTabs;
      case COUNTRY_ADMIN.name:
        return countryAdminTabs;
      case DISTRIBUTOR_ADMIN.name:
        return distributorAdminTabs;
      default:
        return distributorAdminTabs;
    }
  };

  const tabs = getTabs();

  return (
    <Modal
      open={open}
      onClose={onClose}
      onTabChange={setActiveTab}
      title={`${tabs ? tabs[activeTab] : 'Distributor'} administrator`}
      subtitle="Admin"
      tabs={tabs}
      activeTab={activeTab}
      size="lg"
      adminModal
      onRefreshClick={refreshData}
      isGlobalAdmin={userRole === userRoles.GLOBAL_ADMIN.name}
      onSettingsClick={() => setSettingsOpen(true)}
    >
      {(loading || fetching) ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        renderContent()
      )}
    </Modal>
  );
};

AdminModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  userRole: PropTypes.string,
  fetchCountries: PropTypes.func,
  fetchProcedures: PropTypes.func,
  fetchDistributors: PropTypes.func,
  fetchHospitals: PropTypes.func,
  fetchManufacturers: PropTypes.func,
  fetchRadiology: PropTypes.func,
  fetchSurgeons: PropTypes.func,
  fetchProducts: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userRole: state.user.currentUser.role,
    emailVerified: state.user.currentUser.emailVerified,
    isLoading:
      state.users.loading ||
      state.distributors.loading ||
      state.hospitals.loading ||
      state.manufacturers.loading ||
      state.procedures.loading ||
      state.surgeons.loading ||
      state.products.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCountries: () => dispatch(fetchCountryList()),
    fetchProcedures: () => dispatch(fetchProcedures()),
    fetchDistributors: () => dispatch(fetchDistributors()),
    fetchHospitals: () => dispatch(fetchHospitals()),
    fetchManufacturers: () => dispatch(fetchManufacturers()),
    fetchRadiology: () => dispatch(fetchRadiology()),
    fetchSurgeons: () => dispatch(fetchSurgeons()),
    fetchDocuments: () => dispatch(fetchDocuments()),
    fetchProducts: () => dispatch(fetchProducts()),
    fetchUsers: () => dispatch(fetchUsers())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminModal);
