import { LOAD_USER_DATA, SET_CURRENT_USER, UPDATE_USER_DATA } from './actionTypes';
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Firebase from '../firebase'; // Make sure Firebase instance uses modular SDK setup
import { USER_AUTHORIZATION_COLLECTION, USER_DATA_COLLECTION } from '../firebase/collections';

// Initialize Firestore and Storage instances
const db = getFirestore();
const storage = getStorage();

export const setCurrentUser = (user) => (dispatch) => {
  if (user && user.profileImage) {
    const imageRef = ref(storage, `${user.uid}/profileImage/${user.profileImage}`);
    getDownloadURL(imageRef).then((photoUrl) =>
      dispatch({ type: SET_CURRENT_USER, user: { ...user, photoUrl } })
    );
  }

  return dispatch({ type: SET_CURRENT_USER, user });
};

export const simpleUpdateUser = (user) => ({ type: UPDATE_USER_DATA, user });

export const updateUserData = (uid, data, currentPassword, newPassword, profileImage) => async (dispatch) => {
  const userDoc = doc(db, USER_DATA_COLLECTION, uid);

  dispatch({ type: LOAD_USER_DATA });

  try {
    // Update password if provided
    if (currentPassword && newPassword) {
      await Firebase.doReauthenticate(currentPassword);
      await Firebase.doPasswordUpdate(newPassword);

      await setDoc(userDoc, { adminPassword: false }, { merge: true });
      dispatch({
        type: UPDATE_USER_DATA,
        user: { ...data, adminPassword: false },
      });
    }

    // Upload profile image if provided
    if (profileImage) {
      const imageRef = ref(storage, `${uid}/profileImage/${profileImage.name}`);
      await uploadBytes(imageRef, profileImage);

      const photoUrl = await getDownloadURL(imageRef);
      await updateDoc(userDoc, { ...data, profileImage: profileImage.name });

      return dispatch({
        type: UPDATE_USER_DATA,
        user: { ...data, profileImage: profileImage.name, photoUrl },
      });
    }

    // Update user data
    await updateDoc(userDoc, data);
    dispatch({ type: UPDATE_USER_DATA, user: { ...data } });

  } catch (error) {
    console.error('Error updating user data:', error);
  }
};

export const savePushNotificationsToken = (token) => (dispatch, getState) => {
  const state = getState();
  const currentUser = state.user.currentUser;

  if (currentUser?.uid) {
    const userAuthDoc = doc(db, USER_AUTHORIZATION_COLLECTION, currentUser.uid);
    return setDoc(userAuthDoc, { pushNotificationsToken: token }, { merge: true });
  }
};
