import moment from 'moment';
import {
  LOAD_ACTIVITY_LIST,
  SET_ACTIVITY_LIST,
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  RESET_ACTIVITY,
} from './actionTypes';
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  addDoc,
  deleteDoc,
  query,
  orderBy
} from 'firebase/firestore';
import { api } from '../util';
import urls from '../constants/urls';
import { ACTIVITY_COLLECTION, ACTIVITY_SUB_COLLECTION } from '../firebase/collections';

// Initialize Firestore
const db = getFirestore();

export const setActivityList = (activityList) => ({ type: SET_ACTIVITY_LIST, activityList });

export const fetchActivity = () => (dispatch, getState) => {
  const state = getState();
  const uid = state.user.currentUser.uid;
  const activityCollection = collection(db, ACTIVITY_COLLECTION, uid, ACTIVITY_SUB_COLLECTION);

  const date = moment().subtract(14, 'days');

  // Query the sub-collection, ordering by date in descending order
  const activityQuery = query(activityCollection, orderBy('date', 'desc'));

  return getDocs(activityQuery)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((activity) =>
      dispatch(setActivityList(activity?.filter((item) => moment(item.date).isAfter(date))))
    );
};

export const createActivity = (uid, activity) => (dispatch, getState) => {
  const state = getState();
  const notificationsSettings = state.settings?.notifications;
  const activityCollection = collection(db, ACTIVITY_COLLECTION, uid, ACTIVITY_SUB_COLLECTION);

  dispatch({ type: LOAD_ACTIVITY_LIST });

  return addDoc(activityCollection, activity)
    .then(() => {
      dispatch({ type: CREATE_ACTIVITY, activity });
      if (notificationsSettings.active) {
        createPushNotification(uid, { title: activity?.title, body: activity?.text }).catch();
      }
      return dispatch(fetchActivity());
    })
    .catch((error) => console.log('Error creating activity:', error));
};

export const createPushNotification = (uid, notification) => {
  return api.post(urls.sendPushNotification, {
    uid,
    title: notification?.title || '',
    body: notification?.body || ''
  });
};

export const deleteActivity = (id, uid) => (dispatch, getState) => {
  const state = getState();
  const userId = uid || state.user.currentUser.uid;
  const activityDoc = doc(db, ACTIVITY_COLLECTION, userId, ACTIVITY_SUB_COLLECTION, id);

  dispatch({ type: LOAD_ACTIVITY_LIST });

  return deleteDoc(activityDoc)
    .then(() => dispatch({ type: DELETE_ACTIVITY, id }))
    .catch((error) => console.log('Error deleting activity:', error));
};

export const resetActivity = () => ({ type: RESET_ACTIVITY });
