import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions/DialogActions';
import { withStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: { width: '450px' },
  primary: { color: theme.palette.primary.main },
}));

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #f2f2f2',
  },
}))(MuiDialogActions);

const AlertModal = ({
  open,
  title,
  text,
  additionalText,
  onSubmit,
  onClose,
  submitText,
  closeText,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && (
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
          {text}
        </DialogContentText>
        {additionalText && (
          <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
            {additionalText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-1">
          <div
            className="flex-1 p-md text-center secondary pointer font-bold"
            style={{ borderRight: '1px solid #f2f2f2' }}
            onClick={onClose}
          >
            {closeText || 'Back'}
          </div>
          <div
            className={`${classes.primary} flex-1 p-md text-center pointer font-bold`}
            onClick={onSubmit}
          >
            {submitText || 'Confirm'}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AlertModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  submitText: PropTypes.string,
  closeText: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default AlertModal;
