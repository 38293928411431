import React, { useState, useEffect } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Firebase from '../../firebase';
import Modal from '../modals/Modal';
import IframeViewer from '../shared/iframe/IframeViewer';

const TermsModal = ({ year, open, onClose, onSubmit }) => {
  const [checked, setChecked] = useState(false);
  const [termsOfUseUrl, setTermsOfUseUrl] = useState('');

  useEffect(() => {
    if (open) {
      const fetchTermsOfUse = async () => {
        try {
          const response = await Firebase.getDownloadLink('static/PS_terms_of_use.pdf');
          setTermsOfUseUrl(response);  // Set the Firebase URL for the PDF
        } catch (error) {
          console.error('Error fetching terms of use:', error);
        }
      };

      fetchTermsOfUse();
    } else {
      setTermsOfUseUrl('');  // Clear URL when modal closes
    }
  }, [open]);

  return (
    <Modal
      size="md"
      fullWidth
      open={open}
      onClose={onClose}
      title={`Terms of Service ${year}`}
      subtitle="Personalised Surgery Pty Ltd"
      submitButton={onSubmit ? 'Accept' : null}
      onSubmit={onSubmit}
      disabled={!checked}
    >
      <div className="footer-modal__content">
        <div className="footer-modal__block">
          {termsOfUseUrl ? (
            <IframeViewer fileUrl={termsOfUseUrl} />
          ) : (
            <p>Loading terms...</p>
          )}
        </div>

        {onSubmit && (
          <div className="m-t-lg">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
              }
              label="I have read and agree to the Terms & Conditions"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TermsModal;
