import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Papa from 'papaparse';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Button from '../../../shared/Button';
import MultiSelect from '../../../shared/MultiSelect';

import ProcedureList from './procedure/procedure-list';
import DocumentsList from './DocumentsList';
import DocumentsUpload from './DocumentsUpload';
import ProductsList from './products/products-list';
import ProductsForm from './products/products-form';
import NewUser from '../NewUser';
import UserList from '../UserList';
import Dropzone from '../../../shared/dropzone/Dropzone';
import Alert from '../../../shared/Alert';
import ConfirmationModal from '../../ConfirmationModal';

import { countries, userRoles } from '../../../../constants';

import {
  updateCountryList,
  setRole,
  toggleProcedure,
  updateProducts,
  updateDocuments,
  addProcedure,
  deleteProcedure,
  addDocument,
  deleteDocument,
  deleteProduct,
  updateFlow,
  updateLeadDays,
  fetchProducts,
  addProduct,
  updateNotificationsSettings,
} from '../../../../actions/adminActions';

const tabs = [
  { id: 0, label: 'Admin', buttonLabel: 'Admin' },
  { id: 1, label: 'Procedure', buttonLabel: 'Procedure' },
  { id: 2, label: 'Documents', buttonLabel: 'Documents' },
  { id: 3, label: 'Products', buttonLabel: 'Products' },
];

const GlobalAdminContainer = (props) => {
  const {
    onClose,
    users,
    countryAdmins,
    countryList,
    updateCountries,
    setRole,
    uid,
    procedures,
    toggleProcedure,
    updateProducts,
    updateDocuments,
    addProcedure,
    deleteProcedure,
    addDocument,
    deleteDocument,
    documents,
    products,
    deleteProduct,
    updateFlow,
    updateLeadDays,
    addProduct,
    settings,
    updateNotificationsSettings,
    settingsOpen,
    setSettingsOpen
  } = props;

  const [countryLoading, setCountryLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [country, setCountry] = useState('Australia');
  const [selectedCountries, setSelectedCountries] = useState(countryList);
  const [addCountry, setAddCountry] = useState(false);

  const [addMode, setAddMode] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [newProcedure, setNewProcedure] = useState('');

  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const [missingProducts, setMissingProducts] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);

  const [confirmModalOpen, toggleConfirmModal] = useState(false);

  const handleSaveClick = () => {
    setCountryLoading(true);
    updateCountries(selectedCountries).then(() => {
      setCountryLoading(false);
      setAddCountry(false);
      setCountry(selectedCountries[0]);
    });
  };

  const handleCountryDelete = (value) =>
    setSelectedCountries(selectedCountries.filter((item) => item !== value));

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    setAddMode(false);
  };

  const addNewUser = () => {
    if (!newUser) {
      return;
    }

    setRole(newUser.uid, userRoles.COUNTRY_ADMIN.name, country);
    setAddMode(false);
  };

  const handleAddProcedure = () => {
    if (!newProcedure.length) {
      return;
    }

    addProcedure(newProcedure, country);
    setNewProcedure('');
    setAddMode(false);
  };

  const handleSubmit = (e) => {
    if (e.keyCode === 13) {
      handleAddProcedure();
    }
  };

  const handleAddFlow = (id, flow) => {
    updateFlow(id, flow);
  };
  const handleDeleteFlow = (id) => {
    const deleteFlow = (procedures.find((item) => item.id === id).flow = null);
    updateFlow(id, deleteFlow);
  };

  const handleAddProduct = (id, product) => {
    const products = procedures.find((item) => item.id === id).products.slice();

    products.push(product);
    updateProducts(id, products);
  };

  const handleDeleteProduct = (id, product) => {
    const products = procedures.find((item) => item.id === id).products.slice();
    const newProducts = products.filter((item) => item !== product);

    updateProducts(id, newProducts);
  };

  const handleAddDocument = (id, document) => {
    const procedureDocuments = procedures.find((item) => item.id === id).documents;
    const newDocuments = procedureDocuments ? procedureDocuments.slice() : [];

    newDocuments.push(document);
    updateDocuments(id, newDocuments);
  };

  const handleDeleteDocument = (id, documentId) => {
    const procedureDocuments = procedures.find((item) => item.id === id).documents;
    const newDocuments = procedureDocuments ? procedureDocuments.slice() : [];

    updateDocuments(
      id,
      newDocuments.filter((doc) => doc.id !== documentId)
    );
  };

  const onUploadComplete = async (document) => {
    await addDocument(document);
  };

  const onUpload = (files) => {
    Papa.parse(files[0], {
      header: false,
      skipEmptyLines: true,
      complete: async (results) => {
        if (results && results.data) {
          await uploadProducts(results.data);
        }
      },
    });
  };

  const onUploadProducts = (files, procedureId) => {
    Papa.parse(files[0], {
      header: false,
      skipEmptyLines: true,
      complete: async (results) => {
        if (results && results.data) {
          const foundProducts = [];
          const notFoundProducts = [];
          results?.data?.forEach((product) => {
            const sku = product[0] ? product[0].trim() : '';
            const description = product[1] ? product[1].trim() : '';
            const _product = products?.find((p) => p.sku === sku);
            if (_product) {
              foundProducts?.push(_product);
            } else {
              notFoundProducts?.push(`${sku} - ${description}`);
            }
          });

          if (notFoundProducts?.length) {
            setMissingProducts(notFoundProducts);
          } else {
            const procedure = procedures?.find((procedure) => procedure.id === procedureId);
            const products = procedure?.products.slice();

            foundProducts?.forEach((product) => {
              if (!products?.find((p) => p.id === product.id)) {
                products.push(product);
              }
            });
            updateProducts(procedureId, products);
            setSuccessAlert(true);
          }
        }
      },
    });
  };

  const uploadProducts = async (list) => {
    try {
      setLoading(true);
      for (const product of list) {
        const sku = product[0] ? product[0].trim() : '';
        const description = product[1] ? product[1].trim() : '';

        if (sku && description) {
          await addProduct({
            sku,
            description,
            country,
          });
        }

        await fetchProducts();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationChange = (e) => {
    const value = e.target.value;
    if (!!value) {
      updateNotificationsSettings({ active: true });
    } else {
      toggleConfirmModal(true);
    }
  };

  const renderContent = (data) => {
    switch (activeTab) {
      case 0:
        return <UserList users={data} onDelete={(uid) => setRole(uid, null, null, null)} />;
      case 1:
        return (
          <ProcedureList
            procedures={data}
            country={country}
            onDelete={deleteProcedure}
            onToggle={toggleProcedure}
            onAddProduct={handleAddProduct}
            onDeleteProduct={handleDeleteProduct}
            onAddDocument={handleAddDocument}
            onDeleteDocument={handleDeleteDocument}
            addProduct
            allDocuments={documents}
            onAddFlow={handleAddFlow}
            onDeleteFlow={handleDeleteFlow}
            updateLeadDays={updateLeadDays}
            onUploadProducts={onUploadProducts}
          />
        );
      case 2:
        if (!addMode) {
          return <DocumentsList documents={documents} onDelete={deleteDocument} />;
        }
        return null;
      case 3:
        return <>
          <div className="modal-item-block m-t-md m-b-md">
            <div className="block-header">
              Upload Products
            </div>
            <Dropzone
              multiple={false}
              files={file}
              onUpload={onUpload}
              type="csv"
              text="Drag and drop .csv file here or click to upload"
              hideFilesList
              loading={loading}
            />
            <div className='m-t-sm'>
              <span className='font-bold'>Format:</span>
              <span className='m-l-sm'>Sku, Description</span>
            </div>
          </div>
          <div className='m-t-lg'>
            <ProductsList products={products} country={country} onDelete={deleteProduct} />
          </div>
        </>;
      default:
        return <UserList users={data} onDelete={(uid) => setRole(uid, null, null, null)} />;
    }
  };

  const renderInput = () => {
    switch (activeTab) {
      case 0:
        return (
          <NewUser
            newUser={newUser}
            userList={userList}
            onChange={setNewUser}
            onCancel={() => setAddMode(false)}
            onSubmit={addNewUser}
          />
        );
      case 1:
        return (
          <div className="d-flex new-item">
            <div className="column">
              <div className="modal-item-circle light" />
            </div>
            <TextField
              id="new-procedure"
              type="text"
              fullWidth
              value={newProcedure}
              onChange={(e) => setNewProcedure(e.target.value)}
              onKeyDown={handleSubmit}
            />
            <div className="p-l-md primary pointer" onClick={handleAddProcedure}>
              Add
            </div>
            <div className="p-l-md secondary pointer" onClick={() => setAddMode(false)}>
              Cancel
            </div>
          </div>
        );
      case 2:
        return <DocumentsUpload onUploadComplete={onUploadComplete} onDelete={deleteDocument} />;
      case 3:
        return <ProductsForm country={country} onCancel={setAddMode} />;
      default:
        return (
          <NewUser
            newUser={newUser}
            userList={userList}
            onChange={setNewUser}
            onCancel={() => setAddMode(false)}
            onSubmit={addNewUser}
          />
        );
    }
  };

  const filteredAdmins = countryAdmins.filter((item) => item.administrationCountry === country);
  const filteredData = activeTab === 0 ? filteredAdmins : procedures;

  const filterUserList = (user) =>
    !~filteredAdmins.map((admin) => admin.email).indexOf(user.email) && user.uid !== uid;
  const userList = users.filter(filterUserList);

  return (
    <div className="admin-modal-container">
      <div className="admin-modal-content">
        {!settingsOpen ? (
          <>
            <div className={`admin-modal-item header ${!filteredData.length && 'empty'}`}>
              <div className="column">
                <div className="modal-item-circle light" />
              </div>
              <div className="modal-item-content">
                <div className="item-content-header">
                  Country
                  {!addCountry ? (
                    <div className="primary pointer" onClick={() => setAddCountry(true)}>
                      Add Country
                    </div>
                  ) : countryLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <div className="d-flex">
                      <div className="primary pointer" onClick={handleSaveClick}>
                        Save
                      </div>
                      <div className="secondary pointer m-l-md" onClick={() => setAddCountry(false)}>
                        Cancel
                      </div>
                    </div>
                  )}
                </div>
                {!addCountry ? (
                  <Select
                    id="country-select"
                    className="country-select"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {selectedCountries.sort().map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <MultiSelect
                    disabled={setCountryLoading}
                    value={selectedCountries}
                    list={countries.map((item) => item.name)}
                    onChange={setSelectedCountries}
                    onDelete={handleCountryDelete}
                    className="country-select"
                  />
                )}
                <div className="modal-tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.id}
                      className={`modal-tab ${activeTab === tab.id && 'active'}`}
                      onClick={() => handleTabChange(tab.id)}
                    >
                      {tab.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {renderContent(filteredData)}
            {addMode && renderInput()}</>
        ) : <>
          <div className="admin-modal-item header">
            <div className="column">
              <div className="modal-item-circle light" />
            </div>
            <div className="modal-item-content m-b-lg">
              <div className="item-content-header">
                Notifications
              </div>
              <div className="m-b-lg">
                <Select
                  id="notifications-select"
                  className="country-select"
                  value={!!settings?.notifications?.active ? 1 : 0}
                  onChange={handleNotificationChange}
                >
                  <MenuItem value={1}>
                    Enabled
                  </MenuItem>
                  <MenuItem value={0}>
                    Disabled
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </>}
      </div>

      <div className="admin-modal-actions">
        {settingsOpen ? (
          <div className="secondary pointer" onClick={() => setSettingsOpen(false)}>
            Back
          </div>
        ) : (
          <div className="secondary pointer" onClick={onClose}>
            Close
          </div>
        )}
        {!addMode && activeTab !== 4 && (
          <Button
            text={`Add ${tabs[activeTab].buttonLabel}`}
            color="primary"
            onClick={() => setAddMode(true)}
          />
        )}
        {addMode && activeTab === 2 && (
          <Button text="Save" color="primary" onClick={() => setAddMode(false)} />
        )}
      </div>

      <Alert
        variant="error"
        message={`Products were not found during upload: ${missingProducts?.join(', ')}`}
        open={!!missingProducts?.length}
        onClose={() => setMissingProducts([])}
      />

      <Alert
        variant="success"
        message="Products successfully added"
        open={successAlert}
        onClose={() => setSuccessAlert(false)}
      />

      <ConfirmationModal
        open={confirmModalOpen}
        onClose={() => toggleConfirmModal(false)}
        title='Disable All Notifications?'
        text='Are you sure you want to disable platform notifications for all users?'
        submitText='Proceed'
        onSubmit={() => {
          updateNotificationsSettings({ active: false });
          toggleConfirmModal(false);
        }}
      />
    </div>
  );
};

GlobalAdminContainer.propTypes = {
  email: PropTypes.string,
  users: PropTypes.array,
  countryList: PropTypes.array,
  onClose: PropTypes.func,
  updateCountries: PropTypes.func,
  countryAdmins: PropTypes.array,
  procedures: PropTypes.array,
  toggleProcedure: PropTypes.func,
  addProcedure: PropTypes.func,
  updateProducts: PropTypes.func,
  deleteProcedure: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    uid: state.user.currentUser.uid,
    users: state.users.list,
    countryAdmins: state.users.list.filter((item) => item.role === userRoles.COUNTRY_ADMIN.name),
    countryList: state.countries.list,
    procedures: state.procedures.list,
    documents: state.documents.list,
    products: state.products.list,
    settings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCountries: (countries) => dispatch(updateCountryList(countries)),
    setRole: (uid, role, country, distributor) =>
      dispatch(setRole(uid, role, country, distributor)),
    toggleProcedure: (id, active) => dispatch(toggleProcedure(id, active)),
    updateProducts: (id, products) => dispatch(updateProducts(id, products)),
    updateFlow: (id, flow) => dispatch(updateFlow(id, flow)),
    updateLeadDays: (id, days) => dispatch(updateLeadDays(id, days)),
    addProcedure: (name, country) => dispatch(addProcedure(name, country)),
    deleteProcedure: (id) => dispatch(deleteProcedure(id)),
    addDocument: (document) => dispatch(addDocument(document)),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
    updateDocuments: (id, documents) => dispatch(updateDocuments(id, documents)),
    deleteProduct: (id) => dispatch(deleteProduct(id)),
    addProduct: (product) => dispatch(addProduct(product)),
    updateNotificationsSettings: (data) => dispatch(updateNotificationsSettings(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAdminContainer);
