import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';

import DatePickerModal from './DatePickerModal';

import './table.scss';

const StyledTableCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: '#b1b1b1',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingTop: '6px',
    paddingBottom: '6px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
  },
}))(TableCell);

const HeaderCell = ({ title, fieldName, filterOptions, selectedValues, onFilterChange, isDate, accessField }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, toggleModal] = useState(false);

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option) => {
    let values = [];

    if (selectedValues.includes(option)) {
      values = selectedValues.filter((value) => value !== option);
    } else {
      values = [...selectedValues, option];
    }

    onFilterChange(fieldName, values);
  };

  const onDateChange = (fromDate, toDate) => {
    onFilterChange(fieldName, { from: fromDate, to: toDate });
    toggleModal(false);
  };

  return (
    <StyledTableCell>
      <div className="table-header-cell" style={{ justifyContent: accessField ? 'center' : '' }}>
        {title}
        {filterOptions && !!filterOptions.length && (
          <div className="filter-button">
            <IconButton
              aria-controls="filter"
              aria-haspopup="true"
              size="small"
              color={selectedValues.length !== 0 ? 'primary' : 'secondary'}
              className="filter-button"
              onClick={(e) => handleClickOpenMenu(e)}
            >
              <FilterIcon />
            </IconButton>
            <Menu
              id="filter"
              anchorEl={anchorEl}
              keepMounted
              elevation={1}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              {orderBy(filterOptions).map((option) => (
                <MenuItem
                  key={option}
                  className="filter-menu-item"
                  value={option}
                  onClick={() => handleSelect(option)}
                >
                  <Checkbox
                    checked={selectedValues && selectedValues.indexOf(option) > -1}
                    color="primary"
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}

        {isDate && (
          <>
            <div className="filter-button">
              <IconButton
                aria-controls="filter"
                aria-haspopup="true"
                size="small"
                color={selectedValues.length !== 0 ? 'primary' : 'secondary'}
                className="filter-button"
                onClick={() => toggleModal(true)}
              >
                <FilterIcon />
              </IconButton>
            </div>
            <DatePickerModal open={isOpen} onClose={() => toggleModal(false)} onSubmit={onDateChange} />
          </>
        )}
      </div>
    </StyledTableCell>
  );
};

HeaderCell.propTypes = {
  title: PropTypes.string,
  fieldName: PropTypes.string,
  filterOptions: PropTypes.array,
  selectedValues: PropTypes.array,
  onFilterChange: PropTypes.func,
};

export default HeaderCell;
