import userRoles from './userRoles';

import moment from 'moment';

const { SURGEON, ENGINEER, SALES_REP, LOGISTICS, MANUFACTURER, PRACTICE_MANAGER, REGISTRAR } = userRoles;

const casesEmails = {
  0: {
    success: {
      subject: (parameters) => 'New Case Created',
      roles: [SURGEON, REGISTRAR, SALES_REP, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that a new case ${parameters.caseId} has been created and you have been assigned to it.`,
      additionalText: (parameters) => 'You will be notified when your direct input is required.',
    },
    updateDate: {
      subject: (parameters) => 'Surgery Date Changed',
      roles: [REGISTRAR, ENGINEER, SALES_REP, LOGISTICS, MANUFACTURER, PRACTICE_MANAGER],
      message: (
        parameters
      ) => `This is a notification to alert you that the surgery date has changed for the following case:`,
      additionalText: (parameters) => `The surgery date has been updated from ${parameters.oldDate} to ${parameters.newDate}. Please login to review any further case details.`,
    },
    updateHospital: {
      subject: (parameters) => 'Surgery Hospital Changed',
      roles: [SURGEON, REGISTRAR, ENGINEER, SALES_REP],
      message: (parameters) =>
        `This is a notification to alert you that the surgery hospital has changed for the following case:`,
      additionalText: (parameters) => `The surgery hospital has been moved from ${parameters.oldHospital} to ${parameters.newHospital}. Please login to review any further case details.`,
    },
  },
  1: {
    success: {
      subject: 'New Scans Uploaded for Review',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that new scans have been loaded for your review to the following case:`,
      additionalText: (parameters) => 'Please login to review scans and provide feedback.',
      parameters: ['caseId', 'lastName', 'surgeon']
    },
  },
  2: {
    reject: {
      subject: (parameters) => 'Scan Issues Reported',
      roles: [SURGEON, REGISTRAR, ENGINEER, PRACTICE_MANAGER],
      message: (parameters) =>
        `This is a notification to alert you that scans loaded have been rejected by the engineer for the following case:`,
      additionalText: (parameters) =>
        'Please login to review issues and upload new scans where required.',
      parameters: ['caseId', 'lastName', 'surgeon']
    },
  },
  3: {
    success: {
      subject: (parameters) => 'Preoperative Alignment Data Uploaded for Review',
      roles: [SURGEON, REGISTRAR, SALES_REP],
      message: (parameters) =>
        `This is a notification to alert you that the engineer has uploaded alignment data for your review to the following case:`,
      additionalText: (parameters) =>
        'Please login to review data and make any required amendments.',
      parameters: ['caseId', 'lastName', 'date', 'hospital', 'procedure']
    },
  },
  4: {
    success: {
      subject: (parameters) => 'Surgeon Measurements for Review',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that the surgeon has submitted measurement data for your review to the following case:`,
      additionalText: (parameters) =>
        'Please login to review measurements and make any required amendments.',
      parameters: ['caseId', 'lastName', 'surgeon']
    },
  },
  5: {
    success: {
      subject: (parameters) => 'Pre-Op Plan Uploaded for Review',
      roles: [SURGEON, REGISTRAR],
      message: (parameters) =>
        `This is a notification to alert you that the engineer has uploaded the Pre-Op Plan for your review to the following case:`,
      additionalText: (parameters) =>
        'Please login to review the document and provide any required feedback.',
      parameters: ['caseId', 'lastName', 'date', 'hospital', 'procedure']
    },
    reject: {
      subject: (parameters) => 'Measurement Issues Reported!',
      roles: [SURGEON, REGISTRAR],
      message: (parameters) =>
        `This is a notification to alert you that the engineer has reported issues with the submitted measurement data for the following case:`,
      additionalText: (parameters) =>
        'Please login to review engineer concerns and make any required amendments.',
      parameters: ['caseId']
    },
    manufacturer: {
      subject: (parameters) => 'New Manufacturing Files Uploaded for Review',
      roles: [MANUFACTURER],
      message: (parameters) => `This is a notification to alert you that the engineer has uploaded manufacturing files for your review to the following case:`,
      additionalText: (parameters) => 'Please login to review the files and provide any required feedback.',
      parameters: ['caseId']
    }
  },
  6: {
    success: {
      subject: (parameters) =>
        parameters.recipient.role === 'ENGINEER'
          ? 'Surgeon approved Pre-Op plan'
          : `New Manufacturing Files Uploaded for Review`,
      roles: [MANUFACTURER],
      message: (parameters) =>
        parameters.recipient.role === 'ENGINEER'
          ? `This is a notification to alert you that the surgeon has approved the Pre-Op plan for the following case:`
          : `This is a notification to alert you that the engineer has uploaded manufacturing files for your review to the following case:`,
      additionalText: (parameters) =>
        parameters.recipient.role === 'ENGINEER'
          ? 'The manufacturer has been forwarded the uploaded block files and you will be notified if there are any issues raised.'
          : 'Please login to review the files and provide any required feedback.',
      parameters: ['caseId', 'lastName', 'surgeon']
    },
    reject: {
      subject: (parameters) => 'Pre-Op Plan Issues Reported',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that the surgeon has reported issues with the submitted Pre-Op plan for the following case:`,
      additionalText: (parameters) =>
        'Please login to review the files and provide any required feedback.',
      parameters: ['caseId', 'lastName', 'surgeon']
    },
  },
  7: {
    success: {
      subject: (parameters) => 'Surgeon approved Pre-Op plan',
      roles: [ENGINEER, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that the manufacturer has approved the block manufacturing files uploaded to the following case:`,
      additionalText: (parameters) =>
        `The manufacturer has set a targeted dispatch date of ${moment(
          parameters.dispatchInfo ? parameters.dispatchInfo.targetedDispatchDate : new Date()
        ).format('DD/MM/YYYY')}. 
        You will be notified once the block manufacturing has been completed.`,
      parameters: ['caseId', 'lastName']
    },
    reject: {
      subject: (parameters) => 'Files Rejected for Manufacture',
      roles: [ENGINEER, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that the manufacturer has reported issues with the submitted block files for the following case:`,
      additionalText: (parameters) =>
        'Please login to review the files and provide any required feedback.',
      parameters: ['caseId', 'lastName', 'surgeon', 'date', 'hospital']
    },
  },
  8: {
    success: {
      subject: (parameters) => 'Block Manufacturing Completed',
      roles: [ENGINEER, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that block manufacturing has been completed for the following case:`,
      additionalText: (parameters) => `Dispatch will need to be within ${moment(parameters.date)
        .subtract(7, 'days')
        .diff(
          moment(
            parameters.dispatchInfo ? parameters.dispatchInfo.targetedDispatchDate : new Date()
          ),
          'days'
        )} days to remain on schedule. 
        You will be notified once the block has been shipped.`,
      parameters: ['caseId', 'lastName', 'surgeon', 'date', 'hospital']
    },
  },
  9: {
    success: {
      subject: (parameters) =>
        parameters.recipient.role === 'SALES_REP'
          ? 'Case Ready For Surgery'
          : 'Block Has Been Shipped',
      roles: [LOGISTICS, ENGINEER, SALES_REP],
      message: (parameters) =>
        parameters.recipient.role === 'SALES_REP'
          ? `This is a notification to alert you that the case is ready for surgery:`
          : `This is a notification to alert you that the manufacturer has dispatched the block for the following case:`,
      additionalText: (parameters) => `
        The tracking information for this shipment is - ${parameters.dispatchInfo.trackingNumber} - ${parameters.dispatchInfo.courier}. 
        Please login to check tracking information if required.
      `,
      parameters: ['caseId']
    },
  },
  10: {
    // success: {
    //   subject: (parameters) => "Usage Confirmation Required",
    //   roles: [SALES_REP],
    //   message: (parameters) => `This is a notification to alert you that the usage report is required for the following case – ${parameters.caseId}.`,
    //   additionalText: (parameters) => "Please login to confirm case usage and make product changes if required."
    // }
  },
  11: {
    // success: {
    //   subject: (parameters) => 'Post Op Report Required',
    //   roles: [SALES_REP],
    //   message: (parameters) =>
    //     `This is a notification to alert you that the Post Op report is required for the following case – ${parameters.caseId}.`,
    //   additionalText: (parameters) =>
    //     'Please login to add any post-op information and complete the case.',
    // },
  },
};

const casesACLEmails = {
  0: {
    success: {
      subject: (parameters) => 'New Case Created',
      roles: [SURGEON, REGISTRAR, SALES_REP, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that a new case ${parameters.caseId} has been created and you have been assigned to it.`,
      additionalText: (parameters) => 'You will be notified when your direct input is required.',
    },
    updateDate: {
      subject: (parameters) => 'Surgery Date Changed',
      roles: [SURGEON, REGISTRAR, ENGINEER, SALES_REP, LOGISTICS, MANUFACTURER, PRACTICE_MANAGER],
      message: (
        parameters,
      ) => `This is a notification to alert you that the surgery date has changed for the following case:`,
      additionalText: (parameters) => `The surgery date has been updated from ${parameters.oldDate} to ${parameters.newDate}. Please login to review any further case details.`,
    },
    updateHospital: {
      subject: (parameters) => 'Surgery Hospital Changed',
      roles: [SURGEON, REGISTRAR, ENGINEER, SALES_REP],
      message: (parameters) =>
        `This is a notification to alert you that the surgery hospital has changed for the following case:`,
      additionalText: (parameters) => `The surgery hospital has been moved from ${parameters.oldHospital} to ${parameters.newHospital}. Please login to review any further case details.`,
    },
  },
  1: {
    success: {
      subject: 'New Scans Uploaded for Review',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that new scans have been loaded for your review to the following case:`,
      additionalText: (parameters) => 'Please login to review scans and provide feedback.',
      parameters: ['caseId', 'lastName', 'surgeon'],
    },
  },
  2: {
    reject: {
      subject: (parameters) => 'Scan Issues Reported',
      roles: [SURGEON, REGISTRAR, ENGINEER, PRACTICE_MANAGER],
      message: (parameters) =>
        `This is a notification to alert you that patient scans loaded have been rejected by the engineer for the following case:`,
      additionalText: (parameters) =>
        'Please login to review issues and upload new scans where required.',
      parameters: ['caseId', 'lastName', 'surgeon'],
    },
  },
  3: {
    success: {
      subject: (parameters) => 'Pre-Op Plan Uploaded for Review',
      roles: [SURGEON, REGISTRAR],
      message: (parameters) =>
        `This is a notification to alert you that the engineer has uploaded the pre-op plan for your review to the following case:`,
      additionalText: (parameters) =>
        'Please login to review the document and provide any required feedback.',
      parameters: ['caseId', 'lastName', 'date', 'hospital', 'procedure'],
    },
  },
  4: {
    success: {
      subject: (parameters) => 'Surgeon Approved Pre-Op Plan',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that the surgeon has review and approved the pre-op plan for the following case:`,
      additionalText: (parameters) =>
        'Please login to review any comments and progress the case.',
      parameters: ['caseId', 'lastName', 'surgeon'],
    },
    reject: {
      subject: (parameters) => 'Surgeon Rejected Pre-Op Plan',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that the surgeon has reviewed and rejected the pre-op plan for the following case:`,
      additionalText: (parameters) =>
        'Please login to review the comments and progress the case.',
      parameters: ['caseId', 'lastName', 'surgeon'],
    },
  },
  5: {
    success: {
      subject: (parameters) => 'Post Op Report Required',
      roles: [SALES_REP],
      message: (parameters) =>
        `This is a notification to alert you that the Post Op report is required for the following case – ${parameters.caseId}.`,
      additionalText: (parameters) =>
        'Please login to add any post-op information and complete the case.',
    },
  },
};

const casesCMFEmails = {
  0: {
    success: {
      subject: (parameters) => 'New Case Created',
      roles: [SURGEON, REGISTRAR, SALES_REP, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that a new case ${parameters.caseId} has been created and you have been assigned to it.`,
      additionalText: (parameters) => 'You will be notified when your direct input is required.',
    },
    updateDate: {
      subject: (parameters) => 'Surgery Date Changed',
      roles: [SURGEON, REGISTRAR, ENGINEER, SALES_REP, LOGISTICS, MANUFACTURER, PRACTICE_MANAGER],
      message: (
        parameters,
      ) => `This is a notification to alert you that the surgery date has changed for the following case:`,
      additionalText: (parameters) => `The surgery date has been updated from ${parameters.oldDate} to ${parameters.newDate}. Please login to review any further case details.`,
    },
    updateHospital: {
      subject: (parameters) => 'Surgery Hospital Changed',
      roles: [SURGEON, REGISTRAR, ENGINEER, SALES_REP],
      message: (parameters) =>
        `This is a notification to alert you that the surgery hospital has changed for the following case:`,
      additionalText: (parameters) => `The surgery hospital has been moved from ${parameters.oldHospital} to ${parameters.newHospital}. Please login to review any further case details.`,
    },
  },
  1: {
    success: {
      subject: 'New Scans Uploaded for Review',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that new scans have been loaded for your review to the following case:`,
      additionalText: (parameters) => 'Please login to review scans and provide feedback.',
      parameters: ['caseId', 'lastName', 'surgeon'],
    },
  },
  2: {
    reject: {
      subject: (parameters) => 'Scan Issues Reported',
      roles: [SURGEON, REGISTRAR, ENGINEER, PRACTICE_MANAGER],
      message: (parameters) =>
        `This is a notification to alert you that patient scans loaded have been rejected by the engineer for the following case:`,
      additionalText: (parameters) =>
        'Please login to review issues and upload new scans where required.',
      parameters: ['caseId', 'lastName', 'surgeon'],
    },
  },
  3: {
    success: {
      subject: (parameters) => 'Design Report Uploaded for Review',
      roles: [SURGEON, REGISTRAR],
      message: (parameters) =>
        `This is a notification to alert you that the engineer has uploaded the design report for your review to the following case:`,
      additionalText: (parameters) =>
        'Please login to review the document and provide any required feedback.',
      parameters: ['caseId', 'lastName', 'date', 'hospital', 'procedure'],
    },
  },
  4: {
    success: {
      subject: (parameters) => 'Surgeon Approved Design Report',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that the surgeon has review and approved the design report for the following case:`,
      additionalText: (parameters) =>
        'Please login to review any comments and progress the case.',
      parameters: ['caseId', 'lastName', 'surgeon'],
    },
    reject: {
      subject: (parameters) => 'Surgeon Rejected Design Report',
      roles: [ENGINEER],
      message: (parameters) =>
        `This is a notification to alert you that the surgeon has reviewed and rejected the design report for the following case:`,
      additionalText: (parameters) =>
        'Please login to review the comments and progress the case.',
      parameters: ['caseId', 'lastName', 'surgeon'],
    },
  },
  5: {
    success: {
      subject: (parameters) => 'Post Op Report Required',
      roles: [SALES_REP],
      message: (parameters) =>
        `This is a notification to alert you that the Post Op report is required for the following case – ${parameters.caseId}.`,
      additionalText: (parameters) =>
        'Please login to add any post-op information and complete the case.',
    },
  },
};

const casesCMFExtendedEmails = {
  0: {
    success: {
      subject: (parameters) => 'New Case Created',
      roles: [ENGINEER, SALES_REP],
      message: (parameters) => 'A new case has been created:',
      additionalText: (parameters) => '',
    },
    updateDate: {
      subject: (parameters) => 'Surgery Date Changed',
      roles: [ENGINEER, SALES_REP, PRACTICE_MANAGER],
      message: (
        parameters,
      ) => `The following case has a revised surgery date:`,
      additionalText: (parameters) => `The surgery date has been updated from ${parameters.oldDate} to ${parameters.newDate}.`,
    },
    updateHospital: {
      subject: (parameters) => 'Surgery Hospital Changed',
      roles: [PRACTICE_MANAGER, ENGINEER, SALES_REP],
      message: (parameters) =>
        `This is a notification to alert you that the surgery hospital has changed for the following case:`,
      additionalText: (parameters) => `The surgery hospital has been moved from ${parameters.oldHospital} to ${parameters.newHospital}.`,
    },
  },
  1: {
    success: {
      subject: 'Patient Data Uploaded',
      roles: [ENGINEER],
      message: (parameters) =>
        `Patient data has been uploaded to the following case:`,
      additionalText: (parameters) => '',
      parameters: ['caseId', 'lastName', 'procedure'],
    },
  },
  2: {
    reject: {
      subject: (parameters) => 'Patient Data Rejected',
      roles: [SURGEON, REGISTRAR, ENGINEER, PRACTICE_MANAGER],
      message: (parameters) =>
        `Patient data has been rejected for the following case:`,
      additionalText: (parameters) =>
        'Please upload new data in the PS App.',
      parameters: ['note', 'caseId', 'lastName', 'surgeon'],
    },
  },
  3: {
    planningConfirmed: {
      subject: (parameters) => 'Planning Meeting Confirmed',
      roles: [SURGEON, SALES_REP, PRACTICE_MANAGER],
      message: (parameters) =>
        `The planning meeting for patient ${parameters.patientLastName}, (${parameters.caseId}) has been confirmed for:`,
      additionalText: (parameters) => '',
      parameters: ['planningDate', 'planningLink'],
    },
    success: {
      subject: (parameters) => 'Planning Report Uploaded',
      roles: [SURGEON, SALES_REP, PRACTICE_MANAGER],
      message: (parameters) =>
        `The surgical plan for patient ${parameters.patientLastName} is ready for your review. Please access the pdf report on the PS App via the link below and provide feedback at your earliest convenience.`,
      additionalText: (parameters) => '',
      parameters: [],
    },
  },
  4: {
    success: {
      subject: (parameters) => 'Planning Report Approved',
      roles: [ENGINEER, SALES_REP],
      message: (parameters) =>
        `The surgical plan for patient ${parameters.patientLastName}, (${parameters.caseId}) has been approved by the surgeon`,
      additionalText: (parameters) => '',
      parameters: [],
    },
    reject: {
      subject: (parameters) => 'Planning Report Rejected',
      roles: [ENGINEER, SALES_REP],
      message: (parameters) =>
        `The surgical plan for patient ${parameters.patientLastName}, (${parameters.caseId}) has been rejected by the surgeon with the following comments:`,
      additionalText: (parameters) => '',
      parameters: ['note'],
    },
  },
  5: {
    success: {
      subject: (parameters) => 'Design Report Uploaded',
      roles: [SURGEON, SALES_REP, PRACTICE_MANAGER],
      message: (parameters) =>
        `Product designs for patient ${parameters.patientLastName} are ready for your review. Please access the pdf report and provide feedback on the PS App at your earliest convenience.`,
      additionalText: (parameters) => '',
      parameters: [],
    },
  },
  6: {
    success: {
      subject: (parameters) => 'Design Report Approved',
      roles: [ENGINEER, SALES_REP],
      message: (parameters) =>
        `The designs for patient ${parameters.patientLastName}, (${parameters.caseId}) have been approved by the surgeon.`,
      additionalText: (parameters) =>
        `Personalised Surgery will manufacture and ship parts in time for surgery on ${parameters.date ? moment(parameters.date).format('DD/MM/YYYY') : ''}.`,
      parameters: [],
    },
    reject: {
      subject: (parameters) => 'Design Report Rejected',
      roles: [ENGINEER, SALES_REP],
      message: (parameters) =>
        `The designs for patient ${parameters.patientLastName}, (${parameters.caseId}) have been rejected by the surgeon with the following comments:`,
      additionalText: (parameters) => '',
      parameters: ['note'],
    },
  },
  7: {
    success: {
      subject: (parameters) => 'Surgeon approved Pre-Op plan',
      roles: [ENGINEER, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that the manufacturer has approved the block manufacturing files uploaded to the following case:`,
      additionalText: (parameters) =>
        `The manufacturer has set a targeted dispatch date of ${moment(
          parameters.dispatchInfo ? parameters.dispatchInfo.targetedDispatchDate : new Date()
        ).format('DD/MM/YYYY')}. 
        You will be notified once the block manufacturing has been completed.`,
      parameters: ['caseId', 'lastName']
    },
    reject: {
      subject: (parameters) => 'Files Rejected for Manufacture',
      roles: [ENGINEER, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that the manufacturer has reported issues with the submitted block files for the following case:`,
      additionalText: (parameters) =>
        'Please login to review the files and provide any required feedback.',
      parameters: ['caseId', 'lastName', 'surgeon', 'date', 'hospital']
    },
  },
  8: {
    success: {
      subject: (parameters) => 'Manufacturing Completed',
      roles: [ENGINEER, LOGISTICS],
      message: (parameters) =>
        `This is a notification to alert you that manufacturing has been completed for the following case:`,
      additionalText: (parameters) => '',
      parameters: ['caseId', 'lastName', 'surgeon', 'date', 'hospital'],
    },
  },
  9: {
    success: {
      subject: (parameters) =>
        parameters.recipient.role === 'SALES_REP'
          ? 'Case Ready For Surgery'
          : 'Manufactured Parts Have Been Shipped',
      roles: [LOGISTICS, ENGINEER, SALES_REP],
      message: (parameters) =>
        parameters.recipient.role === 'SALES_REP'
          ? `This is a notification to alert you that the case is ready for surgery:`
          : `This is a notification to alert you that the manufacturer has dispatched the parts for the following case:`,
      additionalText: (parameters) => `
        The tracking information for this shipment is - ${parameters.dispatchInfo?.trackingNumber} - ${parameters.dispatchInfo?.courier}. 
        Please login to check tracking information if required.
      `,
      parameters: ['caseId'],
    },
  },
  10: {
    success: {
      subject: (parameters) => 'Post Op Report Required',
      roles: [SALES_REP],
      message: (parameters) =>
        `This is a notification to alert you that the Post Op report is required for the following case – ${parameters.caseId}.`,
      additionalText: (parameters) =>
        'Please login to add any post-op information and complete the case.',
    },
  },
};

const roleAssignmentEmail = {
  subject: 'PS App Access Granted',
  message:
    "Good news! We've assigned your role and you can now login and access all associated cases and relevant information.",
};

export { casesEmails, roleAssignmentEmail, casesACLEmails, casesCMFEmails, casesCMFExtendedEmails };
