import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Step0StaticAdmin from './Step0StaticAdmin';
import RestrictComponent from '../../../../shared/RestrictComponent';
import Dropzone from '../../../../shared/dropzone/Dropzone';
import Button from '../../../../shared/Button';

import { simpleUpdateCase, updateCaseEngineer } from '../../../../../actions/casesActions';

import { sortAlphabetically } from '../../../../../util/utils';

import { userRoles } from '../../../../../constants';
import { fileTypes } from '../../../../../constants/cases';

const {
  GLOBAL_ADMIN,
  COUNTRY_ADMIN,
  DISTRIBUTOR_ADMIN,
  SURGEON,
  ENGINEER,
  SALES_REP,
  LOGISTICS,
  PRACTICE_MANAGER,
  MANUFACTURER,
  RADIOLOGY,
} = userRoles;

const Step0Static = ({
  activeCase,
  surgeons,
  distributors,
  userId,
  procedures,
  distributor,
  userRole,
  engineerLevel,
  updateCase,
}) => {
  const { surgeon, hospital, date, procedureSide, patientInfo, distributorId } = activeCase;
  const { firstName, lastName, dateOfBirth, gender } = patientInfo;

  const [procedure, setProcedure] = useState(
    procedures.find((item) => activeCase.procedure === item.id) || null,
  );

  const dispatch = useDispatch();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const onUpload = (files) => {
    setReports([...reports, ...files.filter((file) => !reports.map((sheet) => sheet.name).includes(file.name))]);
  };

  const onRemove = (name) => {
    setReports(reports.filter((file) => file.name !== name));
  };

  const caseSurgeon = useMemo(
    () => surgeons?.find((user) => user.uid === surgeon?.id || user.uid === surgeon?.userId),
    [surgeons, surgeon],
  );
  const caseDistributor = useMemo(
    () => distributors.find((distributor) => distributor.id === distributorId),
    [distributors, distributorId],
  );

  const handleUploadSubmit = async () => {
    const now = moment().format();
    const files = [...activeCase.files];
    const newFiles = [];

    reports.forEach((file) => {
      files?.unshift({
        id: `${activeCase.id}_${fileTypes.chargedSheet}_${file.name}`,
        name: file.name,
        type: fileTypes.chargedSheet,
        date: now,
        userId,
      });
      newFiles?.push(file);
    });

    setLoading(true);

    await dispatch(simpleUpdateCase({ ...activeCase, files }, newFiles));
    setLoading(false);
  };

  const handleSubmit = () => {
    const caseData = {
      procedure: procedure.id,
      procedureName: procedure.name,
      procedureFlow: procedure.flow || activeCase.procedureFlow || '',
    };

    setLoading(true);
    updateCase(
      activeCase.id,
      { ...activeCase, ...caseData },
      {
        hospital: activeCase.hospital,
        date: activeCase.date,
        surgeon: activeCase.surgeon,
        procedure: activeCase.procedure,
      },
    ).then(() => {
      setLoading(false);
    });
  };

  const isPermission = !activeCase.trainingMode
    ? [GLOBAL_ADMIN, COUNTRY_ADMIN]
    : [
      GLOBAL_ADMIN,
      COUNTRY_ADMIN,
      DISTRIBUTOR_ADMIN,
      SURGEON,
      ENGINEER,
      SALES_REP,
      LOGISTICS,
      PRACTICE_MANAGER,
      MANUFACTURER,
      RADIOLOGY,
    ];

  const filteredProcedures = useMemo(
    () =>
      procedures.filter((item) =>
        distributor?.assignedProcedures?.find((assign) => assign?.id === item?.id),
      ),
    [procedures, distributor],
  );

  useEffect(() => {
    setProcedure(filteredProcedures.find((item) => activeCase.procedure === item.id) || null);
  }, [activeCase.procedure, filteredProcedures]);

  return (
    <Fragment>
      {!activeCase.trainingMode && (
        <RestrictComponent excludeRoles={[GLOBAL_ADMIN, COUNTRY_ADMIN]}>
          <div className="case-static-container">
            <Grid container spacing={0}>
              {distributorId && (
                <Grid item xs={12} md={6}>
                  <div className="field-container">
                    <div className="field-title">Distributor</div>
                    <div className="field-value">
                      {caseDistributor && `${caseDistributor.name}`}
                    </div>
                  </div>
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Assigned surgeon</div>
                  <div className="field-value">
                    {caseSurgeon && `Dr ${caseSurgeon.firstName} ${caseSurgeon.lastName}`}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Hospital</div>
                  <div className="field-value">{hospital}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                {userRole === userRoles.ENGINEER.name && engineerLevel === 1 && activeCase.step < 4 ? (
                  <div className="field-container">
                    <div className="field-title">Procedure</div>
                    <div className="field-content">
                      <Select
                        value={procedure}
                        fullWidth
                        className="case-field-select"
                        onChange={(e) => setProcedure(e.target.value)}
                      >
                        {filteredProcedures.sort(sortAlphabetically('name')).map((item) => (
                          <MenuItem key={item.id} value={item}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : (
                  <div className="field-container">
                    <div className="field-title">Procedure</div>
                    <div className="field-value">{procedure?.name || ''}</div>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Targeted surgery date</div>
                  <div className="field-value">{date ? moment(date).format('DD/MM/YYYY') : 'TBC'}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Patient name</div>
                  <div className="field-value d-flex">{`${firstName} ${lastName}`}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Gender</div>
                  <div className="field-value">{gender.toUpperCase()}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Date of birth</div>
                  <div className="field-value">{moment(dateOfBirth).format('DD/MM/YYYY')}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Procedure side</div>
                  <div className="field-value">{procedureSide.toUpperCase()}</div>
                </div>
              </Grid>
            </Grid>

            {userRole === userRoles.ENGINEER.name && engineerLevel === 1 && activeCase.step < 4 && (
              <div className="case-modal-actions">
                <div />
                <Button
                  text="Save Changes"
                  loading={loading}
                  disabled={loading}
                  color="primary"
                  onClick={handleSubmit}
                />
              </div>
            )}

            <RestrictComponent roles={[ENGINEER, SALES_REP, PRACTICE_MANAGER]}>
              <div className="m-t-lg">
                <div className="modal-item-block m-b-lg">
                  <div className="block-header">Surgeon consultation report</div>
                  <Dropzone
                    files={reports}
                    onUpload={onUpload}
                    icon="scan"
                    onRemove={onRemove}
                  />
                </div>
                <div className="case-modal-actions">
                  <div />
                  <Button
                    text="Upload"
                    loading={loading}
                    disabled={loading}
                    color="primary"
                    onClick={handleUploadSubmit}
                  />
                </div>
              </div>
            </RestrictComponent>
          </div>
        </RestrictComponent>
      )}
      <RestrictComponent roles={isPermission}>
        <Step0StaticAdmin activeCase={activeCase} />
      </RestrictComponent>
    </Fragment>
  );
};

Step0Static.propTypes = {
  activeCase: PropTypes.object,
  surgeons: PropTypes.array,
  procedure: PropTypes.string,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((item) => item.id === activeCase.procedure);
  const procedureName = procedure ? procedure.name : 'No procedure';
  const distributors = state.distributors.list;

  const distributor = distributors.find(
    (distributor) => distributor.id === activeCase?.distributorId,
  );

  return {
    activeCase: state.cases.activeCase,
    surgeons: state.users.list.filter((user) => user.role === userRoles.SURGEON.name),
    procedure: procedureName,
    distributors: state.distributors.list,
    distributor,
    userId: state.user.currentUser.uid,
    userRole: state.user.currentUser.role,
    engineerLevel: state.user.currentUser.engineerLevel,
    procedures: state.procedures.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (id, data, previousData) => dispatch(updateCaseEngineer(id, data, previousData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step0Static);
