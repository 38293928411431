import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import PolicyModal from './PolicyModal';
import TermsModal from './TermsModal';

import { routes } from '../../constants';

import './app-footer.scss';

const AppFooter = ({ link }) => {
  const [policyIsOpen, setPolicyIsOpen] = useState(false);
  const [termsIsOpen, setTermsIsOpen] = useState(false);

  const year = new Date().getFullYear();

  return (
    <div className="app-footer">
      <div>
        <div className="d-flex">
          <div className="pointer" onClick={() => setPolicyIsOpen(true)}>
            Privacy Policy
          </div>
          <div className="divider" />
          <div className="pointer" onClick={() => setTermsIsOpen(true)}>
            Terms of Service
          </div>
        </div>

        {link === 'sign-up' ? (
          <LinkContainer to={routes.SIGN_UP}>
            <div className="primary pointer">Sign up</div>
          </LinkContainer>
        ) : (
          <LinkContainer to={routes.SIGN_IN}>
            <div className="primary pointer">Have account?</div>
          </LinkContainer>
        )}
      </div>
      <PolicyModal open={policyIsOpen} onClose={() => setPolicyIsOpen(false)} year={year} />
      <TermsModal open={termsIsOpen} onClose={() => setTermsIsOpen(false)} year={year} />
    </div>
  );
};

export default AppFooter;
