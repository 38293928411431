import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setActiveTab } from '../../../../../../actions/casesActions';
import { getFileIcon, getFileLabel } from '../../../../../../util/cases';

const OsteotomyStep7Static = ({ files, goToFiles, notes }) => {
  return (
    <div className="case-static-container">
      {files.map((item) => (
        <div key={`${item.name}-${item.type}-${item.date}`} className="case-file-list-item" onClick={goToFiles}>
          <div className="width-100">
            <div className="d-flex">
              <div className="d-flex align-center icon-file-container flex-1">
                <img src={getFileIcon(item.type)} alt="file-icon" style={{ height: '20px', fill: '#eee' }} />
                <div className="font-bold">{getFileLabel(item.type)}</div>
              </div>
              {item.status && (
                <div className={`status-indicator status-indicator-file ${item.status}`}>{item.status}</div>
              )}
            </div>
            <div className="d-flex w-100 space-between m-t-sm">
              <div className="list-item-title">{item.name}</div>
            </div>
          </div>
        </div>
      ))}
      {notes && (
        <div className="m-t-md p-t-sm border-top">
          <div className="secondary">Notes:</div>
          <div>{notes}</div>
        </div>
      )}
    </div>
  );
};

OsteotomyStep7Static.propTypes = {
  files: PropTypes.array,
  goToFiles: PropTypes.func,
  notes: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    files: state.cases.activeCase.files.filter((file) => file.type === 'block'),
    notes: state.cases.activeCase.notes && state.cases.activeCase.notes.step7,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep7Static);
