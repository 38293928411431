import { createSelector } from 'reselect';

// Hospitals
const hospitalsListSelector = (state) => state.hospitals;
export const getHospitalsList = createSelector(
  hospitalsListSelector,
  (hospitals) => hospitals?.list
);
export const getIsLoadingHospitalsList = createSelector(
  hospitalsListSelector,
  (hospitals) => hospitals?.loading
);

// Surgeons
const surgeonsListSelector = (state) => state.surgeons;
export const getSurgeonsList = createSelector(
  surgeonsListSelector,
  (surgeons) => surgeons?.list
);
export const getIsLoadingSurgeonsList = createSelector(
  surgeonsListSelector,
  (surgeons) => surgeons?.loading
);

// Documents
const documentsListSelector = (state) => state.documents;
export const getDocumentsList = createSelector(
  documentsListSelector,
  (documents) => documents?.list
);
export const getIsLoadingDocumentsList = createSelector(
  documentsListSelector,
  (documents) => documents?.loading
);

// Products
const productsListSelector = (state) => state.products;
export const getProductsList = createSelector(productsListSelector, (products) => products?.list);
export const getIsLoadingProductsList = createSelector(
  productsListSelector,
  (products) => products?.loading
);

// Countries
const countriesListSelector = (state) => state.countries;
export const getCountriesList = createSelector(
  countriesListSelector,
  (countries) => countries?.list
);
export const getIsLoadingCountriesList = createSelector(
  countriesListSelector,
  (countries) => countries?.loading
);

const proceduresListSelector = (state) => state.procedures;
export const getProceduresList = createSelector(proceduresListSelector, (procedures) => procedures?.list);
export const getIsLoadingProceduresList = createSelector(
  proceduresListSelector,
  (procedures) => procedures?.loading
);

// Cases
const casesListSelector = (state) => state.cases;
export const getCasesList = createSelector(
  casesListSelector,
  (cases) => [...cases?.list, ...cases?.completedCases].sort((a, b) => (a.date > b.date ? 1 : -1))
);
