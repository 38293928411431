import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AclStep1Admin from './AclStep1Admin';
import RestrictComponent from '../../../../../shared/RestrictComponent';

import { setActiveTab } from '../../../../../../actions/casesActions';

import { userRoles } from '../../../../../../constants';
import { getFileIcon, getFileLabel } from '../../../../../../util/cases';

const fileTypes = ['scan', 'mri'];

const AclStep1Static = ({ activeCase, goToFiles }) => {
  const { files } = activeCase;

  return (
    <div className="case-static-container">
      {files
        .filter((file) => fileTypes.includes(file.type))
        .map((item) => (
          <div key={`${item.name}-${item.type}-${item.date}`} className="case-file-list-item" onClick={goToFiles}>
            <div className="width-100">
              <div className="d-flex">
                <div className="d-flex align-center icon-file-container flex-1">
                  <img src={getFileIcon(item.type)} alt="file-icon" style={{ height: '20px', fill: '#eee' }} />
                  <div className="font-bold">{getFileLabel(item.type)}</div>
                </div>
                {item.status && (
                  <div className={`status-indicator status-indicator-file ${item.status}`}>{item.status}</div>
                )}
              </div>
              <div className="d-flex w-100 space-between m-t-sm">
                <div className="list-item-title">{item.name}</div>
              </div>
            </div>
          </div>
        ))}

      <RestrictComponent roles={[userRoles.GLOBAL_ADMIN, userRoles.COUNTRY_ADMIN]}>
        <AclStep1Admin activeCase={activeCase} />
      </RestrictComponent>
    </div>
  );
};

AclStep1Static.propTypes = {
  activeCase: PropTypes.object,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AclStep1Static);
