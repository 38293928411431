import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, TotpMultiFactorGenerator } from 'firebase/auth';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import Alert from '../../components/shared/Alert';
import CustomButton from '../../components/shared/Button';
import ForgotPasswordModal from '../../components/modals/ForgotPassword';

import { withFirebase } from '../../firebase';

import { setCurrentUser } from '../../actions/userActions';

import './login-page.scss';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showPassword: false,
      checked: false,
      loading: false,
      verificationLoading: false,
      error: false,
      isOpen: false,
      resetPasswordError: null,
      resetPasswordSuccess: false,
      disabledAccountError: false,
      mfaVerificationCode: '',
      totpCode: '',
      mfaRequired: false,
      totpRequired: false,
      multiFactorResolver: null,
      recaptchaVerified: false,
      mfaSession: null,
      mfaError: false,
    };
  }

  componentDidMount() {
    // Initialize reCAPTCHA verifier when the component mounts
    const { firebase } = this.props;

    try {
      const recaptchaVerifier = firebase.getRecaptchaVerifier('recaptcha-container');

      // Render the reCAPTCHA
      recaptchaVerifier.render().then(() => {
        this.setState({ recaptchaVerified: true, recaptchaVerifier });
      }).catch((error) => {
        console.error('Error rendering reCAPTCHA:', error);
        this.setState({ recaptchaVerified: false });
      });
    } catch (error) {
      console.error('Error initializing reCAPTCHA:', error);
      this.setState({ recaptchaVerified: false });
    }
  }

  handleSubmit = async (e) => {
    const { email, password, checked } = this.state;
    const { firebase, setUser, history } = this.props;

    e.preventDefault();

    if (!email.length || !password.length) {
      return;
    }

    this.setState({ loading: true, error: false });

    try {
      // Attempt to sign in with email and password
      const authResult = await firebase.doSignInWithEmailAndPassword(email, password, checked);

      // Successfully signed in without multi-factor authentication
      setUser(authResult);
      history.push('/dashboard');
    } catch (error) {
      // Handle MFA required error using getMultiFactorResolver directly from Firebase Auth
      if (error.code === 'auth/multi-factor-auth-required') {
        try {
          // Use getMultiFactorResolver from the Firebase Auth package
          const multiFactorResolver = getMultiFactorResolver(firebase.auth, error);

          // Check enrolled factors for TOTP and SMS
          const enrolledFactors = multiFactorResolver.hints;
          const isTOTP = enrolledFactors.some(factor => factor.factorId === 'totp');
          const isSMS = enrolledFactors.some(factor => factor.factorId === 'phone');

          // Set state based on detected factors
          if (isTOTP) {
            this.setState({ totpRequired: true, multiFactorResolver });
          } else if (isSMS) {
            const hint = enrolledFactors.find(factor => factor.factorId === 'phone');
            const recaptchaVerifier = this.state.recaptchaVerifier;

            const phoneAuthProvider = new PhoneAuthProvider(firebase.auth);
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
              {
                multiFactorHint: hint,
                session: multiFactorResolver.session,
              },
              recaptchaVerifier
            );

            this.setState({
              mfaRequired: true,
              multiFactorResolver,
              verificationId,
            });
          }
        } catch (verificationError) {
          console.error('Error handling multi-factor authentication:', verificationError);
          this.setState({ error: true });
        }
      } else {
        // Handle other errors such as wrong credentials
        console.error('Error during sign-in:', error);
        this.setState({ error: true });
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  handleMFA = async () => {
    this.setState({ verificationLoading: true });
    const { mfaVerificationCode, verificationId, multiFactorResolver } = this.state;
    const { firebase, history, setUser } = this.props;

    try {
      // Create the phone credential using the verification ID and the code entered by the user
      const phoneCredential = PhoneAuthProvider.credential(verificationId, mfaVerificationCode);

      // Create a multi-factor assertion using the phone credential
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneCredential);

      // Complete the sign-in using the multi-factor assertion
      await multiFactorResolver.resolveSignIn(multiFactorAssertion);

      // Fetch the current user after resolving MFA
      const user = await firebase.doGetCurrentUser();

      // Set the authenticated user in your app state and navigate to the dashboard
      setUser(user);
      history.push('/dashboard');
    } catch (error) {
      console.error('Error during MFA verification:', error);
      this.setState({ mfaError: true });
    } finally {
      this.setState({ verificationLoading: false });
    }
  };

  handleTOTP = async () => {
    const { totpCode, multiFactorResolver } = this.state;
    const { setUser, history, firebase } = this.props;

    this.setState({ verificationLoading: true });

    try {
      // Use assertionForSignIn with the TOTP factor uid and the OTP entered by the user
      const selectedIndex = multiFactorResolver.hints.findIndex(hint => hint.factorId === 'totp');
      const totpAssertion = TotpMultiFactorGenerator.assertionForSignIn(
        multiFactorResolver.hints[selectedIndex].uid,
        totpCode
      );

      // Resolve sign-in with the TOTP assertion
      await multiFactorResolver.resolveSignIn(totpAssertion);

      const user = await firebase.doGetCurrentUser();

      // Set the authenticated user in your app state and navigate to the dashboard
      setUser(user);
      history.push('/dashboard');
    } catch (error) {
      console.error('Error verifying TOTP:', error);
      this.setState({ mfaError: true });
    } finally {
      this.setState({ verificationLoading: false });
    }
  };

  resetPassword = (email) => {
    const { firebase } = this.props;

    this.setState({ isOpen: false });
    firebase
      .doPasswordReset(email)
      .then(() => this.setState({ resetPasswordSuccess: true }))
      .catch((err) => this.setState({ resetPasswordError: err.message }));
  };

  render() {
    const {
      email,
      password,
      showPassword,
      loading,
      error,
      checked,
      isOpen,
      resetPasswordError,
      resetPasswordSuccess,
      disabledAccountError,
      mfaVerificationCode,
      mfaRequired,
      totpRequired,
      totpCode,
      mfaError,
      verificationLoading,
    } = this.state;

    return (
      <div className="login-page">
        <AppHeader />
        <div className="login-form-container">
          <h3>Sign In</h3>
          {!mfaRequired && !totpRequired && (
            <form onSubmit={this.handleSubmit}>
              <div className="login-form">
                <div className="form-group-container m-b-lg">
                  <TextField
                    id="email"
                    type="email"
                    label="Email address"
                    fullWidth
                    value={email}
                    onChange={(e) => this.setState({ email: e.target.value, error: false })}
                  />
                </div>
                <div className="form-group-container m-t-sm m-b-lg">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.setState({ showPassword: !showPassword })}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
              <div className="actions">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={checked}
                      onChange={() => this.setState({ checked: !checked })}
                    />
                  }
                  label="Keep me signed in"
                />
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  SIGN IN
                  {loading && <CircularProgress size={24} className="button-processing-indicator" />}
                </Button>
              </div>
              <div className="primary pointer m-t-lg" onClick={() => this.setState({ isOpen: true })}>
                Forgot password?
              </div>
            </form>
          )}

          {mfaRequired && (
            <div>
              <div className='secondary'>Enter verification code sent to your phone number</div>
              <div className='m-t-md m-b-lg'>
                <TextField
                  id="code"
                  type="text"
                  label="Code"
                  fullWidth
                  value={mfaVerificationCode}
                  onChange={(e) => this.setState({ mfaVerificationCode: e.target.value })}
                />
              </div>
              <div className='w-100 d-flex space-between'>
                <Button
                  onClick={() => this.setState({ mfaVerificationCode: '', mfaRequired: false })}
                  color="primary"
                >
                  Back
                </Button>
                <CustomButton
                  type="submit"
                  onClick={this.handleMFA}
                  disabled={!mfaVerificationCode.length || verificationLoading}
                  loading={verificationLoading}
                  text="Verify Code"
                />
              </div>
            </div>
          )}

          {totpRequired && (
            <div>
              <div className='m-b-sm secondary'>Enter Authenticator verification code here</div>
              <TextField
                id="totpCode"
                type="text"
                label="Authenticator Code"
                value={totpCode}
                onChange={(e) => this.setState({ totpCode: e.target.value })}
                style={{ width: '100%' }}
              />
              <div className='m-t-lg'>
                <CustomButton
                  type="submit"
                  onClick={this.handleTOTP}
                  disabled={!totpCode || verificationLoading}
                  loading={verificationLoading}
                  color="primary"
                  text="Verify Code"
                />
              </div>
            </div>
          )}

        </div>
        <Alert
          variant="error"
          message="Invalid email or password"
          open={error}
          onClose={() => this.setState({ error: false })}
        />
        <Alert
          variant="error"
          message="Error verifying code"
          open={mfaError}
          onClose={() => this.setState({ mfaError: false })}
        />
        <Alert
          variant="error"
          message="Your account has been disabled by an administrator"
          open={disabledAccountError}
          onClose={() => this.setState({ disabledAccountError: false })}
        />
        <Alert
          variant="error"
          message={resetPasswordError}
          open={!!resetPasswordError}
          onClose={() => this.setState({ resetPasswordError: null })}
        />
        <Alert
          variant="success"
          message="Please follow instructions that we sent you"
          open={resetPasswordSuccess}
          onClose={() => this.setState({ resetPasswordSuccess: false })}
        />
        <ForgotPasswordModal
          open={isOpen}
          onClose={() => this.setState({ isOpen: false })}
          onSubmit={this.resetPassword}
        />
        <AppFooter link="sign-up" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setCurrentUser(user)),
  };
};

LoginPage.propTypes = {
  setUser: PropTypes.func.isRequired,
  firebase: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withFirebase(connect(null, mapDispatchToProps)(LoginPage)));
