import imageIcon from '../assets/ps_image_icon.svg';
import zipIcon from '../assets/ps_zip_icon.svg';
import documentIcon from '../assets/ps_document_icon.svg';

import { caseConstants } from '../constants';

const { procedureFlows, numberOfSteps, numberOfAClSteps, numberOfCustomSteps, fileTypes } = caseConstants;

export const getCaseStepsByFlow = (flow) => {
  if (flow === procedureFlows.HTO || flow === procedureFlows.HTO_LATERAL || flow === procedureFlows.DFO_Medial || flow === procedureFlows.DFO_Medial) {
    return numberOfSteps;
  }

  if (flow === procedureFlows.ACL) {
    return numberOfAClSteps;
  }

  if (flow === procedureFlows.CUSTOM || flow === procedureFlows.DOUBLE_LEVEL_OSTEOTOMY) {
    return numberOfCustomSteps;
  }
};

export const getFileIcon = (type) => {
  switch (type) {
    case fileTypes.xRay:
      return imageIcon;
    case fileTypes.scan:
      return zipIcon;
    case fileTypes.preop:
      return documentIcon;
    case fileTypes.block:
      return zipIcon;
    case fileTypes.image:
      return imageIcon;
    case fileTypes.mri:
      return zipIcon;
    case fileTypes.aoa:
      return documentIcon;
    case fileTypes.allograft:
      return documentIcon;
    case fileTypes.chargedSheet:
      return documentIcon;
    case fileTypes.coc:
      return documentIcon;
    case fileTypes.manufacturing:
      return zipIcon;
    default:
      return documentIcon;
  }
};

export const getFileLabel = (type) => {
  switch (type) {
    case fileTypes.chargedSheet:
      return 'Usage Document';
    case fileTypes.aoa:
      return 'Registry Form';
    case fileTypes.allograft:
      return 'Graft';
    case fileTypes.xRay:
      return 'Xray';
    case fileTypes.scan:
      return 'CT';
    case fileTypes.mri:
      return 'MRI';
    case fileTypes.report:
      return 'Surgeon Consultation Report';
    case fileTypes.preop:
      return 'Surgical Plan';
    case fileTypes.block:
      return 'Block';
    case fileTypes.coc:
      return 'Certificate of Conformity';
    case fileTypes.image:
      return 'Image';
    case fileTypes.planReport:
      return 'Planning Report';
    case fileTypes.designReport:
      return 'Design Report';
    case fileTypes.conformanceReport:
      return 'Conformance Report';
    case fileTypes.manufacturing:
      return 'Manufacturing Files';
    default:
      return type;
  }
};

export const filterRequired = (item) => {
  let numberOfSteps = caseConstants.numberOfSteps;

  if (item?.procedureFlow === caseConstants.procedureFlows?.ACL) {
    numberOfSteps = caseConstants.numberOfAClSteps;
  }

  if (item?.procedureFlow === caseConstants.procedureFlows?.CMF) {
    numberOfSteps = caseConstants.numberOfCmfSteps;
  }

  if (item?.procedureFlow === caseConstants.procedureFlows?.CUSTOM || item?.procedureFlow === caseConstants.procedureFlows?.ONCOL) {
    numberOfSteps = caseConstants.numberOfCustomSteps;
  }

  return item.access && item.step <= numberOfSteps;
};