const IframeViewer = ({ fileUrl }) => {
  if (!fileUrl) {
    return <div>Loading...</div>;
  }

  return (
    <iframe
      src={fileUrl}
      width="100%"
      height="700"
      frameBorder="0"
      allowFullScreen
    />
  );
};

export default IframeViewer;
