import {
  LOAD_CASES,
  SET_CASES,
  SET_COMPLETED_CASES,
  SET_ACTIVE_TAB,
  CREATE_CASE,
  SET_ACTIVE_CASE,
  DISCARD_ACTIVE_CASE,
  UPDATE_CASE,
  RESET_CASES,
  SET_SELECTED_FLOW,
  SET_CASE_NOTES,
  ADD_CASE_NOTE,
  LOAD_COMPLETED_CASES,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  activeCase: null,
  activeTab: 0,
  selectedFlow: null,
  loading: false,
  isLoaded: false,
  completedCases: [],
  completedLoading: false,
  completedLoaded: false,
};

const casesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CASES:
      return { ...state, loading: true };
    case SET_CASES:
      return { ...state, loading: false, list: action.cases, isLoaded: true };
    case LOAD_COMPLETED_CASES:
      return { ...state, completedLoading: true };
    case SET_COMPLETED_CASES:
      return { ...state, completedLoading: false, completedCases: [...state.completedCases, ...action.cases], completedLoaded: true };
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.tab };
    case SET_SELECTED_FLOW:
      return { ...state, selectedFlow: action.flow };
    case CREATE_CASE:
      return {
        ...state,
        list: [...state.list, action.case].sort((a, b) => (a.date > b.date ? 1 : -1)),
        activeCase: action.case,
        loading: false,
      };
    case UPDATE_CASE:
      const updatedCase = state.list.find((item) => item.id === action.case.id);
      const newCase = { ...updatedCase, ...action.case };

      return {
        ...state,
        list: state.list.map((item) => (item.id === action.case.id ? newCase : item)),
        activeCase: newCase,
        loading: false,
      };
    case SET_ACTIVE_CASE:
      return { ...state, activeCase: action.case };
    case SET_CASE_NOTES:
      return { ...state, activeCase: { ...state?.activeCase, caseNotes: action.notes || [] } };
    case ADD_CASE_NOTE:
      return { ...state, activeCase: { ...state?.activeCase, caseNotes: [action.note, ...state?.activeCase?.caseNotes] } };
    case DISCARD_ACTIVE_CASE:
      return { ...state, activeCase: null, activeTab: 0, selectedFlow: null };
    case RESET_CASES:
      return { ...state, isLoaded: false };
    default:
      return state;
  }
};

export default casesReducer;
