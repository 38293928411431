import React, { useState, useEffect } from 'react';
import Firebase from '../../firebase';
import Modal from '../modals/Modal';
import IframeViewer from '../shared/iframe/IframeViewer';

const PolicyModal = ({ year, open, onClose }) => {
  const [policyUrl, setPolicyUrl] = useState('');

  useEffect(() => {
    if (open) {
      const fetchPolicy = async () => {
        try {
          const response = await Firebase.getDownloadLink('static/PS_privacy_policy.pdf');
          setPolicyUrl(response);  // Set the Firebase URL for the PDF
        } catch (error) {
          console.error('Error fetching privacy policy:', error);
        }
      };

      fetchPolicy();
    } else {
      setPolicyUrl('');  // Clear the URL when modal closes
    }
  }, [open]);

  return (
    <Modal
      size="md"
      fullWidth
      open={open}
      onClose={onClose}
      title={`Privacy Policy ${year}`}
      subtitle="Personalised Surgery Pty Ltd"
    >
      <div className="footer-modal__content">
        <div className="footer-modal__block">
          {policyUrl ? (
            <IframeViewer fileUrl={policyUrl} />
          ) : (
            <p>Loading privacy policy...</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PolicyModal;
