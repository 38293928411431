import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/PhoneIphone';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ExportIcon from '@material-ui/icons/CloudDownloadOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import UndoIcon from '@material-ui/icons/Undo';
import EventIcon from '@material-ui/icons/Event';
import RefreshIcon from '@material-ui/icons/Refresh';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Button from '../shared/Button';
import { CaseSteps } from '../cases';

import Firebase from '../../firebase';

import trainingIcon from '../../assets/training_icon_active.svg';
import paidIcon from '../../assets/paid_icon.svg';

import { utils } from '../../util';

import './modal.scss';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: '#2B2B2B',
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    paddingBottom: '20px !important',
  },
  tooltip: {
    padding: theme.spacing(1),
    fontSize: '12px',
    whiteSpace: 'pre-line !important',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    borderTop: '1px solid #f2f2f2',
    justifyContent: (props) => props.closeButton ? 'space-between' : 'flex-end'
  },
}))(MuiDialogActions);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box style={{ height: '100%' }} p={0}>
        {children}
      </Box>
    </Typography>
  );
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Modal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    onTabChange,
    title,
    subtitle,
    caseId,
    closeButton,
    submitButton,
    children,
    disabled,
    loading,
    tabs,
    size,
    fullWidth,
    activeTab,
    isCase,
    autoSize,
    isAdmin,
    isGlobalAdmin,
    isSeniorEngineer,
    onDeleteClick,
    onRestoreClick,
    onExportClick,
    step,
    trainingMode,
    flow,
    withExport,
    isPaid,
    // caseNote,
    // onCaseNoteClick,
    // onFilesClick,
    onNotifyUsersClick,
    adminModal,
    onRefreshClick,
    onUndoClick,
    onTBCClick,
    onAdminApproveDateClick,
    onRequestDateClick,
    activeCase,
    onSettingsClick,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getFlowTheme = utils.getFlowsTheme(flow).main;

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const [links, setLinks] = useState({});

  useEffect(() => {
    const promises = [
      Firebase.getDownloadLink('static/PS-CT_Protocol.pdf'),
      Firebase.getDownloadLink('static/PS-MRI_Protocol.pdf'),
      Firebase.getDownloadLink('static/PS-EOS_Protocol.pdf'),
      Firebase.getDownloadLink('static/PS-CRANIAL_Protocol.pdf'),
    ];

    Promise.all(promises)
      .then((res) => {
        setLinks({
          ct: res[0],
          mri: res[1],
          eos: res[2],
          cranial: res[3]
        });
      });
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Dialog
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={size}
      scroll={autoSize ? 'body' : 'paper'}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {(isCase || subtitle) && (
          <div className="d-flex space-between">
            {subtitle && <div className="subtitle">{subtitle}</div>}
            <div className="d-flex" style={{ marginRight: 40, marginTop: -8 }}>
              {isCase && (
                <>
                  {!isAdmin && !activeCase?.requestedSurgeryDate && (
                    <Tooltip
                      title='Request New Surgery Date'
                      placement="bottom"
                      enterTouchDelay={50}
                      classes={{ tooltip: classes.tooltip }}
                      leaveTouchDelay={3000}
                    >
                      <IconButton
                        edge="start"
                        color="secondary"
                        aria-label="menu"
                        onClick={() => {
                          if (activeCase.date) {
                            onRequestDateClick(activeCase);
                          } else {
                            onTBCClick(activeCase);
                          }
                        }}
                        style={{ marginRight: '8px' }}
                      >
                        <EventIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isAdmin && !!activeCase?.requestedSurgeryDate && (
                    <Tooltip
                      title={`New Case Date Requested – ${moment(activeCase?.requestedSurgeryDate?.newDate).format('DD/MM/YY')}`}
                      placement="bottom"
                      enterTouchDelay={50}
                      classes={{ tooltip: classes.tooltip }}
                      leaveTouchDelay={3000}
                    >
                      <IconButton
                        edge="start"
                        color="primary"
                        aria-label="menu"
                        onClick={() => onAdminApproveDateClick(activeCase)}
                        style={{ marginRight: '8px' }}
                      >
                        <EventIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              {adminModal && isGlobalAdmin && (
                <div className="d-flex flex-end" style={{ marginRight: '8px' }}>
                  <IconButton edge="start" color="secondary" aria-label="menu" onClick={onSettingsClick}>
                    <SettingsIcon />
                  </IconButton>
                </div>
              )}
              {adminModal && (
                <div className="d-flex flex-end">
                  <IconButton edge="start" color="secondary" aria-label="menu" onClick={onRefreshClick}>
                    <RefreshIcon />
                  </IconButton>
                </div>
              )}
              {isAdmin && step > 0 && (
                <IconButton edge="start" color="secondary" aria-label="menu" onClick={onUndoClick} style={{ marginRight: '8px' }}>
                  <UndoIcon />
                </IconButton>
              )}
              {isAdmin && step > 0 && (
                <IconButton edge="start" color="secondary" aria-label="menu" onClick={onNotifyUsersClick}>
                  <PhoneIcon />
                </IconButton>
              )}
              {/*{!!caseNote && (*/}
              {/*  <IconButton edge="start" color="secondary" aria-label="menu" onClick={onCaseNoteClick} style={{ marginLeft: 0 }}>*/}
              {/*    <NotesIcon />*/}
              {/*  </IconButton>*/}
              {/*)}*/}
              {/*{step > 0 && (*/}
              {/*  <div className="delete-button-container">*/}
              {/*    <IconButton edge="start" color="secondary" aria-label="menu" onClick={onFilesClick}>*/}
              {/*      <FilesIcon />*/}
              {/*    </IconButton>*/}
              {/*  </div>*/}
              {/*)}*/}
              {(isGlobalAdmin || isSeniorEngineer) && step > 0 && !activeCase.archived && (
                <div className="delete-button-container">
                  <IconButton edge="start" color="secondary" aria-label="menu" onClick={onDeleteClick}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )}
              {isGlobalAdmin && step > 0 && !!activeCase.archived && (
                <div className="delete-button-container">
                  <IconButton edge="start" color="secondary" aria-label="menu" onClick={onRestoreClick}>
                    <RestoreFromTrashIcon />
                  </IconButton>
                </div>
              )}
              {withExport && caseId && (
                <div>
                  <IconButton
                    aria-label='more'
                    aria-controls='long-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                    color="secondary"
                  >
                    <ExportIcon />
                  </IconButton>
                  <Menu
                    id='long-menu'
                    anchorEl={anchorEl}
                    open={isOpen}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: 200,
                        width: 200,
                        marginTop: 48
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        onExportClick();
                        handleClose();
                      }}
                    >
                      <span>Referral Document</span>
                    </MenuItem>
                    <MenuItem>
                      <a target='_blank' href={links.ct} download className='default-styled-link'>PS CT Protocol</a>
                    </MenuItem>
                    <MenuItem>
                      <a target='_blank' href={links.mri} download className='default-styled-link'>PS MRI Protocol</a>
                    </MenuItem>
                    <MenuItem>
                      <a target='_blank' href={links.eos} download className='default-styled-link'>PS EOS Protocol</a>
                    </MenuItem>
                    <MenuItem>
                      <a target='_blank' href={links.cranial} download className='default-styled-link'>PS CRANIAL Protocol</a>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="d-flex">
          {title}
          {trainingMode && <img src={trainingIcon} alt="new-case-icon" style={{ width: '16px', marginLeft: '8px' }} />}
          {isPaid && <img src={paidIcon} alt="new-case-icon" style={{ width: '16px', marginLeft: '8px' }} />}
        </div>
        {isCase && <CaseSteps style={getFlowTheme} flow={flow} />}
      </DialogTitle>
      <ThemeProvider
        theme={(theme) =>
          createMuiTheme({
            ...theme,
            palette: {
              ...theme.palette,
              primary: { ...utils.getFlowsTheme(flow) },
            },
          })
        }
      >
        <DialogContent style={{ height: '100%', padding: 0 }}>
          {tabs && tabs.length ? (
            <div className={classes.root}>
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  value={activeTab}
                  onChange={(e, value) => onTabChange(value)}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  {tabs.map((tab, index) => (
                    <Tab
                      key={index}
                      label={typeof tab === 'object' ? tab.label : tab}
                      disabled={tab.disabled}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </AppBar>

              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeTab}
                onChangeIndex={onTabChange}
                style={{ flex: 1 }}
                disabled
              >
                {tabs.map((tab, index) => (
                  <TabPanel key={`tab-${index}`} value={activeTab} index={index} dir={theme.direction}>
                    {children}
                  </TabPanel>
                ))}
              </SwipeableViews>
            </div>
          ) : (
            children
          )}
        </DialogContent>
      </ThemeProvider>
      {(closeButton || submitButton) && (
        <DialogActions closeButton={closeButton}>
          {closeButton && <Button type="cancel" text={closeButton} onClick={onClose} />}
          {submitButton && <Button text={submitButton} onClick={onSubmit} color="primary" disabled={disabled} loading={loading} />}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onTabChange: PropTypes.func,
  activeTab: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  closeButton: PropTypes.string,
  submitButton: PropTypes.string,
  disabled: PropTypes.bool,
  tabs: PropTypes.array,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  fullWidth: PropTypes.bool,
  isCase: PropTypes.bool,
  autoSize: PropTypes.bool,
};

export default Modal;
