import { LOAD_USERS, RECEIVE_USERS, SET_ROLE, DISABLE_USER, DISABLE_2FA, CHANGE_ENGINEER_LEVEL } from '../actions/actionTypes';

const initialState = {
  list: [],
};

const usersReducer = (state = initialState, action) => {
  const users = state.list.slice();
  let user = state.list.find((item) => item.uid === action.uid);

  switch (action.type) {
    case LOAD_USERS:
      return { ...state, loading: true };
    case RECEIVE_USERS:
      return { ...state, loading: false, list: action.users };
    case SET_ROLE:
      if (user) {
        user.role = action.role;
        user.administrationCountry = action.country;
        user.distributor = action.distributor;
      }

      return { ...state, list: users, loading: false };
    case CHANGE_ENGINEER_LEVEL:
      if (user && action.data?.engineerLevel) {
        user.engineerLevel = action.data.engineerLevel;
      }

      return { ...state, list: users, loading: false };
    case DISABLE_USER:
      const list = state.list.slice();
      const disabledUser = list.find((item) => item.uid === action.uid);

      if (disabledUser) {
        disabledUser.disabled = action.value;
      }

      return { ...state, list, loading: false };
    case DISABLE_2FA:
      const usersList = state.list.slice();
      const updatedUser = usersList.find((item) => item.uid === action.uid);

      if (updatedUser) {
        updatedUser.multiFactorType = null;
        updatedUser.multiFactorEnabled = false;
      }

      return { ...state, list: usersList, loading: false };
    default:
      return state;
  }
};

export default usersReducer;
