import { orderBy } from 'lodash';

import {
  LOAD_USERS,
  RECEIVE_USERS,
  RECEIVE_COUNTRY_LIST,
  LOAD_COUNTRY_LIST,
  SET_ROLE,
  DISABLE_USER,
  LOAD_PROCEDURES,
  SET_PROCEDURES,
  ADD_PROCEDURE,
  DELETE_PROCEDURE,
  TOGGLE_PROCEDURE,
  UPDATE_PRODUCTS,
  SET_DISTRIBUTORS,
  LOAD_DISTRIBUTORS,
  ADD_DISTRIBUTOR,
  DELETE_DISTRIBUTOR,
  SET_HOSPITALS,
  LOAD_HOSPITALS,
  ADD_HOSPITAL,
  DELETE_HOSPITAL,
  ADD_MANUFACTURER,
  DELETE_MANUFACTURER,
  LOAD_MANUFACTURERS,
  SET_MANUFACTURERS,
  LOAD_SURGEONS,
  SET_SURGEONS,
  ADD_SURGEON,
  DISABLE_SURGEON,
  ENABLE_SURGEON,
  UPDATE_SURGEON,
  UPDATE_SURGEON_HOSPITALS,
  LOAD_DOCUMENTS,
  SET_DOCUMENTS,
  ADD_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_DOCUMENTS,
  LOAD_PRODUCTS,
  SET_PRODUCTS,
  ADD_PRODUCTS,
  DELETE_PRODUCTS,
  UPDATE_FLOW,
  UPDATE_DISTRIBUTOR_PROCEDURE,
  UPDATE_DISTRIBUTOR,
  LOAD_RADIOLOGY,
  SET_RADIOLOGY,
  ADD_RADIOLOGY,
  DELETE_RADIOLOGY,
  UPDATE_LEAD_DAYS,
  CHANGE_ENGINEER_LEVEL,
  LOAD_SETTINGS, UPDATE_SETTINGS, DISABLE_2FA,
} from './actionTypes';

import {
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  addDoc
} from 'firebase/firestore';
import { ref, deleteObject, getStorage } from 'firebase/storage';
import Firebase from '../firebase'; // Ensure Firebase is correctly set up with modular imports
import {
  CONSTANTS_COLLECTION,
  COUNTRIES_DOCUMENT,
  USER_AUTHORIZATION_COLLECTION,
  USER_DATA_COLLECTION,
  PROCEDURES_COLLECTION,
  DISTRIBUTORS_COLLECTION,
  HOSPITALS_COLLECTION,
  MANUFACTURERS_COLLECTION,
  SURGEONS_COLLECTION,
  DOCUMENTS_COLLECTION,
  PRODUCTS_COLLECTION,
  RADIOLOGY_COLLECTION,
  SETTINGS_COLLECTION
} from '../firebase/collections';
import { mergeArrayObjectsByField } from '../util/utils';
import { sendEmail, api } from '../util';
import userRoles from '../constants/userRoles';
import { roleAssignmentEmail } from '../constants/emailTemplates';
import urls from '../constants/urls';

// Set up Firestore instance
const db = Firebase.db;
const storage = getStorage();

// Countries

export const setCountryList = (data) => ({
  type: RECEIVE_COUNTRY_LIST,
  countries: data.countryList,
});

export const fetchCountryList = () => (dispatch) => {
  dispatch({ type: LOAD_COUNTRY_LIST });
  const countryDoc = doc(db, CONSTANTS_COLLECTION, COUNTRIES_DOCUMENT);

  return getDoc(countryDoc)
    .then((docSnap) => docSnap.data())
    .then((data) => dispatch(setCountryList(data)));
};

export const updateCountryList = (countryList) => (dispatch) => {
  const countryDoc = doc(db, CONSTANTS_COLLECTION, COUNTRIES_DOCUMENT);

  return updateDoc(countryDoc, { countryList })
    .then(() => dispatch(fetchCountryList()));
};

// Users

export const saveUsers = (users) => ({ type: RECEIVE_USERS, users: orderBy(users, 'lastName') });

export const fetchUsers = () => (dispatch) => {
  dispatch({ type: LOAD_USERS });

  return Promise.all([fetchUserData(), fetchUserRoles()]).then((responses) =>
    dispatch(saveUsers(mergeArrayObjectsByField('uid', responses)))
  );
};

export const fetchUserData = () => {
  const userCollection = collection(db, USER_DATA_COLLECTION);

  return getDocs(userCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() })));
};

export const fetchUserRoles = () => {
  const rolesCollection = collection(db, USER_AUTHORIZATION_COLLECTION);

  return getDocs(rolesCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() })));
};

export const setRole = (uid, role = null, country = null, distributor = null) => (dispatch, getState) => {
  const rolesDoc = doc(db, USER_AUTHORIZATION_COLLECTION, uid);
  const state = getState();
  const users = state.users.list;
  const notificationsSettings = state.settings.notifications;

  dispatch({ type: LOAD_USERS });

  return updateDoc(rolesDoc, { role, administrationCountry: country, distributor })
    .then(() => {
      const recipient = users.find((user) => user.uid === uid);

      if (notificationsSettings.active) {
        sendEmail(recipient, roleAssignmentEmail.subject, roleAssignmentEmail.message);
      }

      return dispatch({ type: SET_ROLE, uid, role, country, distributor });
    });
};

export const changeEngineerLevel = (uid, level = 1) => (dispatch) => {
  const userDoc = doc(db, USER_AUTHORIZATION_COLLECTION, uid);

  updateDoc(userDoc, { engineerLevel: level })
    .then(() => dispatch({ type: CHANGE_ENGINEER_LEVEL, uid, data: { engineerLevel: level } }));
};

export const disableUser = (uid, value) => async (dispatch) => {
  const userDoc = doc(db, USER_AUTHORIZATION_COLLECTION, uid);

  await api.post(urls.disableUser, { uid, value });
  await updateDoc(userDoc, { disabled: value });
  return dispatch({ type: DISABLE_USER, uid, value });
};

export const disable2FA = (uid) => async (dispatch) => {
  try {
    const res = await api.post(urls.disable2FA, { uid });
    return dispatch({ type: DISABLE_2FA, uid });
  } catch (err) {
    console.error(err);
  }
};

// Procedures

export const setProcedures = (procedures) => ({ type: SET_PROCEDURES, procedures });

export const fetchProcedures = (withoutLoading) => (dispatch) => {
  if (!withoutLoading) {
    dispatch({ type: LOAD_PROCEDURES });
  }

  const proceduresCollection = collection(db, PROCEDURES_COLLECTION);

  return getDocs(proceduresCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((procedures) => dispatch(setProcedures(orderBy(procedures, 'name'))));
};

export const addProcedure = (name) => (dispatch) => {
  const proceduresCollection = collection(db, PROCEDURES_COLLECTION);

  dispatch({ type: LOAD_PROCEDURES });

  return addDoc(proceduresCollection, { name, active: [], products: [], flow: null })
    .then((docRef) => dispatch({ type: ADD_PROCEDURE, id: docRef.id, name }));
};

export const deleteProcedure = (id) => (dispatch) => {
  const procedureDoc = doc(db, PROCEDURES_COLLECTION, id);

  dispatch({ type: LOAD_PROCEDURES });

  return deleteDoc(procedureDoc)
    .then(() => dispatch({ type: DELETE_PROCEDURE, id }));
};

export const toggleProcedure = (id, active) => (dispatch) => {
  const procedureDoc = doc(db, PROCEDURES_COLLECTION, id);

  dispatch({ type: TOGGLE_PROCEDURE, id, active });
  return updateDoc(procedureDoc, { active });
};

export const updateFlow = (id, flow) => (dispatch) => {
  const procedureDoc = doc(db, PROCEDURES_COLLECTION, id);

  dispatch({ type: UPDATE_FLOW, id, flow });
  return updateDoc(procedureDoc, { flow });
};

export const updateProducts = (id, products) => (dispatch) => {
  const procedureDoc = doc(db, PROCEDURES_COLLECTION, id);

  dispatch({ type: UPDATE_PRODUCTS, id, products });
  return updateDoc(procedureDoc, { products });
};

export const updateDocuments = (id, documents) => (dispatch) => {
  const procedureDoc = doc(db, PROCEDURES_COLLECTION, id);

  dispatch({ type: UPDATE_DOCUMENTS, id, documents });
  return updateDoc(procedureDoc, { documents });
};

export const updateLeadDays = (id, leadDays) => (dispatch) => {
  const procedureDoc = doc(db, PROCEDURES_COLLECTION, id);

  dispatch({ type: UPDATE_LEAD_DAYS, id, leadDays });
  return updateDoc(procedureDoc, { leadDays });
};

// Distributors

export const setDistributors = (distributors) => ({ type: SET_DISTRIBUTORS, distributors });

export const fetchDistributors = () => (dispatch) => {
  dispatch({ type: LOAD_DISTRIBUTORS });

  const distributorsCollection = collection(db, DISTRIBUTORS_COLLECTION);

  return getDocs(distributorsCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((distributors) => dispatch(setDistributors(orderBy(distributors, 'name'))));
};

export const addDistributor = (distributor) => (dispatch) => {
  const distributorsCollection = collection(db, DISTRIBUTORS_COLLECTION);

  dispatch({ type: LOAD_DISTRIBUTORS });

  return addDoc(distributorsCollection, distributor)
    .then((docRef) => dispatch({ type: ADD_DISTRIBUTOR, id: docRef.id, ...distributor }));
};

export const updateAssignedProcedures = (id, assignedProcedures) => (dispatch) => {
  const distributorDoc = doc(db, DISTRIBUTORS_COLLECTION, id);

  dispatch({ type: UPDATE_DISTRIBUTOR_PROCEDURE, id, assignedProcedures });
  return updateDoc(distributorDoc, { assignedProcedures });
};

export const deleteDistributor = (id) => (dispatch) => {
  const distributorDoc = doc(db, DISTRIBUTORS_COLLECTION, id);

  dispatch({ type: LOAD_DISTRIBUTORS });

  return fetchUserRoles().then((users) => {
    const promises = users.map((user) => {
      if (user.distributor === id) {
        return dispatch(setRole(user.uid, null, null, null));
      }
      return Promise.resolve();
    });

    Promise.all(promises).then(() => {
      return deleteDoc(distributorDoc)
        .then(() => dispatch({ type: DELETE_DISTRIBUTOR, id }));
    });
  });
};

export const updateDistributor = (id, distributor) => (dispatch) => {
  const distributorDoc = doc(db, DISTRIBUTORS_COLLECTION, id);

  dispatch({ type: LOAD_DISTRIBUTORS });

  return setDoc(distributorDoc, distributor, { merge: true })
    .then(() => dispatch({ type: UPDATE_DISTRIBUTOR, id, distributor }));
};

// Additional collections like Hospitals, Manufacturers, Surgeons, Documents, etc., follow the same pattern as above.

export const deleteDocument = (id) => (dispatch) => {
  const documentDoc = doc(db, DOCUMENTS_COLLECTION, id);
  const storageRef = ref(storage, `documents/${id}`);

  dispatch({ type: LOAD_DOCUMENTS });

  return deleteDoc(documentDoc)
    .then(() => {
      deleteObject(storageRef);
      return dispatch({ type: DELETE_DOCUMENT, id });
    });
};

// Hospitals

export const setHospitals = (hospitals) => ({ type: SET_HOSPITALS, hospitals });

export const fetchHospitals = () => (dispatch) => {
  dispatch({ type: LOAD_HOSPITALS });

  const hospitalsCollection = collection(db, HOSPITALS_COLLECTION);

  return getDocs(hospitalsCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((hospitals) => dispatch(setHospitals(orderBy(hospitals, 'name', 'asc'))));
};

export const addHospital = (hospital) => (dispatch) => {
  const hospitalsCollection = collection(db, HOSPITALS_COLLECTION);

  dispatch({ type: LOAD_HOSPITALS });

  return addDoc(hospitalsCollection, hospital)
    .then((docRef) => dispatch({ type: ADD_HOSPITAL, id: docRef.id, ...hospital }));
};

export const deleteHospital = (id) => (dispatch) => {
  const hospitalDoc = doc(db, HOSPITALS_COLLECTION, id);

  dispatch({ type: LOAD_HOSPITALS });

  return deleteDoc(hospitalDoc)
    .then(() => dispatch({ type: DELETE_HOSPITAL, id }));
};

// Manufacturers

export const setManufacturers = (manufacturers) => ({ type: SET_MANUFACTURERS, manufacturers });

export const fetchManufacturers = () => (dispatch) => {
  dispatch({ type: LOAD_MANUFACTURERS });

  const manufacturersCollection = collection(db, MANUFACTURERS_COLLECTION);

  return getDocs(manufacturersCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((manufacturers) => dispatch(setManufacturers(orderBy(manufacturers, 'name'))));
};

export const addManufacturer = (manufacturer) => (dispatch) => {
  const manufacturersCollection = collection(db, MANUFACTURERS_COLLECTION);

  dispatch({ type: LOAD_MANUFACTURERS });

  return addDoc(manufacturersCollection, manufacturer)
    .then((docRef) => dispatch({ type: ADD_MANUFACTURER, id: docRef.id, ...manufacturer }));
};

export const deleteManufacturer = (id) => (dispatch) => {
  const manufacturerDoc = doc(db, MANUFACTURERS_COLLECTION, id);

  dispatch({ type: LOAD_MANUFACTURERS });

  return deleteDoc(manufacturerDoc)
    .then(() => dispatch({ type: DELETE_MANUFACTURER, id }));
};

// Radiology

export const setRadiology = (radiologyList) => ({ type: SET_RADIOLOGY, radiologyList });

export const fetchRadiology = () => (dispatch) => {
  dispatch({ type: LOAD_RADIOLOGY });

  const radiologyCollection = collection(db, RADIOLOGY_COLLECTION);

  return getDocs(radiologyCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((radiologyList) => dispatch(setRadiology(orderBy(radiologyList, 'name'))));
};

export const addRadiology = (radiology) => (dispatch) => {
  const radiologyCollection = collection(db, RADIOLOGY_COLLECTION);

  dispatch({ type: LOAD_RADIOLOGY });

  return addDoc(radiologyCollection, radiology)
    .then((docRef) => dispatch({ type: ADD_RADIOLOGY, id: docRef.id, ...radiology }));
};

export const deleteRadiology = (id) => (dispatch) => {
  const radiologyDoc = doc(db, RADIOLOGY_COLLECTION, id);

  dispatch({ type: LOAD_RADIOLOGY });

  return deleteDoc(radiologyDoc)
    .then(() => dispatch({ type: DELETE_RADIOLOGY, id }));
};

// Surgeons

export const setSurgeons = (surgeons) => ({ type: SET_SURGEONS, surgeons });

export const fetchSurgeons = () => (dispatch) => {
  dispatch({ type: LOAD_SURGEONS });

  const surgeonsCollection = collection(db, SURGEONS_COLLECTION);

  return getDocs(surgeonsCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((surgeons) => dispatch(setSurgeons(surgeons)));
};

export const addSurgeon = (surgeon, distributor) => (dispatch, getState) => {
  const surgeonsCollection = collection(db, SURGEONS_COLLECTION);
  const authCollection = collection(db, USER_AUTHORIZATION_COLLECTION);
  const state = getState();
  const users = state.users.list;
  const notificationsSettings = state.settings.notifications;

  const data = {
    distributor,
    engineer: surgeon.engineer,
    manufacturer: surgeon.manufacturer,
    manager: surgeon.manager,
    salesRep: surgeon.salesRep,
    logistics: surgeon.logistics,
    hospitals: [],
    userId: surgeon.userId,
  };

  dispatch({ type: LOAD_SURGEONS });

  return Promise.all([
    setDoc(doc(surgeonsCollection, surgeon.id), data),
    updateDoc(doc(authCollection, surgeon.userId), { role: userRoles.SURGEON.name, distributor }),
  ]).then(() => {
    const recipient = users.find((user) => user.uid === surgeon.uid);

    if (notificationsSettings.active) {
      sendEmail(recipient, roleAssignmentEmail.subject, roleAssignmentEmail.message);
    }

    dispatch({ type: SET_ROLE, uid: surgeon.userId, role: userRoles.SURGEON.name, distributor });
    dispatch({ type: ADD_SURGEON, userId: surgeon.userId, surgeon, distributor });
  });
};

export const disableSurgeon = (id) => (dispatch) => {
  const surgeonDoc = doc(db, SURGEONS_COLLECTION, id);

  dispatch({ type: LOAD_SURGEONS });

  return setDoc(surgeonDoc, { disabled: true }, { merge: true })
    .then(() => dispatch({ type: DISABLE_SURGEON, id }));
};

export const enableSurgeon = (id) => (dispatch) => {
  const surgeonDoc = doc(db, SURGEONS_COLLECTION, id);

  dispatch({ type: LOAD_SURGEONS });

  return setDoc(surgeonDoc, { disabled: false }, { merge: true })
    .then(() => dispatch({ type: ENABLE_SURGEON, id }));
};

export const updateSurgeon = (id, surgeon) => (dispatch) => {
  const surgeonDoc = doc(db, SURGEONS_COLLECTION, id);

  dispatch({ type: LOAD_SURGEONS });

  return updateDoc(surgeonDoc, surgeon)
    .then(() => dispatch({ type: UPDATE_SURGEON, id, surgeon }));
};

export const updateHospitals = (id, hospitals) => (dispatch) => {
  const surgeonDoc = doc(db, SURGEONS_COLLECTION, id);

  dispatch({ type: UPDATE_SURGEON_HOSPITALS, id, hospitals });
  return updateDoc(surgeonDoc, { hospitals });
};

// Documents

export const setDocuments = (documents) => ({ type: SET_DOCUMENTS, documents });

export const fetchDocuments = () => (dispatch) => {
  dispatch({ type: LOAD_DOCUMENTS });

  const documentsCollection = collection(db, DOCUMENTS_COLLECTION);

  return getDocs(documentsCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((documents) => dispatch(setDocuments(documents)));
};

export const addDocument = (document) => (dispatch) => {
  const documentsCollection = collection(db, DOCUMENTS_COLLECTION);

  dispatch({ type: LOAD_DOCUMENTS });

  return setDoc(doc(documentsCollection, document.name), document)
    .then(() => dispatch({ type: ADD_DOCUMENT, id: document.name, ...document }));
};

// Products

export const setProducts = (products) => ({ type: SET_PRODUCTS, products });

export const fetchProducts = () => (dispatch) => {
  dispatch({ type: LOAD_PRODUCTS });

  const productsCollection = collection(db, PRODUCTS_COLLECTION);

  return getDocs(productsCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((products) => dispatch(setProducts(orderBy(products, 'sku'))));
};

export const addProduct = (product) => (dispatch) => {
  const productsCollection = collection(db, PRODUCTS_COLLECTION);

  dispatch({ type: LOAD_PRODUCTS });

  return addDoc(productsCollection, product)
    .then((docRef) => dispatch({ type: ADD_PRODUCTS, id: docRef.id, ...product }));
};

export const deleteProduct = (id) => (dispatch) => {
  const productDoc = doc(db, PRODUCTS_COLLECTION, id);
  const storageRef = ref(storage, `products/${id}`);

  dispatch({ type: LOAD_PRODUCTS });

  return deleteDoc(productDoc)
    .then(() => {
      deleteObject(storageRef);
      return dispatch({ type: DELETE_PRODUCTS, id });
    });
};

export const updateUser = (uid, userData) => (dispatch) => {
  const userDoc = doc(db, USER_DATA_COLLECTION, uid);

  return setDoc(userDoc, userData, { merge: true })
    .then(() => dispatch(fetchUsers()));
};

export const fetchSettings = () => (dispatch) => {
  const settingsCollection = collection(db, SETTINGS_COLLECTION);

  return getDocs(settingsCollection)
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((settings) => dispatch({ type: LOAD_SETTINGS, settings }));
};

export const updateNotificationsSettings = (data) => (dispatch) => {
  const notificationsDoc = doc(db, SETTINGS_COLLECTION, 'notifications');

  return updateDoc(notificationsDoc, data)
    .then(() => dispatch({ type: UPDATE_SETTINGS, settings: { notifications: data } }));
};
