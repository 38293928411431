import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setActiveTab } from '../../../../../../actions/casesActions';
import { getFileIcon, getFileLabel } from '../../../../../../util/cases';
import { fileTypes } from '../../../../../../constants/cases';

const OsteotomyStep6Static = ({ preop, goToFiles, notes }) => {
  if (preop) {
    return (
      <div className="case-static-container">
        <div className="case-file-list-item" onClick={goToFiles}>
          <div className="width-100">
            <div className="d-flex">
              <div className="d-flex align-center icon-file-container flex-1">
                <img src={getFileIcon(fileTypes.preop)} alt="file-icon" style={{ height: '20px', fill: '#eee' }} />
                <div className="font-bold">{getFileLabel(fileTypes.preop)}</div>
              </div>
              {preop.status && (
                <div className={`status-indicator status-indicator-file ${preop.status}`}>{preop.status}</div>
              )}
            </div>
            <div className="d-flex w-100 space-between m-t-sm">
              <div className="list-item-title">{preop.name}</div>
            </div>
          </div>
        </div>

        {notes && (
          <div className="m-t-md p-t-sm border-top">
            <div className="secondary">Notes:</div>
            <div>{notes}</div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

OsteotomyStep6Static.propTypes = {
  preop: PropTypes.object,
  goToFiles: PropTypes.func,
  notes: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    preop: state.cases.activeCase.files.find((file) => file.type === 'preop') || {},
    notes: state.cases.activeCase.notes && state.cases.activeCase.notes.step6,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep6Static);
