import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HardwareList from '../../HardwareList';
import RestrictComponent from '../../../../../shared/RestrictComponent';

import { setActiveTab, simpleUpdateCase } from '../../../../../../actions/casesActions';

import { userRoles } from '../../../../../../constants';
import { makeStyles } from '@material-ui/core';
import { getFileIcon, getFileLabel } from '../../../../../../util/cases';

const fileTypes = ['preop', 'block'];

const useStyles = makeStyles((theme) => ({
  primary: { color: theme.palette.primary.main },
}));

const CmfStep3Static = ({ activeCase, goToFiles, userRole, products, updateCase }) => {
  const { files, alignmentData, maxilla, leftMandible, rightMandible, genioplasty } = activeCase;
  const classes = useStyles();
  const {
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  } = userRoles;

  const initialHardwareList = maxilla ? maxilla.map((item) => ({ ...item, id: Math.random() * Math.random() })) : [];
  const initialLeftMandibleList = leftMandible
    ? leftMandible.map((item) => ({ ...item, id: Math.random() * Math.random() }))
    : [];
  const initialRightMandibleList = rightMandible
    ? rightMandible.map((item) => ({ ...item, id: Math.random() * Math.random() }))
    : [];
  const initialGenioplastyList = genioplasty
    ? genioplasty.map((item) => ({ ...item, id: Math.random() * Math.random() }))
    : [];

  const [maxillaList, setMaxillaList] = useState(initialHardwareList);
  const [leftMandibleList, setLeftMandibleList] = useState(initialLeftMandibleList);
  const [rightMandibleList, setRightMandibleList] = useState(initialRightMandibleList);
  const [genioplastyList, setGenioplastyList] = useState(initialGenioplastyList);

  const [isEditMode, setEditMode] = useState(false);
  const [isEditLeftMandibleMode, setEditLeftMandibleMode] = useState(false);
  const [isEditRightMandibleMode, setEditRightMandibleMode] = useState(false);
  const [isEditGenioplastyMode, setEditGenioplastyMode] = useState(false);

  useEffect(() => {
    if (maxilla) {
      setMaxillaList(maxilla.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    }
    if (leftMandible) {
      setLeftMandibleList(leftMandible.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    }
    if (rightMandible) {
      setRightMandibleList(rightMandible.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    }
    if (rightMandible) {
      setRightMandibleList(rightMandible.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    }
  }, [maxilla, leftMandible, rightMandible, genioplasty]);

  const notes = activeCase.notes && activeCase.notes.step5;

  const isPermission = !activeCase.trainingMode
    ? [GLOBAL_ADMIN, COUNTRY_ADMIN]
    : [
        GLOBAL_ADMIN,
        COUNTRY_ADMIN,
        DISTRIBUTOR_ADMIN,
        SURGEON,
        ENGINEER,
        SALES_REP,
        LOGISTICS,
        PRACTICE_MANAGER,
        MANUFACTURER,
      ];

  const onSave = () => {
    const updatedCase = {
      ...activeCase,
      maxilla: maxillaList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category || '',
      })),
      leftMandible: leftMandibleList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category || '',
      })),
      rightMandible: rightMandibleList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category || '',
      })),
      genioplasty: genioplastyList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category || '',
      }))
    };

    updateCase(updatedCase);
    setEditMode(false);
    setEditLeftMandibleMode(false);
    setEditRightMandibleMode(false);
    setEditGenioplastyMode(false);
  };

  // Maxilla

  const handleAddHardware = (product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setMaxillaList(list);
    } else {
      setMaxillaList([...maxillaList, product]);
    }
  };

  const handleRemoveHardware = (id) => {
    setMaxillaList(maxillaList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setMaxillaList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setMaxillaList(list);
  };

  const handleChange = (id, product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setMaxillaList(list);
  };

  // Left Mandible

  const handleAddLeftMandible = (product) => {
    const list = [...leftMandibleList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setLeftMandibleList(list);
    } else {
      setLeftMandibleList([...leftMandibleList, product]);
    }
  };

  const handleRemoveLeftMandible = (id) => {
    setLeftMandibleList(leftMandibleList.filter((item) => item.id !== id));
  };

  const handleAmountLeftMandibleChange = (id, amount) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setLeftMandibleList(list);
  };

  const handleCategoryLeftMandibleChange = (id, category) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setLeftMandibleList(list);
  };

  const handleLeftMandibleChange = (id, product) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setLeftMandibleList(list);
  };

  // Right Mandible

  const handleAddRightMandible = (product) => {
    const list = [...rightMandibleList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setRightMandibleList(list);
    } else {
      setRightMandibleList([...rightMandibleList, product]);
    }
  };

  const handleRemoveRightMandible = (id) => {
    setRightMandibleList(rightMandibleList.filter((item) => item.id !== id));
  };

  const handleAmountRightMandibleChange = (id, amount) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setRightMandibleList(list);
  };

  const handleCategoryRightMandibleChange = (id, category) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setRightMandibleList(list);
  };

  const handleRightMandibleChange = (id, product) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setRightMandibleList(list);
  };

  // Genioplasty

  const handleAddGenioplasty = (product) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setGenioplastyList(list);
    } else {
      setGenioplastyList([...genioplastyList, product]);
    }
  };

  const handleRemoveGenioplasty = (id) => {
    setGenioplastyList(genioplastyList.filter((item) => item.id !== id));
  };

  const handleAmountGenioplastyChange = (id, amount) => {
    const list = [...genioplastyList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setGenioplastyList(list);
  };

  const handleCategoryGenioplastyChange = (id, category) => {
    const list = [...genioplastyList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setGenioplastyList(list);
  };

  const handleGenioplastyChange = (id, product) => {
    const list = [...genioplastyList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setGenioplastyList(list);
  };

  return (
    <div className="case-static-container">
      <div className="m-b-md underline">
        {files
          .filter((file) => fileTypes.includes(file.type))
          .map((item) => (
            <div key={`${item.name}-${item.type}-${item.date}`} className="case-file-list-item" onClick={goToFiles}>
              <div className="width-100">
                <div className="d-flex">
                  <div className="d-flex align-center icon-file-container flex-1">
                    <img src={getFileIcon(item.type)} alt="file-icon" style={{ height: '20px', fill: '#eee' }} />
                    <div className="font-bold">{getFileLabel(item.type)}</div>
                  </div>
                  {item.status && (
                    <div className={`status-indicator status-indicator-file ${item.status}`}>{item.status}</div>
                  )}
                </div>
                <div className="d-flex w-100 space-between m-t-sm">
                  <div className="list-item-title">{item.name}</div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="p-b-md m-b-md underline">
        <div className="m-b-sm d-flex">
          <div className="secondary">MAXILLA</div>
          <RestrictComponent roles={isPermission}>
            {isEditMode ? (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={onSave}>
                Save
              </div>
            ) : (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={() => setEditMode(true)}>
                Edit
              </div>
            )}
          </RestrictComponent>
        </div>
        {isEditMode ? (
          <div>
            <HardwareList
              classes={classes}
              hardwareList={maxillaList}
              products={products}
              onAdd={handleAddHardware}
              onRemove={handleRemoveHardware}
              onAmountChange={handleAmountChange}
              onCategoryChange={handleCategoryChange}
              onChange={handleChange}
              procedureFlow={activeCase.procedureFlow}
            />
          </div>
        ) : (
          <div>
            {maxillaList.map((item, index) => (
              <div key={`${index}-${item.product}`} className="d-flex">
                <div>
                  {`${index + 1}.`} <span className="secondary">{item.category}</span> {item.product}
                </div>
                <div className="secondary m-l-sm">x {item.amount}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="p-b-md m-b-md underline">
        <div className="m-b-sm d-flex">
          <div className="secondary">LEFT MANDIBLE</div>
          <RestrictComponent roles={isPermission}>
            {isEditLeftMandibleMode ? (
              <div className={`${classes.primary} m-l-sm pointer`} onClick={onSave}>
                Save
              </div>
            ) : (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={() => setEditLeftMandibleMode(true)}>
                Edit
              </div>
            )}
          </RestrictComponent>
        </div>
        {isEditLeftMandibleMode ? (
          <div>
            <HardwareList
              classes={classes}
              hardwareList={leftMandibleList}
              products={products}
              onAdd={handleAddLeftMandible}
              onRemove={handleRemoveLeftMandible}
              onAmountChange={handleAmountLeftMandibleChange}
              onCategoryChange={handleCategoryLeftMandibleChange}
              onChange={handleLeftMandibleChange}
              procedureFlow={activeCase.procedureFlow}
            />
          </div>
        ) : (
          <div>
            {leftMandibleList.map((item, index) => (
              <div key={`${index}-${item.product}`} className="d-flex">
                <div>
                  {`${index + 1}.`} <span className="secondary">{item.category}</span> {item.product}
                </div>
                <div className="secondary m-l-sm">x {item.amount}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="p-b-md m-b-md underline">
        <div className="m-b-sm d-flex">
          <div className="secondary">RIGHT MANDIBLE</div>
          <RestrictComponent roles={isPermission}>
            {isEditRightMandibleMode ? (
              <div className={`${classes.primary} m-l-sm pointer`} onClick={onSave}>
                Save
              </div>
            ) : (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={() => setEditRightMandibleMode(true)}>
                Edit
              </div>
            )}
          </RestrictComponent>
        </div>
        {isEditRightMandibleMode ? (
          <div>
            <HardwareList
              classes={classes}
              hardwareList={rightMandibleList}
              products={products}
              onAdd={handleAddRightMandible}
              onRemove={handleRemoveRightMandible}
              onAmountChange={handleAmountRightMandibleChange}
              onCategoryChange={handleCategoryRightMandibleChange}
              onChange={handleRightMandibleChange}
              procedureFlow={activeCase.procedureFlow}
            />
          </div>
        ) : (
          <div>
            {rightMandibleList.map((item, index) => (
              <div key={`${index}-${item.product}`} className="d-flex">
                <div>
                  {`${index + 1}.`} <span className="secondary">{item.category}</span> {item.product}
                </div>
                <div className="secondary m-l-sm">x {item.amount}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <div className="m-b-sm d-flex">
          <div className="secondary">GENIOPLASTY</div>
          <RestrictComponent roles={isPermission}>
            {isEditGenioplastyMode ? (
              <div className={`${classes.primary} m-l-sm pointer`} onClick={onSave}>
                Save
              </div>
            ) : (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={() => setEditGenioplastyMode(true)}>
                Edit
              </div>
            )}
          </RestrictComponent>
        </div>
        {isEditGenioplastyMode ? (
          <div>
            <HardwareList
              classes={classes}
              hardwareList={genioplastyList}
              products={products}
              onAdd={handleAddGenioplasty}
              onRemove={handleRemoveGenioplasty}
              onAmountChange={handleAmountGenioplastyChange}
              onCategoryChange={handleCategoryGenioplastyChange}
              onChange={handleGenioplastyChange}
              procedureFlow={activeCase.procedureFlow}
            />
          </div>
        ) : (
          <div>
            {genioplastyList.map((item, index) => (
              <div key={`${index}-${item.product}`} className="d-flex">
                <div>
                  {`${index + 1}.`} <span className="secondary">{item.category}</span> {item.product}
                </div>
                <div className="secondary m-l-sm">x {item.amount}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      {notes && (
        <div className="m-t-md p-t-sm border-top">
          <div className="secondary">Notes:</div>
          <div>{notes}</div>
        </div>
      )}
    </div>
  );
};

CmfStep3Static.propTypes = {
  activeCase: PropTypes.object,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    products,
    userRole: state.user.currentUser.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
    updateCase: (updatedCase) => dispatch(simpleUpdateCase(updatedCase)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CmfStep3Static);
