import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setActiveTab, simpleUpdateCase } from '../../../../../../actions/casesActions';

import { fileTypes } from '../../../../../../constants/cases';
import { getFileIcon, getFileLabel } from '../../../../../../util/cases';

const CmfExtendedStep8Static = ({ activeCase, goToFiles }) => {
  const { files } = activeCase;

  return (
    <div className="case-static-container">
      <div className="">
        {files
          .filter((file) => file.type === fileTypes.conformanceReport)
          .map((item) => (
            <div key={`${item.name}-${item.type}-${item.date}`} className="case-file-list-item" onClick={goToFiles}>
              <div className="width-100">
                <div className="d-flex">
                  <div className="d-flex align-center icon-file-container flex-1">
                    <img src={getFileIcon(item.type)} alt="file-icon" style={{ height: '20px', fill: '#eee' }} />
                    <div className="font-bold">{getFileLabel(item.type)}</div>
                  </div>
                  {item.status && (
                    <div className={`status-indicator status-indicator-file ${item.status}`}>{item.status}</div>
                  )}
                </div>
                <div className="d-flex w-100 space-between m-t-sm">
                  <div className="list-item-title">{item.name}</div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

CmfExtendedStep8Static.propTypes = {
  activeCase: PropTypes.object,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    products,
    userRole: state.user.currentUser.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
    updateCase: (updatedCase) => dispatch(simpleUpdateCase(updatedCase)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CmfExtendedStep8Static);
