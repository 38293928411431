import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { orderBy } from 'lodash';
import { LinkContainer } from 'react-router-bootstrap';

import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExportIcon from '@material-ui/icons/CloudDownload';
import FilterIcon from '@material-ui/icons/FilterList';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import CasesTable from './CasesTable';
import CasesTableMobile from './CasesTableMobile';
import CaseModal, { CaseFilterModal } from '../modals/case';
import RestrictComponent from '../shared/RestrictComponent';
import TrainingModeModal from '../modals/TrainingModeModal';
import CaseExportModal from '../modals/case/CaseExportModal';
import ExportModal from '../modals/ExportModal';
import CaseDateModal from '../modals/case/CaseDateModal';
import CaseRequestDateModal from '../modals/case/CaseRequestDateModal';
import CaseRequestDateHospitalModal from '../modals/case/CaseRequestDateHospitalModal';
import CaseApproveDateModal from '../modals/case/CaseApproveDateModal';

import {
  setActiveCase,
  discardActiveCase,
  setActiveTab,
  updateCaseDoc,
  sendChangeDateHospitalEmail,
  requestCaseDateUpdate,
  approveRequestDateUpdate,
  rejectRequestDateUpdate,
  fetchCompletedCases,
} from '../../actions/casesActions';
import { toggleSidebar } from '../../actions/sidebarActions';
import { fetchHospitals, fetchSurgeons } from '../../actions/adminActions';

import { utils } from '../../util';
import { filterRequired } from '../../util/cases';

import { userRoles, caseConstants } from '../../constants';
import { procedureFlows } from '../../constants/cases';

import './cases.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

const {
  GLOBAL_ADMIN,
  COUNTRY_ADMIN,
  DISTRIBUTOR_ADMIN,
  SURGEON,
  ENGINEER,
  SALES_REP,
  LOGISTICS,
  PRACTICE_MANAGER,
  MANUFACTURER,
  RADIOLOGY,
} = userRoles;

const getNumberOfSteps = (flow) => {
  switch (flow) {
    case caseConstants.procedureFlows?.ACL:
      return caseConstants.numberOfAClSteps;
    case caseConstants.procedureFlows?.CMF:
      return caseConstants.numberOfCmfSteps;
    case caseConstants.procedureFlows?.CUSTOM:
      return caseConstants.numberOfCustomSteps;
    case caseConstants.procedureFlows?.DOUBLE_LEVEL_OSTEOTOMY:
      return caseConstants.numberOfCmfExtendedSteps;
    case caseConstants.procedureFlows?.ONCOL:
      return caseConstants.numberOfCmfExtendedSteps;
    case caseConstants.procedureFlows?.CMF_EXTENDED:
      return caseConstants.numberOfCmfExtendedSteps;
    case caseConstants.procedureFlows?.CUSTOM_EXTENDED:
      return caseConstants.numberOfCmfExtendedSteps;
    default:
      return caseConstants.numberOfSteps;
  }
};

const CasesTableContainer = (props) => {
  const {
    title,
    setActiveCase,
    discardActiveCase,
    setActiveTab,
    toggleSidebar,
    withButton,
    onlyRequired,
    trainingMode,
    users,
    surgeons,
    caseId,
    countryList,
    distributors,
    hospitals,
    procedures,
    role,
    updateCaseDoc,
    requestCaseDateUpdate,
    approveRequestDateUpdate,
    sendChangeDateHospitalEmail,
    rejectRequestDateUpdate,
    casesLoaded,
  } = props;

  const cases = useMemo(() => props.cases?.map((caseItem) => {
    let surgeonName = caseItem?.surgeonName || '';

    if (!surgeonName) {
      const surgeonUser = users.find((user) => user.uid === caseItem?.surgeon?.userId);
      surgeonName = surgeonUser
        ? `${surgeonUser?.firstName} ${surgeonUser?.lastName}`
        : "";
    }

    return {
      ...caseItem,
      surgeonName,
    };
  }), [props.cases, users]);

  const dispatch = useDispatch();

  const filteredFields = ['access', 'hospital', 'country', 'procedureName', 'action'];

  const getInitialFilters = () => {
    const initialFilter = {};

    filteredFields.forEach((field) => {
      if (field === 'access') {
        initialFilter[field] = ['Open'];
      } else {
        initialFilter[field] = [];
      }
    });

    return initialFilter;
  };

  const [filter, setFilter] = useState(getInitialFilters());
  const [isOpen, toggleModal] = useState(false);
  const [filterModalIsOpen, toggleFilterModal] = useState(false);
  const [search, setSearch] = useState('');
  const [trainingConfirmModalOpen, setTrainingConfirmModalState] = useState(false);

  const [isExportModalOpen, toggleExportModal] = useState(false);
  const [isCaseExportModalOpen, toggleCaseExportModal] = useState(false);

  const [selectedCase, setSelectedCase] = useState(null);
  const [loading, setLoading] = useState(false);

  const [dateModalOpen, toggleDateModal] = useState(false);
  const [dateHospitalModalOpen, toggleDateHospitalModal] = useState(false);
  const [requestDateModalOpen, toggleRequestDateModal] = useState(false);
  const [approveDateModalOpen, toggleApproveDateModal] = useState(false);

  useEffect(() => {
    dispatch(fetchHospitals());
    dispatch(fetchSurgeons());
  }, [dispatch]);

  const handleModalClose = (isFirstStep, selectedFlow) => {
    toggleModal(false);

    if (isFirstStep && typeof isFirstStep === "boolean") {
      if ([procedureFlows.CMF_EXTENDED, procedureFlows.CMF, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(selectedFlow)) {
        toggleModal(true);
      } else {
        toggleCaseExportModal(true);
      }
    } else {
      discardActiveCase();
    }
  };

  const handleClick = (newCase) => {
    setActiveCase(newCase);
    toggleModal(true);
  };

  const goToFiles = (newCase) => {
    handleClick(newCase);
    setActiveTab(2);
  };

  const handleFilterChange = (field, values) => {
    setFilter({ ...filter, [field]: values });
  };

  const handleRemoveFilterItem = (key, value) => {
    if (key === 'date') {
      setFilter({ ...filter, date: null });
    } else {
      setFilter({ ...filter, [key]: filter[key].filter((item) => item !== value) });
    }
  };

  const handleFilterModalSubmit = (newFilter) => {
    setFilter({ ...filter, ...newFilter });
  };

  const handleNewCaseClick = () => {
    if (trainingMode) {
      setTrainingConfirmModalState(true);
    } else {
      toggleModal(true);
    }
  };

  const onSkipClick = (id) => {
    toggleCaseExportModal(false);
    const item = cases?.find((c) => c.id === id);
    if (item) {
      setActiveCase(item);
      toggleModal(true);
    }
  };

  const hospitalList = useMemo(() => {
    if (selectedCase && selectedCase.surgeonId) {
      const surgeon = surgeons?.find((s) => s.id === selectedCase.surgeonId);
      return surgeon ? hospitals?.filter((h) => surgeon?.hospitals?.includes(h.id)) : []
    }
    return [];
  }, [hospitals, surgeons, selectedCase]);

  // Request date functionality

  const onSubmitDate = async (date, hospitalId) => {
    try {
      setLoading(true);
      const hospital = hospitals?.find((h) => h.id === hospitalId);
      const doc = {
        date: date?.format(),
        hospital: hospital?.name || '',
        hospitalUniq: { id: hospitalId, name: hospital?.name || '' },
      };
      await updateCaseDoc(selectedCase.id, doc);
      sendChangeDateHospitalEmail(selectedCase.id, { ...selectedCase, ...doc }, selectedCase);
      toggleDateModal(false);
      setSelectedCase(null);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitRequestDate = async (date) => {
    try {
      setLoading(true);
      await requestCaseDateUpdate(selectedCase, date);
      toggleRequestDateModal(false);
      setSelectedCase(null);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitRequestDateHospital = async (date, hospital) => {
    try {
      setLoading(true);
      await requestCaseDateUpdate(selectedCase, date, hospital);
      toggleDateHospitalModal(false);
      setSelectedCase(null);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onApproveDate = async () => {
    try {
      if (selectedCase?.requestedSurgeryDate) {
        setLoading(true);
        await approveRequestDateUpdate(selectedCase);
        toggleApproveDateModal(false);
        setSelectedCase(null);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onRejectDate = async () => {
    try {
      if (selectedCase?.requestedSurgeryDate) {
        setLoading(true);
        await rejectRequestDateUpdate(selectedCase);
        toggleApproveDateModal(false);
        setSelectedCase(null);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onAdminApproveDateClick = (row) => {
    handleModalClose();
    setSelectedCase(row);
    toggleApproveDateModal(true);
  };

  const onTBCClick = (row) => {
    handleModalClose();
    const isAdmin = [userRoles.GLOBAL_ADMIN.name, userRoles.COUNTRY_ADMIN.name, userRoles.DISTRIBUTOR_ADMIN.name]?.includes(role);
    setSelectedCase(row);
    if (isAdmin) {
      toggleDateModal(true);
    } else {
      toggleDateHospitalModal(true);
    }
  };

  const onRequestDateClick = (row) => {
    handleModalClose();
    setSelectedCase(row);
    toggleRequestDateModal(true);
  };

  const filterOptions = useMemo(() => {
    const statuses = ['Open', 'Completed', 'Deleted'];
    const hospitals = [];
    const countries = countryList;
    const procedures = [];
    const actions = [];
    const surgeons = [];
    const steps = [];

    cases.forEach((item) => {
      const { hospital, procedureName, step, surgeonName } = item;
      const action = step && caseConstants.caseActions[step];

      if (hospital && !hospitals?.includes(hospital)) {
        hospitals?.push(hospital);
      }

      if (procedureName && !procedures?.includes(procedureName)) {
        procedures?.push(procedureName);
      }

      if (action && !actions?.includes(action)) {
        actions?.push(action);
      }

      if (step && !steps?.includes(step)) {
        steps?.push(step);
      }

      if (surgeonName && !surgeons?.includes(surgeonName)) {
        surgeons?.push(surgeonName);
      }
    });

    return {
      access: statuses,
      hospital: hospitals,
      procedureName: procedures,
      action: actions,
      surgeonName: surgeons,
      country: countries,
      step: steps,
    };
  }, [cases, procedures, surgeons, hospitals, countryList]);

  const data = useMemo(() => {
    let result = cases?.filter((row) => {
      let persistence = true;
      const distributorCountry = distributors?.find((d) => d.id === row.distributorId)?.country || '';
      const numberOfSteps = getNumberOfSteps(row.procedureFlow);

      Object.keys(filter)
        .filter((key) => key !== 'access' && key !== 'action' && key !== 'country' && key !== 'date')
        .forEach((key) => {
          if (filter[key].length && !filter[key].includes(row[key])) {
            persistence = false;
          }
        });


      if (row.archived) {
        persistence = filter.access.length && filter.access.includes('Deleted');
      } else {
        if (row.step <= numberOfSteps) {
          persistence = filter.access.length && filter.access.includes('Open');
        }
        if (row.step > numberOfSteps) {
          persistence = filter.access.length && filter.access.includes('Completed');
        }
      }

      if (filter.action.length) {
        const steps = filter.action.map((action) =>
          utils.getKeyByValue(caseConstants.caseActions, action),
        );

        if (!steps.includes(row.step.toString())) {
          persistence = false;
        }
      }

      if (filter.country?.length) {
        persistence = persistence && filter.country?.includes(distributorCountry);
      }

      if (filter.procedureName?.length) {
        persistence = persistence && filter.procedureName?.includes(row.procedureName);
      }

      if (filter.surgeonName?.length) {
        persistence = persistence && filter.surgeonName?.includes(row.surgeonName);
      }

      if (filter.hospital?.length) {
        persistence = persistence && filter.hospital?.includes(row.hospital);
      }

      if (filter.step?.length) {
        persistence = persistence && filter.step?.includes(row.step);
      }

      if (filter.date && filter.date.from && filter.date.to) {
        persistence = persistence && filter.date.from.startOf('day').isBefore(moment(row.date)) && filter.date.to.endOf('day').isAfter(moment(row.date));
      }

      if (search && !!search.length) {
        return (
          persistence &&
          (row.id.toLowerCase().includes(search.toLowerCase()) ||
            (row.patientLastName && row.patientLastName.toLowerCase().includes(search.toLowerCase())))
        );
      }

      return persistence;
    });

    if (onlyRequired) {
      result = result.filter(filterRequired);
    }

    return result;
  }, [cases, filter, search]);

  const number = useMemo(() => onlyRequired ? cases.filter(filterRequired)?.length : cases?.length, [cases, onlyRequired]);

  return (
    <Paper className="cases-table-container">
      <div className="table-title-container">
        <div className="flex-1">
          <div className="d-flex">
            {withButton && (
              <Hidden smUp>
                <div className="m-r-sm">
                  <IconButton
                    edge="start"
                    color="primary"
                    aria-label="menu"
                    onClick={toggleSidebar}
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
              </Hidden>
            )}
            <div className="table-title">
              {title || 'My Cases'}
              <div className="badge">{number}</div>
            </div>
            <Hidden smDown implementation="css">
              <div className="d-flex flex-center m-l-lg">
                <TextField
                  className="case-search"
                  id="search"
                  type="text"
                  label="Search"
                  variant="outlined"
                  value={search}
                  margin="dense"
                  placeholder="Case ID / Patient"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Hidden>
          </div>
        </div>
        <div className="d-flex flex-1 flex-end">
          <RestrictComponent roles={[GLOBAL_ADMIN, COUNTRY_ADMIN]}>
            <div className="m-r-lg">
              <IconButton
                edge="start"
                color="primary"
                aria-label="export"
                onClick={() => toggleExportModal(true)}
              >
                <ExportIcon />
              </IconButton>
            </div>
          </RestrictComponent>
          {trainingMode ? (
            <RestrictComponent
              roles={[
                GLOBAL_ADMIN,
                COUNTRY_ADMIN,
                DISTRIBUTOR_ADMIN,
                SURGEON,
                ENGINEER,
                SALES_REP,
                LOGISTICS,
                PRACTICE_MANAGER,
                MANUFACTURER,
              ]}
            >
              <Hidden smDown implementation="css">
                <IconButton
                  edge="start"
                  color="primary"
                  aria-label="add"
                  onClick={handleNewCaseClick}
                >
                  <AddCircleIcon />
                </IconButton>
              </Hidden>
            </RestrictComponent>
          ) : (
            <RestrictComponent excludeRoles={[MANUFACTURER, LOGISTICS, RADIOLOGY]}>
              <Hidden smDown implementation="css">
                <IconButton
                  edge="start"
                  color="primary"
                  aria-label="add"
                  onClick={handleNewCaseClick}
                >
                  <AddCircleIcon />
                </IconButton>
              </Hidden>
            </RestrictComponent>
          )}
          <Hidden mdUp implementation="css">
            <div className="d-flex">
              <IconButton
                edge="start"
                color="primary"
                aria-label="filter"
                onClick={() => toggleFilterModal(true)}
              >
                <FilterIcon />
              </IconButton>
            </div>
          </Hidden>
        </div>
      </div>
      {!casesLoaded && (
        <div className="width-100 d-flex flex-center">
          <CircularProgress />
        </div>
      )}
      {!!number ? (
        <Fragment>
          <Hidden smDown implementation="css">
            <CasesTable
              cases={data}
              filter={filter}
              filterOptions={filterOptions}
              onFilterChange={handleFilterChange}
              onRemoveFilterItem={handleRemoveFilterItem}
              onClick={handleClick}
              goToFiles={goToFiles}
              distributors={distributors}
              onlyRequired={onlyRequired}
              onAdminApproveDateClick={onAdminApproveDateClick}
              onTBCClick={onTBCClick}
              onRequestDateClick={onRequestDateClick}
            />
            {onlyRequired && (
              <LinkContainer to="/cases">
                <div className="primary pointer d-flex p-t-md p-b-md flex-center" onClick={() => {
                }}>View All Cases
                </div>
              </LinkContainer>
            )}
          </Hidden>
          <Hidden mdUp implementation="css">
            <CasesTableMobile
              cases={orderBy(data?.map((item) => ({
                ...item,
                sortDate: item.date || item.createdAt || null,
              })), 'sortDate').splice(0, onlyRequired ? 5 : number)}
              onClick={handleClick}
            />
            {onlyRequired && (
              <LinkContainer to="/cases">
                <div className="primary pointer d-flex p-t-md p-b-md flex-center" onClick={() => {
                }}>View All Cases
                </div>
              </LinkContainer>
            )}
          </Hidden>
        </Fragment>
      ) : (
        <div className="empty-block">{onlyRequired ? 'No Action Required Cases' : 'No Cases'}</div>
      )}

      <CaseModal
        open={isOpen}
        onClose={handleModalClose}
        onAdminApproveDateClick={onAdminApproveDateClick}
        onTBCClick={onTBCClick}
        onRequestDateClick={onRequestDateClick}
      />

      <CaseFilterModal
        open={filterModalIsOpen}
        onClose={() => toggleFilterModal(false)}
        filter={filter}
        filterOptions={filterOptions}
        handleFilterModalSubmit={handleFilterModalSubmit}
        search={search}
        onSearchChange={setSearch}
      />
      <TrainingModeModal
        open={trainingConfirmModalOpen}
        onClose={() => setTrainingConfirmModalState(false)}
        onSubmit={() => {
          setTrainingConfirmModalState(false);
          toggleModal(true);
        }}
        title="Training Mode Active"
        text="You are about to create a case for training purposes only. Please cancel and deactivate training if you wish to create an actual case."
        submitText="Proceed"
      />
      <ExportModal
        open={isExportModalOpen}
        onClose={() => toggleExportModal(false)}
        cases={cases}
        users={users}
        surgeons={surgeons}
      />
      <CaseExportModal
        open={isCaseExportModalOpen}
        onClose={() => {
          toggleCaseExportModal(false);
          discardActiveCase();
        }}
        caseId={caseId}
        onSkipClick={onSkipClick}
      />

      <CaseDateModal
        open={dateModalOpen}
        onClose={() => {
          toggleDateModal(false);
          setSelectedCase(null);
        }}
        selectedCase={selectedCase}
        onSubmit={onSubmitDate}
        hospitalList={hospitalList}
        procedures={procedures}
        loading={loading}
        isAdmin={role === userRoles.GLOBAL_ADMIN.name || role === userRoles.COUNTRY_ADMIN.name}
      />

      <CaseRequestDateModal
        open={requestDateModalOpen}
        onClose={() => {
          toggleRequestDateModal(false);
          setSelectedCase(null);
        }}
        selectedCase={selectedCase}
        onSubmit={onSubmitRequestDate}
        loading={loading}
      />

      <CaseRequestDateHospitalModal
        open={dateHospitalModalOpen}
        onClose={() => {
          toggleDateHospitalModal(false);
          setSelectedCase(null);
        }}
        selectedCase={selectedCase}
        onSubmit={onSubmitRequestDateHospital}
        loading={loading}
        hospitals={hospitals}
        surgeons={surgeons}
      />

      <CaseApproveDateModal
        open={approveDateModalOpen}
        onClose={() => {
          toggleApproveDateModal(false);
          setSelectedCase(null);
        }}
        selectedCase={selectedCase}
        onApprove={onApproveDate}
        onReject={onRejectDate}
        loading={loading}
      />
    </Paper>
  );
};

CasesTableContainer.propTypes = {
  title: PropTypes.string,
  cases: PropTypes.array,
  withButton: PropTypes.bool,
  onlyRequired: PropTypes.bool,
  setActiveCase: PropTypes.func,
  discardActiveCase: PropTypes.func,
  setActiveTab: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

const mapStateToProps = (state) => {
  const procedures = state.procedures.list;
  const cases = state.cases.list.map((item) => {
    const caseProcedure = procedures.find((procedure) => procedure.id === item.procedure);

    return { ...item, procedureName: caseProcedure ? caseProcedure.name : 'No procedure' };
  });
  const trainingMode = state.sidebar.trainingMode;

  return {
    cases: cases.filter((item) => !!item.trainingMode === trainingMode),
    casesLoaded: state.cases.isLoaded,
    // completedLoading: state?.cases?.completedLoading,
    // completedLoaded: state?.cases?.completedLoaded,
    trainingMode,
    users: state.users.list,
    surgeons: state.surgeons.list,
    caseId: state.cases?.activeCase?.id,
    countryList: state.countries.list,
    distributors: state.distributors.list,
    hospitals: state.hospitals.list,
    procedures: state.procedures.list,
    role: state.user.currentUser.role,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveCase: (newCase) => dispatch(setActiveCase(newCase)),
    discardActiveCase: () => dispatch(discardActiveCase()),
    setActiveTab: (tab) => dispatch(setActiveTab(tab)),
    toggleSidebar: () => dispatch(toggleSidebar()),
    updateCaseDoc: (caseId, data) => dispatch(updateCaseDoc(caseId, data)),
    sendChangeDateHospitalEmail: (caseId, data, previousData) => dispatch(sendChangeDateHospitalEmail(caseId, data, previousData)),
    requestCaseDateUpdate: (activeCase, date, hospital) => dispatch(requestCaseDateUpdate(activeCase, date, hospital)),
    approveRequestDateUpdate: (activeCase) => dispatch(approveRequestDateUpdate(activeCase)),
    rejectRequestDateUpdate: (activeCase) => dispatch(rejectRequestDateUpdate(activeCase)),
    fetchCompletedCases: () => dispatch(fetchCompletedCases()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CasesTableContainer);
